import React, { useContext, useState, useEffect } from "react";
import { VALIDATE_STEP } from ".";
import { OnboardingContext } from "../../context";


const Meet = ({ nextStep, previousStep, currentStep, updateSteps }) => {
    const { data, setData } = useContext(OnboardingContext); 
    const toNextStep = (lookingFor) => {
        let _data = { ...data, lookingFor };
        if (VALIDATE_STEP(_data, currentStep - 1)) {
            setData({..._data, step:3});
            nextStep()
        }
    }

    return (
        <>
            <section className="col-md-9 col-lg-5 col-xl-4 mx-auto">
                <div className="heading-group">
                    <span className="animate__animated animate__slideInDown animate__delay-3s step-2" />
                    <h1>Who can we help you meet?</h1>
                </div>
                <div className="form-group row cta-option-group">
                    <button type="button" id="#next" className="btn" onClick={() => toNextStep("Male")}>Men</button>
                    <button type="button" id="#next" className="btn" onClick={() => toNextStep("Female")}>Women</button>
                    <button type="button" id="#next" className="btn" onClick={() => toNextStep("Everyone")}>Everyone</button>
                </div>

                <div className="action-buttons">
                    <div className="row">
                        <div className="col">
                            <button type="button" className="btn fa-previous" onClick={previousStep}> </button>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Meet