import { useEffect, useState } from 'react';
// import Circular from "../loaders/CiruclarLoader"s;

/**
 * TODO:
 * Add skeleton loader to images
 */

const Image = ({ src, fallback, alt, styles, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [src]);

  const handleError = (e) => {
    // console.log("image error ");
    // setLoading(false);
    setError(true);
  };

  const handleLoad = (e) => {
    // console.log("image loaded ");
    // setError(false);
    setLoading(false);
  };

  const imgSrc = !error ? src : fallback;

  return (
    <>
      <div className={styles}>
        <img
          {...props}
          alt={alt}
          src={imgSrc}
          draggable='false'
          onLoad={handleLoad}
          onError={handleError}
        />
        {/* {loading && (
          <div className="image-container-overlay">
            <Circular />
          </div>
        )} */}
      </div>
    </>
  );
};

Image.defaultProps = {
  styles: '',
};

export default Image;
