import InvitationsLoader from '../../components/loaders/InvitationsLoader';
import { AddIcon } from '../../components/Svgs';
import Image from '../../components/shared/Image';
import ChildrenModal from '../../components/Modals/childrenModal';
import { useEffect } from 'react';
import { capitalize, getAgeFromYear, getFullName } from '../../utils';

function InvitationRow({ email, status }) {
	return (
		<div className="row justify-content-between childrenInvitation">
			<div className="col-8 mt-2">
				<p className="reset-margin-left-5">{email}</p>
			</div>
			<div className="col-4">
				<p
					className={`recommendee ${status.toLowerCase()} text-center`}
				>
					{capitalize(status)}
				</p>
			</div>
		</div>
	);
}

function RegisteredInvitation({
	imageUrl,
	firstName,
	lastName,
	gender,
	birthDate,
}) {
	console.log(new Date('07/03/2004'));
	return (
		<div className="row justify-content-between recommended-profile">
			<div className="col-1">
				<Image
					alt=""
					className="round-image-48"
					src={imageUrl || 'assets/images/profile-avatar.png'}
					fallback="assets/images/profile-avatar.png"
				/>
			</div>
			<div className="col-7">
				<p className="reset-margin-left-5">
					{getFullName(firstName, lastName)}
					{(gender || birthDate) && (
						<span>
							{capitalize(gender)}{' '}
							{gender && <i className="fas fa-dot-circle" />}
							{birthDate && `${getAgeFromYear(birthDate)}y`}
						</span>
					)}
				</p>
			</div>
			<div className="col-4">
				<p className="recommendee registered text-center">Registered</p>
			</div>
		</div>
	);
}

const NoInvitations = () => (
	<div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
		<div className="card card-light card-min-height-380">
			<div className="card-body">
				<div className="row justify-content-between card-title-container mb-4">
					<div className="col-6">
						<h5 className="card-title with-bg float-start">
							Your children
						</h5>
					</div>
					<div className="col-2"></div>
				</div>
				<div className="empty-card text-center">
					<div className="icon-container mx-auto">
						<svg
							width={25}
							height={24}
							viewBox="0 0 25 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.5 5V19"
								stroke="#E80F6D"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M5.5 12H19.5"
								stroke="#E80F6D"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<p className="card-text">Invite your children</p>
					<a
						href="##"
						className="btn btn-primary"
						data-bs-toggle="modal"
						data-bs-target="#inviteChildrenModal"
					>
						Invite
					</a>
				</div>
			</div>
		</div>
	</div>
);

function Invitations({ invitations, loading, removeInvitation }) {
	useEffect(() => {
		console.log(invitations);
	});
	if (!loading && invitations.length === 0) return <NoInvitations />;
	return (
		<div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
			<div className="card card-light card-min-height-380">
				<div className="card-body">
					<div className="row justify-content-between card-title-container mb-4">
						<div className="col-6">
							<h5 className="card-title with-bg float-start">
								Your children
							</h5>
						</div>
						<div className="col-4">
							<span
								className={`${
									invitations.length === 0 ? 'd-none' : ''
								} float-end`}
							>
								<a
									data-bs-toggle="modal"
									data-bs-target="#children"
									href="##"
								>
									All <i class="fas fa-chevron-right"></i>
								</a>
							</span>
						</div>
					</div>
					{loading && (
						<>
							<InvitationsLoader />
						</>
					)}
					{invitations &&
						invitations.slice(0, 3).map((invitation, i) => {
							if (
								invitation.status === 'Pending' ||
								invitation.status === 'Accepted' ||
								invitation.status === 'Rejected'
							) {
								return (
									<InvitationRow
										key={i}
										email={invitation.email}
										status={invitation.status}
									></InvitationRow>
								);
							} else {
								return (
									<RegisteredInvitation
										firstName={invitation.invitee.firstName}
										lastName={invitation.invitee.lastName}
										gender={invitation.invitee.meta.gender}
										imageUrl={
											invitation.invitee.profileImage
										}
										birthDate={
											invitation.invitee.meta.birthDate
										}
									/>
								);
							}
						})}
				</div>
				<div className="card-footer action-buttons text-center">
					<button
						className="btn transparent-btn"
						data-bs-toggle="modal"
						data-bs-target="#inviteChildrenModal"
					>
						Invite
					</button>
				</div>
			</div>
			<ChildrenModal
				invitations={invitations}
				removeInvitation={removeInvitation}
			/>
		</div>
	);
}

export default Invitations;
