import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { GoogleIcon, LoginPageBackgroundIcon } from '../../components/Svgs';
import { RegisterEmailForm } from './Forms/RegisterEmail.form';
import useLocalStorage from '../../hooks/useLocalStorage';
import { UserContext } from '../../context/user.context';
import { useGoogleLogin, GoogleLogin } from 'react-google-login';
import refreshTokenSetup from '../../utils/refreshToken';
import { gapi } from 'gapi-script';
import { isSafariBrowser } from '../../utils/browserDetect';

// import "../../assets/scss/main.scss";

const clientId =
	'607625530243-lkiescpo9lafbgjiqojjai9i42l1qtpi.apps.googleusercontent.com';

export const Register = () => {
	const isSafari = isSafariBrowser();
	const history = useHistory();
	const { getItem } = useLocalStorage();
	const details = getItem('details');
	const [context, setContext] = useState({
		context: details ? 'email' : 'landing',
		userId: '',
		email: '',
	});

	const { data: userData } = useContext(UserContext);

	useEffect(() => {
		if (userData && userData.user) {
			history.push('/dashboard');
		}
	}, [userData, history]);

	useEffect(() => {
		function start() {
			gapi.client.init({
				clientId: clientId,
				scope: 'email',
			});
		}

		gapi.load('client:auth2', start);
	}, []);

	return (
		<div className="container-fluid">
			<div className="row aunty-signup-login">
				<div className="col-md-4 col-lg-5 col-xl-4">
					<div className="promotional-left-panel">
						<div className="logo">
							<NavLink to="/login">
								<img
									src="assets/images/promo-logo.png"
									alt="promo-logo"
									className={`${
										isSafari ? 'safari-img-rotate' : ''
									}`}
								/>
							</NavLink>
						</div>
						<div className="image-container text-center">
							<LoginPageBackgroundIcon></LoginPageBackgroundIcon>
						</div>
						<div className="text text-center">
							<h3>The Auntie Network</h3>
							<p>Find the perfect match</p>
						</div>
					</div>
				</div>
				{context.context === 'landing' ? (
					<RegisterPrefs setContext={setContext} context={context} />
				) : (
					<RegisterEmailForm setContext />
				)}
			</div>
		</div>
	);
};

const RegisterPrefs = ({ setContext, context }) => {
	const history = useHistory();

	const onSuccess = (res) => {
		console.log('login succesful', res);
		refreshTokenSetup(res);
	};

	const onFailure = (res) => {
		console.log('Login Failed', res);
	};
	/*
const {signIn} = useGoogleLogin({
  onSuccess,
  onFailure,
  clientId,
  isSignedIn:true,
  accesType:"offline"
})
*/

	return (
		<div className="col-md-12 col-lg-7 col-xl-7">
			<div className="col-md-8 col-lg-8 col-xl-7 mx-auto form-content-container text-center">
				<h1>Create a Parent Account</h1>
				<div className="login-button-options">
					<li>
						<a
							onClick={() =>
								setContext({ ...context, context: 'email' })
							}
							className="btn with-email w-100"
							href="##"
						>
							<span>Sign up with email</span>
						</a>
					</li>
					<li>
						{/* <a className="btn google-btn w-100" href="##"> */}
						{/* <GoogleIcon /> */}
						{/* <span>Sign up with Google</span> */}
						{/* </a> */}
						{/* <GoogleLogin
                clientId={clientId}
                onSuccess={onSuccess}
                onFailure={onFailure}
                buttonText="Signup"
                cookiePolicy={"single_host_origin"}

            /> */}
					</li>
					{/* <li>
            <a href='##' className='btn facebook-btn w-100'>
              <FacebookIcon></FacebookIcon>
              <span>Sign up with Facebook</span>
            </a>
          </li>
          <li>
            <a href='##' className='btn twitter-btn w-100'>
              <TwitterIcon></TwitterIcon>
              <span>Sign up with Twitter</span>
            </a>
          </li> */}
				</div>
				<div className="after-login-button-options-separator">
					<span />
				</div>
				<div className="links-wrapper">
					<p>
						Already have an account?{' '}
						<a onClick={() => history.push('/login')} href="login">
							Login
						</a>
					</p>
					<p className="mt-4">
						By creating an account you agree to our <br />
						<NavLink
							to={{
								pathname:
									'https://www.theauntienetwork.com/privacy/',
							}}
							target="_blank"
							activeClassName="active"
						>
							Terms and Conditions.
						</NavLink>
					</p>
				</div>
			</div>
		</div>
	);
};
