import Loader from './loader';

const ParentChatCardLoader = () => {
  function ChatIcon() {
    return (
      <svg
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z'
          stroke='gainsboro'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  }

  return (
    <div className='user-parents mt-4'>
      <div className='text-center'>Parent</div>
      <div className='card card-light'>
        <div className={`card-body  disabled}`}>
          <div className='d-flex px-2 gap-3 align-items-center '>
            <div className='col-2'>
              <p
                style={{
                  overflow: 'hidden',
                  width: 'auto',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  background: 'gainsboro',
                }}
              >
                <Loader height='100%' width='100%' />
              </p>
            </div>
            <div className='col-6 '>
              <p>
                <Loader height='20' width='100%' />
                <span>
                  <Loader height='20' width='100%' />
                </span>
              </p>
            </div>
            <div className='col-2'>
              <ChatIcon></ChatIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentChatCardLoader;
