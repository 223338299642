import cogoToast from 'cogo-toast';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
// import useAuth from "../../../hooks/useAuth";
import { Login } from '../../../requests/auth';
import { LoginSchema } from '../../../validation/Login.validation';

import SpiralLoader from '../../../components/loaders/SpiralLoader';

const SignInForm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const { login } = useAuth(history);

  const { values, handleChange, handleSubmit, errors } = useFormik({
    validationSchema: LoginSchema,
    validateOnChange: false,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (val) => {
      setLoading(true);
      Login(val)
        .then((res) => {
          // console.log('login ', res);
          // setLoading(false);
          res && history.push('/dashboard');
          // return null;
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    },
  });

  // validate email
  useEffect(() => {
    let error_keys = Object.keys(errors);
    if (!Boolean(error_keys.length)) return;
    cogoToast.error(errors[error_keys[0]], { position: 'top-center' });
  }, [errors]);

  return (
    <form onSubmit={handleSubmit} className='form-wrapper'>
      <div className='row input-container'>
        <div className='form-group'>
          <input
            name='email'
            type='text'
            onChange={handleChange}
            className='form-control'
            placeholder='Email'
            value = {values.email.toLowerCase()}
          />
        </div>
        <div className='form-group'>
          <input
            name='password'
            type='password'
            onChange={handleChange}
            className='form-control'
            placeholder='Password'
            
          />
        </div>
        <div className='form-group'>
          <button
            type='submit'
            className='btn d-flex justify-content-center align-items-center btn-primary solid-btn w-100'
            disabled={loading}
          >
            {loading ? <SpiralLoader /> : 'Login'}
          </button>
        </div>
      </div>
      <div className='mt-4'>
          <a onClick={() => history.push("/forgot-password")} href="forgot-password">
                Forgot password?
          </a>
      </div>
    </form>
  );
};

export const LoginForm = withRouter(SignInForm);
