export const SET_CHATS = "SET_CHATS";

export const NEW_MESSAGE = "NEW_MESSAGE";

export const SET_USER_ID = "SET_USER_ID";

export const SET_MESSAGES = "SET_MESSAGES";

export const DELETE_MESSAGE = "DELETE_MESSAGE";

export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT";

export const SET_ACTIVE_CHAT_REQUEST = "SET_ACTIVE_CHAT_REQUEST";

export const SET_CHAT_REQUESTS = "SET_CHAT_REQUESTS";

export const UPDATE_CHAT_REQUEST = "UPDATE_CHAT_REQUEST";

export const UPDATE_MESSAGE_ID = "UPDATE_MESSAGE_ID";

export const UPDATE_MESSAGE_BODY = "UPDATE_MESSAGE_BODY";
