/* eslint-disable no-useless-escape */
import cogoToast from 'cogo-toast';
import React, { useContext, useEffect, useState } from 'react';
import NetworkLoader from '../../components/loaders/NetworkLoader';
import { GetNetworkSuggestions } from '../../requests/user';
import NetworkCard from './NetworkCard';
import { UserContext } from '../../context/user.context';
import { useHistory } from 'react-router-dom';
import EditPreferencesModal from '../../components/Modals/EditPreferencesModal';

// import "../../assets/scss/dashboard.scss";

const NoNetworks = () => (
	<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<div className="card card">
			<div className="card-body">
				<h5 className="card-title">Network Profiles</h5>
				<div className="empty-card text-center">
					<div className="icon-container mx-auto">
						{/* <MessageIcon></MessageIcon> */}
					</div>
					<p className="card-text">
						{' '}
						You have no profile recommendations based on your
						preferences. Edit your preferences to access get more
						matches.
					</p>
				</div>
			</div>
		</div>
	</div>
);

const Networks = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [networks, setNetworks] = useState([]);

	const history = useHistory();

	const { logout } = useContext(UserContext);

	const checkAuthorizedUser = (error) => {
		if (error?.message === 'Request failed with status code 401') {
			logout(history);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		GetNetworkSuggestions()
			.then((data) => {
				setNetworks(data.data);
				setIsLoading(false);
				console.log(data.data);
			})
			.catch((err) => {
				checkAuthorizedUser(err);
				// console.log("network error: ", err);
				setIsLoading(false);
			});
	}, []);
	const isEmpty = networks.length === 0;

	if (!isLoading && isEmpty) return <NoNetworks />;

	return (
		<div>
			{/* Page headers */}
			<div className="row m-0">
				<div className="col">
					{!isEmpty && (
						<>
							<div className="explore">
								<h1 className="mb2">Explore</h1>
								{/* { <div className="filter">
									<a
										href="##"
										className="d-block"
										data-bs-target="#editProfilePreferences"
										data-bs-toggle="modal"
										data-bs-dismiss="modal"
									>
										<img
											src="/assets/images/configure.png"
											alt="filter"
										/>
									</a>
								</div> } */}
							</div>
							{/* <p>An Overview of your activities on The Auntie Network</p> */}
						</>
					)}
				</div>
				<EditPreferencesModal profilePage={false} />
			</div>
			{/* listing */}
			{isLoading && (
				<div>
					<NetworkLoader />
				</div>
			)}
			{!isLoading && (
				<div className="row users-listing m-0">
					{networks.map((data, i) => (
						<NetworkCard network={data} key={data.id} />
					))}
				</div>
			)}
		</div>
	);
};

export default Networks;
