import Loader from "./loader";


function InvitationsLoader() {
  return (
    <>
    <div className="row justify-content-between">
      <div className="col-6 mt-2">
        <p className="reset-margin-left-5 mt-2">
          <Loader height="40" width="90%" />
        </p>
      </div>
      <div className="col-4 mt-2">
        <p className=" mt-2">
          <Loader height="35" width="150" radius="15" />
        </p>
      </div>
    </div>
    <div className="row justify-content-between">
      <div className="col-6 mt-2">
        <p className="reset-margin-left-5 mt-2">
          <Loader height="40" width="90%" />
        </p>
      </div>
      <div className="col-4 mt-2">
        <p className=" mt-2">
          <Loader height="35" width="150" radius="15" />
        </p>
      </div>
    </div>
    <div className="row justify-content-between">
      <div className="col-6 mt-2">
        <p className="reset-margin-left-5 mt-2">
          <Loader height="40" width="90%" />
        </p>
      </div>
      <div className="col-4 mt-2">
        <p className=" mt-2">
          <Loader height="35" width="150" radius="15" />
        </p>
      </div>
    </div>
    </>
  );
}

export default InvitationsLoader;