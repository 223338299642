import React, { useState, useContext, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { UserContext } from "../../context/user.context";
import { routes } from "../routes";
import useLocalStorage from "../../hooks/useLocalStorage"
import PageTitle from '../../components/PageTitle';
import { capitalize } from '../../utils';


const UnAuthenticated = ({ page, Component, ...props }) => {
  const { data } = useContext(UserContext);
  const {getItem} = useLocalStorage()
  const onboardingData = getItem("TAN_ONBOARDING_DATA")
  const history = useHistory();
  console.log(page)

  

  
  useEffect(() => {
    const navigate = (path) => history.push(path);


    if(onboardingData){
      navigate('/onboarding')
    }

    if (data && data.user) {
      if (history.location.pathname === '/') return navigate('/dashboard');

      const isSecuredRoute = routes.filter(
        (route) =>
          route.basePath.includes(history.location.pathname) && route.secured
      );

      // console.log('unauth ', history.location.pathname, isSecuredRoute[0]);
      if (isSecuredRoute[0]) return navigate(history.location.pathname); 
    } else {
      document.documentElement.classList.remove("dashboard-page")
    }

  }, [data, history]);

  return <>
      <PageTitle title={`The Auntie Network - ${capitalize(page)}`} />
      <Component {...props} />
  </>
};

export default withRouter(UnAuthenticated);
