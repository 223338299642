import React from 'react';
import { FavoriteIconOutline } from '../Svgs';
import Loader from './loader';

const ProfileCardLoader = ({ currentUser, profile }) => {
  return (
    <>
      <div className='card card-light text-center'>
        <div className='card-body '>
          <div className='row'>
            <div className='w-100'>
              {!profile && (
                <div
                  className={`profile-like-it float-end d-flex justify-content-center align-items-center
                      }`}
                >
                  <i
                    className='fas fa-heart '
                    style={{ color: 'gainsboro' }}
                  ></i>
                </div>
              )}
            </div>
          </div>
          <div className='profile-img-with-text text-center'>
            <p
              className='round-image-120 mx-auto'
              style={{ overflow: 'hidden' }}
            >
              <Loader
                height='100%'
                width='150%'
                // className='round-image-120 mx-auto'
              />
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                marginTop: '20px',
              }}
            >
              <p>
                {' '}
                <Loader height='20' width='100%' />
              </p>
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  marginTop: '5px',
                }}
              >
                <Loader height='20' width='100%' />

                <Loader height='20' width='100%' />
              </div>
            </div>
            <div className='action-buttons mt-3'>
              <div className='row justify-content-center'>
                <p className='  w-75 btn d-flex align-items-center justify-content-center'>
                  <Loader height='30' width='100%' radius='15' />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCardLoader;
