/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import cogoToast from "cogo-toast";
import React, { useContext, useEffect, useState } from "react";
import {
  useHistory,
  useParams,
  withRouter,
  useLocation,
} from "react-router-dom";
import RecommendUsersModal from "../../components/Modals/RecommendUsersModal";
import {
  BioData,
  ParentChatCard,
  ProfileImageGrid,
  Tag,
  TagList,
} from "../../components/Profile";
import { UserContext } from "../../context/user.context";
import {
  GetNetworkProfile,
  GetProfileImages,
  GetUser,
  GetChatRequest
} from "../../requests/user";
import {
  capitalize,
  getAgeFromYear,
  getFullName,
  profileIsChild,
  profileIsParent,
} from "../../utils";
import ProfileCard from "./ProfileCard";
import SingleProfileLoader from "../../components/loaders/SingleProfileLoader";

// import "../../assets/scss/dashboard.scss";

function LocationIcon() {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.25 6.66675C13.25 8.69862 11.9257 10.6824 10.4555 12.2343C9.73579 12.994 9.0135 13.6162 8.4703 14.0488C8.29117 14.1914 8.13218 14.3129 8 14.4114C7.86782 14.3129 7.70883 14.1914 7.5297 14.0488C6.9865 13.6162 6.26421 12.994 5.54446 12.2343C4.0743 10.6824 2.75 8.69862 2.75 6.66675C2.75 5.27436 3.30312 3.939 4.28769 2.95444C5.27226 1.96987 6.60761 1.41675 8 1.41675C9.39239 1.41675 10.7277 1.96987 11.7123 2.95444C12.6969 3.939 13.25 5.27436 13.25 6.66675Z"
        stroke="#4E4B66"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 6.66675C9.25 7.3571 8.69036 7.91675 8 7.91675C7.30964 7.91675 6.75 7.3571 6.75 6.66675C6.75 5.97639 7.30964 5.41675 8 5.41675C8.69036 5.41675 9.25 5.97639 9.25 6.66675Z"
        stroke="#4E4B66"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const SingleProfile = (props) => {
  /*
  const {
    location: { state },
  } = props;
  */
  const { data, userId, profile: currentUser, logout } = useContext(UserContext);
  const { profileId } = useParams();
  const { senderId } = useParams()
  console.log(senderId)
  // const userProfile = GetUser(profileId);
  const { go, goBack, goForward } = useHistory();
  const [profile, setProfile] = useState(null);
  //const [senderId] = useState(state && state.senderId);
  const [isLoading, setIsLoading] = useState(false);
  const user = data && data.user;
  const [loading, setLoading]  = useState(false)
  const [chatRequest, setChatRequest] = useState(false)

  const history = useHistory();

  const checkAuthorizedUser = (error) => {
    if (error?.message === "Request failed with status code 401") {
      logout(history);
    }
  };


  const checkChatRequest = () => {
    let data;

    if (profileIsParent(user.userType)) {
      //console.log("sender id", user.id);
      //console.log("reciever child", profile.id);
      data = {
        senderUserId: user.id, // user id (userType === 'Parent')
        receiverChildUserId: profileId, // id of the current network profile
      };
    } else {
      data = {
        senderChildUserId: user.id, // user id (userType === 'Child')
        receiverChildUserId: profileId, // id of the current network profile
      };
    }

    setLoading(true);
    GetChatRequest(data)
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200 && res.data) {
          setChatRequest(res.data);
        } else {
          setChatRequest(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        checkAuthorizedUser(err);
        // console.log("err ", err.code, err.statusCode, err);
      });
  };

  useEffect(() => {
    if (user && profileIsParent(user.userType)) checkChatRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user && user.id, profile && profile.id]);

  const loadProfileData = () => {
    setIsLoading(true);
    GetNetworkProfile({ userId, profileId })
      .then(({ data }) => {
        data && setProfile(data);

        profile && setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log("ERROR::LOAD_PROFILE -> ", err);
      });
  };

  useEffect(() => {
    if (profileId) {
      loadProfileData();
    }
    if (user && profileIsParent(user.userType)) checkChatRequest();

  }, [profileId]);

  const returnToPreviousPage = (e) => {
    e.stopPropagation();
    go(-2);
  };

  function BackButton() {
    return (
      <div
        className="w-100 back-to-network mt-2"
        onClick={returnToPreviousPage}
      >
        <span>&nbsp;</span>
      </div>
    );
  }

  if (isLoading && !profile) {
    return (
      <>
        <SingleProfileLoader currentUser={currentUser} profile={profile} />
      </>
    );
  }

  // export const profileIsChild = (usertype) => usertype.toLowerCase() === 'child';

  return (
    <div className="row single-explore-profile m-0">
      {profile && (
        <>
          {chatRequest && 
          <div class="alert alert-warning w-50 m-auto justify-content-center text-center">
            {`A chat Request has already been made to ${chatRequest.receiver.firstName} for ${chatRequest.receiverChild.firstName} on behalf of ${chatRequest.senderChild.firstName}`}
          </div>
          }
          <BackButton goBack={goBack}></BackButton>
          <div className="col-md-3">
            <ProfileCard
              senderId={senderId}
              profile={profile}
              isProfilePage={false}
            ></ProfileCard>
            {!profileIsChild(currentUser?.userType) && (
              <ParentChatCard
                disabled={profileIsChild(profile?.userType)}
                parent={profile.parent}
              ></ParentChatCard>
            )}
          </div>
          <div className="col-md-8 content-wrapper">
            {<TagList title="Hobbies" tags={profile?.hobbies}></TagList>}
            <div className="user-location">
              <p>Location</p>
              <div>
                <Tag
                  text={profile?.location || "Unknown"}
                  Icon={LocationIcon}
                />
              </div>
            </div>
            <BioData text={profile?.bio}></BioData>
            {profileIsChild(profile?.userType) && (
              <ProfileImageGrid profile={profile}></ProfileImageGrid>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(SingleProfile);
