import {useState} from 'react'

import Chats from "./Chats";
import Messages from "./Messages";


const Chat = () => {

  const [showChats, setShowChats] = useState(true)

  const screenIsMobile = window.screen.width <= 765

  const showChatsHandler = ()=>{
    if(screenIsMobile){
      setShowChats(prev=>!prev)
    } else {
      return
    }
  }
  
  return (
    <>
      <div className="row chat-container m-0">
        {/* messages */}
        <div className={`col-lg-5 col-md-12 col-sm-12 col-xs-12 ${!showChats && screenIsMobile && 'd-none'}`}>
          <Chats showChatsHandler={showChatsHandler} />
        </div>
        {/* messages */}
        {/* chat ui */}
        <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 ${showChats && screenIsMobile && 'd-none'}`}>
          <Messages  screenIsMobile={screenIsMobile} showChatsHandler={showChatsHandler} />
        </div>
        {/* chat ui */}
      </div>
    </>
  );
};

export default Chat;
