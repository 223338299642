import { useState, useContext, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { OnboardingContext } from "../../context";
import SpiralLoader from '../../components/loaders/SpiralLoader'



const Finish = ({ nextStep, previousStep }) => {
    const { registrationCompleted } = useContext(OnboardingContext);
    const [completed, setCompleted] = useState(false)
    const histroy = useHistory()
    const toDashboard = () => {
        histroy.push("/dashboard")
    }

    useEffect(()=>{
        if(registrationCompleted){
            setTimeout(()=>{
                setCompleted(true)
            }, 5000)
        }
    }, [registrationCompleted])

    return (
        <>
            <div className="col-md-9 col-lg-5 col-xl-4 mx-auto text-container text-center">
                <img src="/assets/images/checkmark.png" className="checkmark animate__animated animate__rotateIn" alt="check" width="90px" height="auto" />
                <h2>Thanks for completing your profile</h2>
                {
                    completed
                    ?
                    <div className='dashboardReady'>
                        <h2>Your dashboard is ready</h2>
                        <div className="action-buttons">
                            <button onClick={toDashboard} type="button" className="btn solid-btn btn1">Go to dashboard</button>
                        </div>
                        
                    </div>
                    :
                    <>
                        <h4>Preparing dashboard</h4>
                        <div className="dashboardSpiral">
                            <SpiralLoader size='sm1' />
                        </div>
                    </>
                }
                
            </div>
        </>
    )
}


export default Finish;