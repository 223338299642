import React, { useContext, useState } from 'react';
import SpiralLoader from '../loaders/SpiralLoader';
import Classes from './EditProfile.module.css';
import { DeleteImages } from '../../requests/user';

import { UserContext } from '../../context/user.context';
import cogoToast from 'cogo-toast';

const ImageModalGrid = ({ imageUrl, fileName }) => {
  const { getProfileImages, profile } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  //Delete Images handler
  const deleteImage = async (id) => {
    try {
      setLoading(true);
      const data = {
        userId: profile.id,
        profileImages: [id],
      };

      await DeleteImages({ ...data });

      const { hide } = cogoToast.success('Image Deleted Successfully 👌', {
        position: 'top-center',
        hideAfter: 3000,
      });

      setLoading(false);
      await getProfileImages(profile.id);

      setTimeout(hide, 1000);
    } catch (err) {
      cogoToast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div className='col-3 image-wrapper'>
      <img
        alt='profile avatar upload option'
        src={imageUrl || 'assets/images/bg_image.png'}
        className='img-fluid mb-4'
        style={{
          border: '1px dashed rgba(128, 128 ,128, 0.5)',
        }}
      />

      {!loading ? (
        <span onClick={() => deleteImage(fileName)}>
          <svg
            width={21}
            height={20}
            viewBox='0 0 21 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M15.0879 5L5.08789 15'
              stroke='#E80F6D'
              strokeWidth={2}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M5.08789 5L15.0879 15'
              stroke='#E80F6D'
              strokeWidth={2}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </span>
      ) : (
        <span className={Classes.svg1}>
          <SpiralLoader size='sm2' />
        </span>
      )}
    </div>
  );
};

export default ImageModalGrid;
