import { useEffect, useState } from "react";
import { GetProfileImages } from "../../requests/user";
import { capitalize, getFullName, profileIsChild } from "../../utils";
import Image from "../../components/shared/Image";
import { useHistory, useParams } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context/user.context";
import ImageGridLoader from "../loaders/ImageGridLoader";

export const Tag = ({ text, Icon }) => {
  return (
    <>
      {Icon && <Icon />}
      <span>{capitalize(text)}</span>
    </>
  );
};

export const TagList = ({ title, tags = [] }) => {
  return (
    <div className="user-hobbies text-start">
      <p>{capitalize(title)}</p>
      <div>
        {tags && tags.map((tag, i) => <Tag key={i} text={tag} />)}
        {!tags && <Tag text="No Hobbies" />}
      </div>
    </div>
  );
};

export const BioData = ({ text }) => {
  return (
    <div className="user-bio text-start">
      <p>Bio</p>
      <div>
        <p>{text || "No Bio yet"}</p>
      </div>
    </div>
  );
};

function ChatIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="d-none"
    >
      <path
        d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
        stroke="#E80F6D"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const ParentChatCard = ({ disabled, parent }) => {
  const openChat = () => {
    if (disabled) return;
  };

  return (
    <div className="user-parents mt-4">
      <div className="text-center">Parent</div>
      <div className="card card-light">
        <div
          className={`card-body ${disabled && "disabled"}`}
          disabled={disabled}
        >
          <div className="row justify-content-between">
            <div className="col-3">
              <Image
                src={parent?.profileImage}
                fallback={parent?.profileImage}
                alt="parent-profileImage"
                className="round-image-56"
              />
            </div>
            <div
              className="col-7 pt-1"
              style={{
                // width: 'auto',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              title={getFullName(parent?.firstName, parent?.lastName)}
            >
              <p>
                {getFullName(parent?.firstName, parent?.lastName)}{" "}
                <span
                  style={{
                    width: "auto",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  title={parent?.profession}
                >
                  {parent?.profession || ""}
                </span>
              </p>
            </div>
            <div className="col-2 pt-3" onClick={openChat}>
              <ChatIcon></ChatIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProfileImageGrid = ({ profile }) => {
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);

  const { profileId } = useParams();

  const history = useHistory();

  const { logout, images, loading: isLoading } = useContext(UserContext);

  //check if user is authorized with a 401 error code
  const checkAuthorizedUser = (error) => {
    if (error?.message === "Request failed with status code 401") {
      logout();
    }
  };

  const getProfileImages = async (id) => {
    setLoading(true);
    try {
      const res = await GetProfileImages(id);

      if (res.statusCode === 200) {
        setImage(res.data);
        setLoading(false);
      }
    } catch (err) {
      // setLoading(false);
      // console.log("ERROR ", e);
      checkAuthorizedUser(err);
    }
  };

  useEffect(() => {
    if (!profileId) return;

    // setLoading(true);
    getProfileImages(profileId);

    // setLoading(false);
  }, [profileId]);

  // {
  //   loading && <ImageGridLoader />;
  // }

  if (loading || isLoading) {
    return <ImageGridLoader />;
  }

  if (profileId && !loading && !image.length > 0) {
    return (
      <div className="d-flex gap-4 flex-column">
        <p>Pictures</p>
        <p>No Images available</p>
      </div>
    );
  }

  if (!profileId && !images?.length > 0 && !isLoading) {
    return (
      <div className="d-flex gap-4 flex-column">
        <p>Pictures</p>
        <p> No Images available</p>
      </div>
    );
  }

  return (
    <div className="user-pictures">
      <p>Pictures</p>

      <div className="row">
        {profileId && !loading
          ? image?.map((image, idx) => (
              <ImageContainer
                key={idx}
                src={image.imageUrl}
                // loading={loading}
              ></ImageContainer>
            ))
          : images &&
            !loading &&
            images?.map((image, idx) => (
              <ImageContainer
                key={idx}
                src={image.imageUrl}
                // loading={loading}
              ></ImageContainer>
            ))}
      </div>
    </div>
  );
};

export const ImageContainer = ({ src, loading }) => {
  return (
    <div className="col-4 list-img-container">
      <img
        alt="profile avatar"
        src={src ? src : "../../assets/images/profile.png"}
        className="img-fluid mb-4"
      />
    </div>
  );
};
