import { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { UserContext } from '../../context/user.context';
import {
	states,
	occupations,
	interests,
	PreferencesOptions,
} from '../../utils/data';
import { postUserPreferences } from '../../requests/user';
import cogoToast from 'cogo-toast';

const EditPreferencesModal = ({ isActive, handleClose, profilePage }) => {
	const [items, setItems] = useState({});

	const { data } = useContext(UserContext);
	const user = data && data.user;

	const inchesToFeet = (value) => {
		const feet = (value / 12).toFixed(1).split('.');

		return `${feet[0]}' ${feet[1]}"`;
	};

	useEffect(() => {
		// height slider
		const noUiSlider = window.noUiSlider;
		const heightRange = document.querySelector('#height-range');
		var labels = {
			40: `3' 3"`,
			55: `4' 6"`,
			67: `5' 6"`,
			77: `6' 4"`,
			85: `7' 1"`,
			90: `7' 5"`,
			100: `8' 3"`,
		};
		noUiSlider.create(heightRange, {
			start: 55,
			connect: [true, false],
			tooltips: {
				to: function (value) {
					return value > 100 ? '100+' : inchesToFeet(parseInt(value));
				},
			},
			range: {
				min: 40,
				'16%': 55,
				'32%': 67,
				'48%': 77,
				'64%': 85,
				'80%': 90,
				max: 100,
			},
			pips: {
				mode: 'steps',
				filter: function (value, type) {
					return type === 0 ? -1 : 1;
				},
				format: {
					to: function (value, key) {
						return labels[value];
					},
				},
			},
		});
	}, []);

	const getSliderValue = () => {
		let slider = document.querySelector('#height-range');
		let sliderValue = slider?.noUiSlider?.get();
		return sliderValue;
	};

	const interestOptions = interests.map((interest) => ({
		value: interest,
		label: interest,
	}));

	const occupationOptions = occupations.map((occupation) => ({
		value: occupation,
		label: occupation,
	}));

	const locationOptions = states.map((location) => ({
		value: location,
		label: location,
	}));

	const immigrationStatusOptions = PreferencesOptions.immigrationStatus.map(
		(option) => ({ value: option, label: option })
	);

	const raisedInOptions = PreferencesOptions.raisedIn.map((option) => ({
		value: option,
		label: option,
	}));

	const educationOptions = PreferencesOptions.education.map((option) => ({
		value: option,
		label: option,
	}));

	const annualIncomeOptions = PreferencesOptions.annualIncome.map(
		(option) => ({ value: option, label: option })
	);

	const religionOptions = PreferencesOptions.religion.map((option) => ({
		value: option,
		label: option,
	}));

	const communityOptions = PreferencesOptions.community.map((option) => ({
		value: option,
		label: option,
	}));

	const dietOptions = PreferencesOptions.diet.map((option) => ({
		value: option,
		label: option,
	}));

	const handleChange = (name, value) => {
		if (profilePage) {
			setItems({ ...items, [name]: value.map((item) => item.value) });
		} else {
			setItems({ ...items, [name]: value.value });
		}
	};

	const handleSubmit = () => {
		items.height = getSliderValue();
		items.id = user.id;
		console.log(items);
		postUserPreferences(items)
			.then((res) => {
				cogoToast.success('Preferences Updated');
				console.log(res);
			})
			.catch((err) => console.log(err));
	};

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			'z-index': '100',
		}),

		control: (provided, state) => ({
			...provided,
			'border-radius': '16px',
			padding: '9px 9px',
			'margin-bottom': '5px',
			background: '#EFF0F7',
			color: '#6E7191',
			border: 'none',
		}),
	};

	return (
		<div
			className="modal edit-profile-pref"
			id="editProfilePreferences"
			tabIndex={-1}
			aria-labelledby="editProfilePreferencesLabel"
			aria-hidden="true"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
		>
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-centered modal-xl">
				<div className="modal-content px-5">
					<div className="modal-header">
						<h5
							className="modal-title d-flex gap-4 align-items-center"
							id="editProfilePreferencesLabel"
						>
							<span>Search Preferences</span>
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						/>
					</div>
					<div className="modal-body py-0  ">
						<form className="flex">
							<div className="container-fluid d-flex flex-column gap-3 p-0">
								<div></div>
								<div className="row">
									<div className="col-3">
										<div className="col-12 mb-0">
											<label
												htmlFor
												className="form-label px-3 mb-0"
											>
												Gender
											</label>
											<Select
												onChange={(selectedOption) =>
													handleChange(
														'gender',
														selectedOption
													)
												}
												options={[
													{
														value: 'Male',
														label: 'Male',
													},
													{
														value: 'Female',
														label: 'Female',
													},
													{
														value: 'binary',
														label: 'binary',
													},
													{
														value: 'both',
														label: 'both',
													},
												]}
												styles={customStyles}
												isMulti={profilePage}
											/>
										</div>
									</div>
									<div className="col-3 mb-0">
										<label
											htmlFor
											className="form-label px-3 mb-0"
										>
											Hobbies
										</label>
										<Select
											onChange={(selectedOption) =>
												handleChange(
													'hobbies',
													selectedOption
												)
											}
											options={interestOptions}
											styles={customStyles}
											isMulti
										/>
									</div>
									<div className="col-3 mb-0">
										<label
											htmlFor
											className="form-label px-3 mb-0"
										>
											Immigration Status
										</label>
										<Select
											onChange={(selectedOption) =>
												handleChange(
													'immigrationStatus',
													selectedOption
												)
											}
											options={immigrationStatusOptions}
											styles={customStyles}
											isMulti={profilePage}
										/>
									</div>
									<div className="col-3 mb-0">
										<label
											htmlFor
											className="form-label px-3 mb-0"
										>
											Diet
										</label>
										<Select
											onChange={(selectedOption) =>
												handleChange(
													'diet',
													selectedOption
												)
											}
											options={dietOptions}
											styles={customStyles}
											isMulti={profilePage}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-3 mb-0">
										<label
											htmlFor
											className="form-label px-3 mb-0"
										>
											Community
										</label>
										<Select
											onChange={(selectedOption) =>
												handleChange(
													'community',
													selectedOption
												)
											}
											options={communityOptions}
											styles={customStyles}
											isMulti={profilePage}
										/>
									</div>

									<div className="col-3 mb-0">
										<label
											htmlFor
											className="form-label px-3 mb-0"
										>
											Caste
										</label>
										<Select
											onChange={(selectedOption) =>
												handleChange(
													'caste',
													selectedOption
												)
											}
											options={[
												{
													value: 'Dalits',
													label: 'Dalits',
												},
												{
													value: 'kshatriyas',
													label: 'kshatriya',
												},
												{
													value: 'Shudras',
													label: 'Shudras',
												},
												{
													value: 'Vaishyas',
													label: 'Vaishyas',
												},
											]}
											styles={customStyles}
											isMulti={profilePage}
										/>
									</div>
									<div className="col-3 mb-0">
										<label
											htmlFor
											className="form-label px-3 mb-0"
										>
											Education
										</label>
										<Select
											onChange={(selectedOption) =>
												handleChange(
													'education',
													selectedOption
												)
											}
											options={educationOptions}
											styles={customStyles}
											isMulti={profilePage}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-3 mb-0">
										<label
											htmlFor
											className="form-label px-3 mb-0"
										>
											Raised In
										</label>
										<Select
											onChange={(selectedOption) =>
												handleChange(
													'raisedIIn',
													selectedOption
												)
											}
											options={raisedInOptions}
											styles={customStyles}
											isMulti={profilePage}
										/>
									</div>

									<div className="col-3 mb-0">
										<label
											htmlFor
											className="form-label px-3 mb-0"
										>
											Religion
										</label>
										<Select
											onChange={(selectedOption) =>
												handleChange(
													'religion',
													selectedOption
												)
											}
											options={religionOptions}
											styles={customStyles}
											isMulti={profilePage}
										/>
									</div>

									<div className="col-3">
										<label
											htmlFor
											className="form-label px-3 mb-0"
										>
											Location
										</label>
										<Select
											onChange={(selectedOption) =>
												handleChange(
													'location',
													selectedOption
												)
											}
											options={locationOptions}
											styles={customStyles}
											isMulti={profilePage}
										/>
									</div>
								</div>

								<div className="col-3">
									<label
										htmlFor
										className="form-label px-3 mb-0"
									>
										Occupation
									</label>
									<Select
										onChange={(selectedOption) =>
											handleChange(
												'occupation',
												selectedOption
											)
										}
										options={occupationOptions}
										styles={customStyles}
										isMulti={profilePage}
									/>
								</div>
								<div className="row input-container">
									<p className="px-4">Height</p>
									<div className="form-group">
										<div id="height-range" />
									</div>
								</div>
							</div>
						</form>
					</div>
					<div className="modal-footer action-buttons">
						<button
							className="btn transparent-gray d-flex justify-content-center align-items-center"
							data-bs-dismiss="modal"
						>
							Clear Filters
						</button>
						<button
							onClick={handleSubmit}
							className="btn solid-btn d-flex justify-content-center align-items-center"
						>
							{'Apply Filters'}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditPreferencesModal;
