import React from 'react';
import SpiralLoader from '../loaders/SpiralLoader';
import Classes from './EditProfile.module.css';

const ImagePreview = ({
  avatars,
  setAvatars,
  handleAvatarChanges,
  UploadFileIcon,
  position,
}) => {
  return (
    <div className='col-3 image-wrapper'>
      <img
        alt='profile avatar upload option'
        src={avatars[position].preview || 'assets/images/bg_image.png'}
        className='img-fluid mb-4'
        style={{
          opacity: avatars[position].loading && 0.5,
          border: '4px dashed rgba(128, 128 ,128, 0.5)',
        }}
      />

      {!avatars[position].loading && (
        <span
          onClick={() =>
            setAvatars({
              ...avatars,
              [position]: {
                raw: false,
                preview: null,
                loading: false,
              },
            })
          }
        >
          <svg
            width={21}
            height={20}
            viewBox='0 0 21 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M15.0879 5L5.08789 15'
              stroke='#E80F6D'
              strokeWidth={2}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M5.08789 5L15.0879 15'
              stroke='#E80F6D'
              strokeWidth={2}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </span>
      )}
      {!avatars[position].loading && (
        <label
          className={`${Classes.imageLoaderContainer} d-block`}
          htmlFor={position}
        >
          <UploadFileIcon></UploadFileIcon>
        </label>
      )}
      <input
        type='file'
        id={position}
        onChange={handleAvatarChanges}
        name={position}
        style={{ display: 'none' }}
        onClick={(e) => {
          e.target.value = null;
        }}
      />

      {avatars[position].loading && (
        <div className={Classes.imageLoaderContainer}>
          {' '}
          <SpiralLoader size='sm1' />{' '}
        </div>
      )}
    </div>
  );
};

export default ImagePreview;
