import Loader from './loader'

const ChatsLoader = () =>{
    return (
        <div className={`user-list-container`}>
        <div
          className="row justify-content-between user-single-list"
        >
          <div className="col-9">
            <div className=" float-start pt-1">
              <Loader height="60" width="60" radius="50%"/>
            </div>
            <div className="">
                <p className="new">
                    <Loader width="100"  height="20" />
                </p>
                <p className="new">
                    <Loader width="140"  height="20" />
                </p>
            </div>
          </div>
          <div className="col-3 text-center">
            <div className="status-time-ref">
              <span className="chat-count-inner mx-auto"><Loader width="10" height="10" radius="50%" /></span>
             <Loader width="20" height="10" />
            </div>
          </div>
        </div>
      </div>
    )
}

export default ChatsLoader