/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { Sidebar } from "../../components/shared/SideBar";
// import { MessagingProvider } from '../../context/messaging.context';
import { UserContext } from "../../context/user.context";
import { routes } from "../routes";
import "../../assets/scss/dashboard.scss";
import "../../assets/scss/responsive.scss";
import PageTitle from "../../components/PageTitle";
import { capitalize } from "../../utils";

const Authenticated = ({ Component, page, ...props }) => {
  const { data } = useContext(UserContext);

  const history = useHistory();
  const [className, setClassName] = useState("");

  function updateClassName(newClassName) {
    className && document.documentElement.classList.remove(className);

    if (!newClassName) return;
    document.documentElement.classList.add(newClassName);
    setClassName(newClassName);
  }

  useEffect(() => {
    const route = routes.find(
      (route) =>
        history.location.pathname
          .substr(1)
          .includes(route.basePath.substr(1)) && route.secured
    );
    console.log("route ", route, route.basePath, history.location.pathname);
    const newClassName = route && (route.class || "");
    if (!className && !newClassName) return;
    updateClassName(newClassName);
  }, [data]);

  return (
    <div className={`container-fluid dashboard-body-container`}>
      <PageTitle title={`The Auntie Network - ${capitalize(page)}`} />
      <Sidebar page={page} data={data && data.user} {...props} />
      <Component />
    </div>
  );
};

export default withRouter(Authenticated);
