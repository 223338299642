import Loader from './loader';

const ImageGridLoader = () => {
  return (
    <div className='user-pictures'>
      <p>Pictures</p>

      <div className='d-flex gap-3'>
        <div className='col-4'>
          <Loader height='100%' width='100%' />

          {/* <Loader height='50' width='100%' /> */}
        </div>

        <div className='col-4'>
          <Loader height='100%' width='100%' />

          {/* <Loader height='50' width='100%' /> */}

          {/* {!profileId && !image?.length > 0 && <div>No Images available</div>} */}
        </div>

        <div className='col-4'>
          <Loader height='100%' width='100%' />

          {/* <Loader height='50' width='100%' /> */}

          {/* {!profileId && !image?.length > 0 && <div>No Images available</div>} */}
        </div>
      </div>
    </div>
  );
};

export default ImageGridLoader;
