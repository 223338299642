import { Link, useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import styles from './subscriptions.module.scss';
import { UserContext } from '../../context/user.context';
import { GetPlans, createCustomer } from '../../requests/user';

const Subscriptions = () => {
	const history = useHistory();
	const { setPlan, data } = useContext(UserContext);

	const user = data && data.user;

	const navigate = (path, data) => {
		setPlan(data);
		history.push('subscriptions/payment');
	};

	const [plans, setPlans] = useState([]);

	const getPlans = () => {
		GetPlans()
			.then((response) => {
				console.log(response);
				setPlans(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getPlans();
		if (user) {
			createCustomer();
		}
	}, [user]);

	return (
		<>
			<div className="row m-0">
				<div className="col">
					<h1 className="mb-0">Membership</h1>
				</div>
			</div>
			<div className={styles.pricing}>
				<section className={styles['pricing-modal']}>
					<section className={styles['pricing-cards-container']}>
						{plans.map((plan, i) => {
							return (
								<div
									key={i}
									className={`${styles['pricing-card']} ${
										i === 1 && styles['plan-2']
									}`}
								>
									<h2>
										{plan.amount === '0' ? (
											'Free'
										) : (
											<>
												${plan.amount}{' '}
												<span> /month</span>
											</>
										)}
									</h2>
									<h3>{plan.name}</h3>
									{/* <p>{plan.description}</p> */}
									<ul>
										{plan.description
											.split(',')
											.map((desc, i) => {
												return (
													<li key={i}>
														<img
															className={
																styles[
																	'checkmark-img'
																]
															}
															src="images/checkmark.png"
															alt=""
														/>{' '}
														<span>{desc}</span>
													</li>
												);
											})}
									</ul>
									<Link
										to={{
											pathname: '/subscriptions/payment',
											state: {
												userId: user.id,
												planId: plan.id,
											},
										}}
									>
										<button
											className={styles['choose-plan']}
										>
											Choose plan
										</button>
									</Link>
									{/* <button
                    onClick={() =>
                      navigate("/subscriptions/payment", {
                        state: {
                          userId: user.id,
                          planId: plan.id,
                        },
                      })
                    }
                    className={styles["choose-plan"]}
                  >
                    Choose plan
                  </button> */}
								</div>
							);
						})}
						{/* <div className={styles["pricing-card"]}>
              <h2>Free</h2>
              <h3>Basic</h3>
              <p>Edit your personal details, upload your Edit your</p>
              <ul>
                <li>
                  <img
                    className={styles["checkmark-img"]}
                    src="images/checkmark.png"
                    alt=""
                  />{" "}
                  <span>Free platform fee</span>
                </li>
                <li>
                  <img
                    className={styles["checkmark-img"]}
                    src="images/checkmark.png"
                    alt=""
                  />{" "}
                  <span>Payment Processing</span>
                </li>
                <li>
                  <img
                    className={styles["checkmark-img"]}
                    src="images/checkmark.png"
                    alt=""
                  />{" "}
                  <span>Children Accrounts</span>
                </li>
              </ul>
              <button
                onClick={() =>
                  navigate("/subscriptions/payment", {
                    userId: user.id,
                    planId: 5,
                  })
                }
                className={styles["choose-plan"]}
              >
                Choose plan
              </button>
            </div>
            <div className={`${styles["pricing-card"]} ${styles["plan-2"]}`}>
              <h2>
                $19.99 <span> /month</span>
              </h2>
              <h3>Individual</h3>
              <p>Edit your personal details, upload your Edit your</p>
              <ul>
                <li>
                  <img
                    className={styles["checkmark-img"]}
                    src="images/white-checkmark.png"
                    alt=""
                  />{" "}
                  <span>Free platform fee</span>
                </li>
                <li>
                  <img
                    className={styles["checkmark-img"]}
                    src="images/white-checkmark.png"
                    alt=""
                  />{" "}
                  <span>Payment Processing</span>
                </li>
                <li>
                  <img
                    className={styles["checkmark-img"]}
                    src="images/white-checkmark.png"
                    alt=""
                  />{" "}
                  <span>Children Accrounts</span>
                </li>
              </ul>
              <button
                onClick={() =>
                  navigate("/subscriptions/payment", {
                    userId: user.id,
                    planId: 3,
                  })
                }
                className={styles["choose-plan"]}
              >
                Choose plan
              </button>
            </div>
            <div className={styles["pricing-card"]}>
              <h2>
                $29.99 <span> /month</span>
              </h2>
              <h3>Family</h3>
              <p>Edit your personal details, upload your Edit your</p>
              <ul>
                <li>
                  <img
                    className={styles["checkmark-img"]}
                    src="images/checkmark.png"
                    alt=""
                  />{" "}
                  <span>Free platform fee</span>
                </li>
                <li>
                  <img
                    className={styles["checkmark-img"]}
                    src="images/checkmark.png"
                    alt=""
                  />{" "}
                  <span>Payment Processing</span>
                </li>
                <li>
                  <img
                    className={styles["checkmark-img"]}
                    src="images/checkmark.png"
                    alt=""
                  />{" "}
                  <span>Children Accrounts</span>
                </li>
              </ul>
              <button
                onClick={() =>
                  navigate("/subscriptions/payment", {
                    userId: user.id,
                    planId: 4,
                  })
                }
                className={styles["choose-plan"]}
              >
                Choose plan
              </button>
            </div> */}
					</section>
				</section>
			</div>
		</>
	);
};

export default Subscriptions;
