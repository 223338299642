/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import cogoToast from 'cogo-toast';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { UserContext } from '../../context/user.context';
import { InviteChild, GetUserInvitations } from '../../requests/user';
import { sortRecommendations } from '../../utils';
import { deleteInvitation } from '../../requests/user';

// styles
//import '../../assets/scss/dashboard.scss';

import Recommendations from './Recommendations';
import Favorites from './Favorites';
import Matches from './Matches';
import RecentChats from './RecentChats';
import InviteChildrenModal from '../../components/Modals/InviteChildrenModal';
import Invitations from './Invitations';

export const Dashboard = () => {
  let { data } = useContext(UserContext);
  // const modalRef = useRef();

  // console.log(modalRef.current?.classList);
  const [childEmail, setEmail] = useState('');
  const [invitations, setInvitations] = useState([]);
  const [loadingInvitations, setLoadingInvitations] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const closeModal = () => {
    const $ = window.$ || window.jQuery;

    $(function () {
      $('.modal').modal('hide');
    });
  };

  const sortInvitations = (invitations)=>{
      const registered = []
      const accepted = []
      const pending = []

      invitations.forEach(invitation=>{
        if(invitation.status === 'Registered'){
          registered.push(invitation)
        }
        if(invitation.status === 'Accepted'){
          accepted.push(invitation)
        }
        if(invitation.status === 'Pending'){
          pending.push(invitation)
        }
      })

      return registered.concat(accepted).concat(pending)
  }

  const loadInvitations = () => {
    if (!data.user || loadingInvitations) return;
    setLoadingInvitations(true);
    GetUserInvitations(data.userId)
      .then((data) => {
        setLoadingInvitations(false);
        setInvitations(sortInvitations(data.data))
      })
      .catch((e) => {
        setLoadingInvitations(false);
        // console.log("invite error ", e.message);
      });
  };

  const removeInvitation = (data)=>{
      deleteInvitation(data).then(res=>{
        if(res.statusCode==="200"){
          loadInvitations()
          cogoToast.success('Invitation deleted')
        }
      })
      .catch(err=>{
        console.log(err)
      })
  }

  useEffect(() => {
    if (data && data.user && data.user.userType.toLowerCase() === 'parent') {
      !loadingInvitations && loadInvitations();
    }
  }, [data]);

 

  const inviteChild = async () => {
    if (!childEmail.match(emailRegex)) {
      return cogoToast.error('Invalid email address', {
        position: 'top-center',
      });
    }
    // const { hide: hideLoader } = cogoToast.loading('Sending invite')
    setSendingInvite(true);
    InviteChild({
      userId: data.userId,
      email: childEmail,
    })
      .then((res) => {
        // hideLoader();

        if (res.statusCode === 201) {
          setSendingInvite(false);
          const { hide } = cogoToast.success('Invitation Sent 📧', {
            position: 'top-center',
            hideAfter: 3000,
          });

          loadInvitations();

          setTimeout(hide, 1000);
          setTimeout(closeModal, 2000);
        }
      })
      .catch((err) => {
        // hideLoader();

        cogoToast.error(err.response && err.response.data.message);

        setSendingInvite(false);
      });
  };

  if (!data || !data.user) {
    return <div>Loading...</div>;
  }

  const { user } = data;

  return (
    <>
      <div className='row m-0'>
        <div className='col'>
          <h1 className='mb2'>{`Hi ${user.firstName}`}</h1>
          <p>An Overview of your activities on The Auntie Network</p>
        </div>
      </div>

      <div className='row m-0'>
        <Recommendations></Recommendations>

        <Favorites></Favorites>
      </div>

      <div className='row m-0'>
        <Matches></Matches>
        {user.userType === 'Child' && <RecentChats></RecentChats>}
        {user.userType === 'Parent' && (
          <Invitations
            loading={loadingInvitations}
            invitations={invitations}
            removeInvitation={removeInvitation}
          ></Invitations>
        )}
      </div>

      {user.userType === 'Parent' && (
        <InviteChildrenModal
          setEmail={setEmail}
          inviteChild={inviteChild}
          loading={sendingInvite}
          // modalRef={modalRef}
        ></InviteChildrenModal>
      )}
    </>
  );
};
