import React, { useContext, useState } from "react";
import { VALIDATE_STEP } from ".";
import { OnboardingContext } from "../../context";



const Gender = ({ nextStep, currentStep, updateSteps }) => {
    const { setData, data } = useContext(OnboardingContext);
    const [_gender, setGender] = useState("");
    const [showExtra, setExtraVisible] = useState(false);
    const toNextStep = (gender) => { 
        let _data = { ...data, gender: _gender || gender };
        if (VALIDATE_STEP(_data, currentStep - 1)) {
            setData({..._data, step:2});
            nextStep()
        }
    }
    
    

    return (
        <>
            <section className="col-md-9 col-lg-5 col-xl-4 mx-auto">
                <div className="heading-group ">
                    <span className="animate__animated animate__slideInDown animate__delay-3s step-1" />
                    <h1>Hi, let’s get started. Which best describes you?</h1>
                </div>

                {!showExtra ? <div className="form-group row cta-option-group">
                    <button type="button" className="btn" onClick={() => toNextStep("Male")}>I'm a Man</button>
                    <button type="button" className="btn" onClick={() => toNextStep("Female")}>I'm a Woman</button>
                    <button type="button" className="btn" onClick={() => setExtraVisible(true)}>More options</button>
                </div>
                    :
                    <>
                        <div className="input-container">
                            <div className="form-group">
                                <input onChange={({ target: { value } }) => setGender(value)} type="text" className="form-control" placeholder="Ex. Binary" />
                            </div>
                        </div>
                        <div className="action-buttons">
                            <div className="row">
                                <div className="col">
                                    <button type="button" className="btn fa-previous" onClick={() => setExtraVisible(false)}></button>
                                    <button type="button" className="btn fa-next float-end" onClick={toNextStep}></button>
                                </div>
                            </div>
                        </div>
                    </>}
            </section>
        </>
    )
}


export default Gender;