import { useState, useContext, useEffect } from 'react';

import { UserContext } from '../../context/user.context';
import { CheckIcon } from '../../components/Svgs';
import { NavLink } from 'react-router-dom';
import {
	capitalize,
	getAgeFromYear,
	getFullName,
	profileIsParent,
} from '../../utils';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router-dom';
import Image from '../../components/shared/Image';
import { RecommendationItemLoader } from '../../components/loaders/RecommendationsLoader';
import { GetMatches } from '../../requests/user';

const MatchItem = ({ data, showRecommendee, viewProfile }) => {
	return (
		<div className="row justify-content-between recommended-profile">
			<div className="col-1">
				<Image
					alt="recommended-profile"
					className="round-image-48"
					src={
						data.recommendation.profileImage ||
						'../../assets/images/dafault.png'
					}
					fallback="assets/images/default.png"
				/>
			</div>
			<div className="col-6">
				<p
					title={`${data.recommendation.firstName} ${data.recommendation.lastName}`}
					onClick={(event) => viewProfile(event, data)}
					className="profile-details"
				>
					{getFullName(
						data.recommendation.firstName,
						data.recommendation.lastName
					)}
					{(data.recommendation.gender ||
						data.recommendation.birthDate) && (
						<span>
							{capitalize(data.recommendation.gender)}{' '}
							{data.recommendation.gender && (
								<i className="fas fa-dot-circle" />
							)}
							{data.recommendation.birthDate &&
								`${getAgeFromYear(
									data.recommendation.birthDate
								)}y`}
						</span>
					)}
				</p>
			</div>
			<div className="col-5">
				{showRecommendee && data.sender && (
					<p className="recommendee text-center">
						{getFullName(
							data.sender.firstName,
							data.sender.lastName
						)}
					</p>
				)}
			</div>
		</div>
	);
};

function Matches() {
	const { data, logout } = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const [matches, setMatches] = useState([]);
	const history = useHistory();

	const user = data && data.user;

	//Check if users access token has expired with a 401 error code
	const checkAuthorizedUser = (error) => {
		if (error?.message === 'Request failed with status code 401') {
			logout(history);
		}
	};

	const getMatches = (id) => {
		GetMatches(id)
			.then((res) => {
				setLoading(false);
				if (res.statusCode === 200) {
					console.log(res.data);
					setMatches(res.data);
				}
			})
			.catch((err) => {
				checkAuthorizedUser(err);
				// console.log("err ", err);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (loading || matches.length) return;

		setLoading(true);
		getMatches(user.id);
	}, []);

	return (
		<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
			<div className="card card-dark card-min-height-380">
				<div className="card-body">
					<div className="row justify-content-between card-title-container mb-4">
						<div className="col-6">
							<h5 className="card-title  float-start">Matches</h5>
						</div>
						<div className="col-4">
							<span
								className={`${
									matches.length === 0 ? 'd-none' : ''
								} float-end`}
							>
								<a
									data-bs-toggle="modal"
									data-bs-target="#recommendations"
									href="##"
								>
									All <i class="fas fa-chevron-right"></i>
								</a>
							</span>
						</div>
					</div>

					{loading ? (
						<>
							<RecommendationItemLoader />
							<RecommendationItemLoader />
							<RecommendationItemLoader />
							{/* <RecommendationItemLoader /> */}
						</>
					) : (
						''
					)}

					{!loading &&
						matches.slice(0, 4).map((match) => (
							<div>
								<MatchItem
									data={match}
									showRecommendee={profileIsParent(
										user.userType
									)}
								></MatchItem>
							</div>
						))}

					{!loading && matches.length === 0 && (
						<div className="empty-card text-center">
							<div className="icon-container-dark mx-auto">
								<CheckIcon></CheckIcon>
							</div>
							<p className="card-text">
								Explore profiles to find matches
							</p>
							<NavLink
								className="btn btn-primary dark"
								to="/networks"
							>
								Explore
							</NavLink>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Matches;
