import { createContext, useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { SyncRegistration, UploadOnboardingImage } from "../requests/onboarding";

export const OnboardingContext = createContext();

export const OnboardingProvider = ({ children }) => {
  const [data, setData] = useState({
    userType: "Child",
    step:1
  });

  const  [location, setLocation] = useState(false)
  const [postalCode, setPostalCode] = useState(false)
  const [registrationCompleted, setRegistrationCompleted] = useState(false)

  const { setItem, removeItem, getItem } = useLocalStorage();

  const locationHandler = ()=>{
    setLocation(prevState=>!prevState)
  }

  const postalCodeHandler = ()=>{
    setPostalCode(prevState=>!prevState)
  }

  
  const registrationCompletedHandler =()=>{
    setRegistrationCompleted(prevState=>!prevState)
  }
  const _setData = async (_data) => {
    //if (_data.step > 1 && !_data.id) return;
    
    
    let syncData = { ...data, ..._data };
    if (_data.onboardingComplete) return;
  
    if(syncData.avatar_preview) {
        var formData = new FormData();
        formData.append("profileImage", syncData.avatar_preview);
        formData.append("userId", syncData.id);
        await UploadOnboardingImage(formData);
        console.log('uploading')

        delete syncData.avatar_preview;
    }
    
    SyncRegistration(syncData).then(({ data: res }) => {
      setItem("TAN_ONBOARDING_DATA", JSON.stringify(res));
      setData({ ...res, userType: "Child" });
    });
  };

  const clearData = () => {
      removeItem("TAN_ONBOARDING_DATA");
  }
  // useEffect(() => {

  // }, [data]);
//   console.log("next data ", JSON.parse(localStorage.getItem("data")));
  return (
    <OnboardingContext.Provider
      value={{
        state:location,
        locationHandler,
        postalCode,
        postalCodeHandler,
        registrationCompleted,
        registrationCompletedHandler,
        data: getItem("TAN_ONBOARDING_DATA")
          ? getItem("TAN_ONBOARDING_DATA")
          : data,
        setData: _setData,
        clearData
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
