import React, { useContext, useState } from "react";
import cogoToast from 'cogo-toast';
import { VALIDATE_STEP } from ".";
import { OnboardingContext } from "../../context";
import { formatDate} from "../../utils";

const DateOfBirth = ({ nextStep, previousStep, currentStep }) => {
    const { data, setData, locationHandler } = useContext(OnboardingContext);
    const {birthDate} = data
    const [dob, setDob] = useState(birthDate?new Date(birthDate).toISOString().split('T')[0]:null);
    const toNextStep = () => {
        locationHandler()
        if((new Date() - new Date(dob))/(1000 * 3600 * 24 * 365) < 18){
            cogoToast.error("You must be at least 18 years old", { position: "top-center" })
            return
        } 

        if( Boolean(new Date(dob).getTime()) === false){
            cogoToast.error("Invalid Date", { position: "top-center" })
            return
        }

        let _data = { ...data, birthDate:new Date(dob).toLocaleDateString() }
        console.log(_data)
        if (VALIDATE_STEP(_data, currentStep - 1)) {
            setData({..._data, step:4});
            nextStep()
        }
    }
    return (
        <>
            <section className="col-md-9 col-lg-5 col-xl-4 mx-auto">
                <div className="heading-group">
                    <span className="animate__animated animate__slideInDown animate__delay-3s step-3" />
                    <h1>What is your date of birth?</h1>
                </div>
                <div className="input-container">
                    <div className="form-group">
                        <input
                            onChange={({ target: { value } }) => setDob(value)}
                            name="birthDate"
                            type="date"
                            className="form-control input3"
                            placeholder="Date of Birth"
                            value = {dob?dob:''}
                        />
                    </div>
                </div>
                <div className="action-buttons">
                    <div className="row">
                        <div className="col">
                            <button type="button" className="btn fa-previous" onClick={previousStep} > </button>
                            <button type="button" className="btn fa-next float-end" onClick={toNextStep} ></button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DateOfBirth;