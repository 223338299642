/* eslint-disable react-hooks/exhaustive-deps */
import {
	createContext,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import cogoToast from 'cogo-toast';
import { UserContext } from './user.context';
import {
	SET_ACTIVE_CHAT,
	SET_ACTIVE_CHAT_REQUEST,
	UPDATE_CHAT_REQUEST,
	SET_CHATS,
	SET_CHAT_REQUESTS,
	SET_MESSAGES,
	SET_USER_ID,
} from './state/actions';
import { messagingReducer } from './state/reducers';
import useSocket, { CONNECTION_STATUS } from '../hooks/useSocket';
import { MESSAGING_URL } from '../requests/endpoints';

const initalState = {
	userId: '',
	newChatUser: '',
	chats: [],
	chatRequests: [],
	messagesStore: {},
	activeChat: null,
	activeChatRequest: null,
	message: '',
};

export const MessagingContext = createContext();

export const MessagingProvider = ({ children }) => {
  const { data } = useContext(UserContext);
  const [state, dispatch] = useReducer(messagingReducer, initalState);
  const [connection, connectionStatus] = useSocket(MESSAGING_URL);
  const [loading, setLoading] = useState(true);
  const [isMatchRequestLoading, setIsMatchRequestLoading] = useState(false)

  useEffect(() => {
    if (!data || !data.user) return;
    dispatch({ type: SET_USER_ID, payload: data.user.id });
  }, [data && data.user]);

  useEffect(() => {
    if (connection && connectionStatus === CONNECTION_STATUS.CONNECTED) {
      // SET LISTENERS
      setListeners();
    }

    return () => {
      // for all events
      console.log('clean up listeners ', connection);
      connection && connection.removeAllListeners();
    };
  }, [connectionStatus]);

  const setListeners = () => {
    // add relationships with connections
    connection.emit('messaging:connections:init', {
      userId: state.userId,
    });

    // get chats
    connection.emit('messaging:chats:getChats', {
      userId: state.userId,
    });

    // get chat requests
    console.log("Chat request ID --- "+ state.userId)
    connection.emit('messaging:chats:getChatRequests', {
      userId: state.userId, // change to receiverUserId
      // status: "Pending",
    });

    
    // receive chats
    connection.on('messaging:chats:chats', (chats) => {
      if (chats.statusCode >= 400) {
         //console.log("on messaging:chats:chats error", chats);
      } else {
        // console.log("on messaging:chats:chats", chats, chats.data);
        const chatList = chats.data.map(chat=>{
            // get messages for single chat
            connection.emit('messaging:messages:getMessages', {
              chatId:chat.chatId,
              userId: state.userId,
            });
      
            // receive messages
            connection.once(
              `messaging:messages:getMessages:${chat.chatId}`,
              (messages) => {
                // console.log("returned messages for " + chatId, messages);
                // state.messagesStore[chatId].messages = messages.data;
                // state.messagesStore[chatId].messagesLoaded = true;
                // chat.dateLastUpdated =  messages && messages.data.length > 0 && messages.data.at(-1).dateLastUpdated 
                dispatch({
                  type: SET_MESSAGES,
                  payload: {
                    id: chat.chatId,
                    data: { messages: messages.data, messagesLoaded: true },
                  },
                });
              }
            );
            return chat
        })
        console.log(chatList)
        dispatch({ type: SET_CHATS, payload: chatList });
        setLoading(false);
      }
    });

    // receive chats
    connection.on('messaging:chats:chatRequests', (chats) => {
      console.log("on messaging:chats:chats error", chats);
      if (chats.statusCode >= 400) {
        // console.log("on messaging:chats:chats error", chats);
      } else {
        // console.log("on messaging:chats:chats", chats, chats.data);
        dispatch({ type: SET_CHAT_REQUESTS, payload: chats.data });
        setLoading(false);
      }
    });

    // connection.onAny((event, ...args) => {
    //   console.log(`got ${event}`);
    // });

    // receive new messages
    connection.on('messaging:messages:newMessage', (data) => {
      // console.log('new message', data.message, data.chatId, uuidv4());

      // this.messagesStore[data.chatId].messages.push(data.message);
      dispatch({
        type: 'NEW_MESSAGE',
        payload: { id: data.chatId, data: data.message },
      });

      // if (data.chatId == this.activeChat) {
      //   this.scrollChatWindow();
      // }
    });

    // on message update -> id, status, content or delete
    connection.on(`messaging:messages:updatedMessage`, (data) => {
      //console.log('on update message ', data);
      // dispatch({
      //   type: "UPDATE_MESSAGE",
      //   payload: { chatId: data.chatId, data: data },
      // });

      if (data.updateType === 'id') {
        dispatch({
          type: 'UPDATE_MESSAGE_ID',
          payload: { chatId: data.chatId, data: data },
        });

        // this.messagesStore[data.chatId].messages.map((msg, index) => {
        //   if (msg.messageId === data.updateMeta.tmpMessageId) {
        //     this.messagesStore[data.chatId].messages[index] = data.message;
        //   }
        // });
      } else if (data.updateType === 'delete') {
        // console.log('its a delete operation', data);
        dispatch({
          type: 'DELETE_MESSAGE',
          payload: { chatId: data.chatId, data: data },
        });

        // this.messagesStore[data.chatId].messages.map((msg, index) => {
        //   if (msg.messageId === data.message.messageId) {
        //     this.messagesStore[data.chatId].messages.splice(index, 1);
        //   }
        // });
      } else if (data.updateType === 'body') {
        dispatch({
          type: 'UPDATE_MESSAGE_BODY',
          payload: { chatId: data.chatId, data: data },
        });

        // this.messagesStore[data.chatId].messages.map((msg, index) => {
        //   if (msg.messageId === data.message.messageId) {
        //     this.messagesStore[data.chatId].messages[index] = {
        //       ...this.messagesStore[data.chatId].messages[index],
        //       ...data.message,
        //     };
        //   }
        // });
      }
    });
  };

  const isConnected = () => connectionStatus === CONNECTION_STATUS.CONNECTED;

  const getChatMessages = (chatId) => {
    if (!isConnected()) return; // display toast to signal disconnectivity
    if (
      state.messagesStore[chatId] &&
      state.messagesStore[chatId].messagesLoaded === false
    ) {
      dispatch({ type: SET_ACTIVE_CHAT, payload: chatId });
      // get messages for single chat
      connection.emit('messaging:messages:getMessages', {
        chatId,
        userId: state.userId,
      });

      // receive messages
      connection.once(
        `messaging:messages:getMessages:${chatId}`,
        (messages) => {
          // console.log("returned messages for " + chatId, messages);
          // state.messagesStore[chatId].messages = messages.data;
          // state.messagesStore[chatId].messagesLoaded = true;
          dispatch({
            type: SET_MESSAGES,
            payload: {
              id: chatId,
              data: { messages: messages.data, messagesLoaded: true },
            },
          });
        }
      );
    }
    dispatch({ type: SET_ACTIVE_CHAT, payload: chatId });
  };

  const openChatRequest = (recordId) => {
    dispatch({ type: SET_ACTIVE_CHAT_REQUEST, payload: recordId });
  };

  const sendMessage = (message) => {
    if (!state.activeChat) return;
    const tmpId = Math.random().toString(36).substring(2, 7); //uuidv4();
    const tmpMsg = {
      authorId: state.userId,
      body: message,
      dateLastUpdated: new Date().toISOString(),
      messageId: tmpId,
      messageType: 'Exchange',
      status: 'Pending',
    };

    dispatch({
      type: 'NEW_MESSAGE',
      payload: { id: state.activeChat, data: tmpMsg },
    });

    connection.emit(`messaging:messages:newMessage`, {
      chatId: state.activeChat,
      authorId: state.userId,
      body: message,
      tmpMessageId: tmpId,
    });
  };

  const acceptChatRequest = (payload) => {


    console.log('accept ', payload);
    if (!isConnected()) return; // display toast;
    connection.emit('messaging:chats:acceptChatRequest', {
      ...payload,
      userId: state.userId,
    });

    connection.once(
      `messaging:chats:acceptChatRequest:${payload.id}`,
      (data) => {
        console.log('response from chat request ', data);
        dispatch({ type: SET_ACTIVE_CHAT_REQUEST, payload: '' });
        dispatch({ type: SET_CHATS, payload: data.newChatList.data });
        console.log(data.newChatList.data)

        //There is this seperation of loops because the new chat needs be set as active chat immediately after 
        // accepting because the second loop takes longer 

        data.newChatList.data.forEach(chat=>{
          console.log(payload.id, chat.chatRequestId)
          if(chat.chatRequestId === payload.id){
            dispatch({ type: SET_CHAT_REQUESTS, payload: data.newChatRequestList.data });
            dispatch({ type: SET_ACTIVE_CHAT, payload: chat.chatId });
          }
        })

        
        data.newChatList.data.forEach(chat=>{
          console.log("get messages for each chat")
          // get messages for single chat
          connection.emit('messaging:messages:getMessages', {
            chatId:chat.chatId,
            userId: state.userId,
          });
    
          // receive messages
          connection.once(
            `messaging:messages:getMessages:${chat.chatId}`,
            (messages) => {
              // console.log("returned messages for " + chatId, messages);
              // state.messagesStore[chatId].messages = messages.data;
              // state.messagesStore[chatId].messagesLoaded = true;
              // chat.dateLastUpdated =  messages && messages.data.length > 0 && messages.data.at(-1).dateLastUpdated 
              dispatch({
                type: SET_MESSAGES,
                payload: {
                  id: chat.chatId,
                  data: { messages: messages.data, messagesLoaded: true },
                },
              });
            }
          );
        })

        setLoading(false)
        cogoToast.success(
          `Chat request has been accepted`,  {
            position: 'top-center',
          }
        );
      }
    );

    connection.once(`messaging:chats:acceptChatRequest`, (data) => {
      console.log('error from chat request ', data);
      setLoading(false)
      cogoToast.error(
        `Chat request has previously been accepted`,  {
          position: 'top-center',
        }
      );
      // dispatch({ type: SET_ACTIVE_CHAT_REQUEST, payload: "" });
    });

    connection.on('messaging:chats:chats', (chats) => {
      if (chats.statusCode >= 400) {
         //console.log("on messaging:chats:chats error", chats);
      } else {
        // console.log("on messaging:chats:chats", chats, chats.data);
       
       
     
        
        
       
      }
    });


  };

  const sendMatchRequest = (payload) => {
    console.log('match payload ', payload);
    if (!isConnected()) return; // display toast;
    setIsMatchRequestLoading(true)
    connection.emit('messaging:chats:sendMatchRequest', {
      ...payload,
    });

    connection.once(
      `messaging:chats:sendMatchRequest:${payload.id}`,
      (response) => {
        console.log('response from match request ', response);
        // display success toast
        dispatch({ type: UPDATE_CHAT_REQUEST, payload: { ...response.data } });
        setTimeout(()=>{
          setIsMatchRequestLoading(false)
        },2000)
      }
    );

    connection.once(`messaging:chats:sendMatchRequest`, (data) => {
      // display error toast
      console.log('error from send match request ', data);
      // dispatch({ type: SET_ACTIVE_CHAT_REQUEST, payload: "" });
      setTimeout(()=>{
        setIsMatchRequestLoading(false)
      },2000)
    });
  };

  const respondToMatchRequest = (payload) => {
    console.log('match payload ', payload);
    if (!isConnected()) return; // display toast;
    setIsMatchRequestLoading(true)
    connection.emit('messaging:chats:respondMatchRequest', {
      ...payload,
    });

    connection.once(
      `messaging:chats:respondMatchRequest:${payload.id}`,
      (response) => {
        console.log('response from respond match request ', response);
        // display success toast
        dispatch({ type: UPDATE_CHAT_REQUEST, payload: { ...response.data } });
        setTimeout(()=>{
          setIsMatchRequestLoading(false)
        },2000)
      }
    );

    connection.once(`messaging:chats:respondMatchRequest`, (data) => {
      // display error toast
      console.log('error from match request ', data);
      // dispatch({ type: SET_ACTIVE_CHAT_REQUEST, payload: "" });
      setTimeout(()=>{
        setIsMatchRequestLoading(false)
      },2000)
    });
  };

  return (
    <MessagingContext.Provider
      value={{
        ...state,
        getChatMessages,
        sendMessage,
        openChatRequest,
        acceptChatRequest,
        sendMatchRequest,
        respondToMatchRequest,
        loading,
        isMatchRequestLoading
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
};
