import { useState, useEffect, useContext } from "react";
import { io } from "socket.io-client";
import { UserContext } from "../context/user.context";

export const CONNECTION_STATUS = {
  CONNECTING: "CONNECTING",
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
};

/**
 *
 * @param {string} connectionUrl
 * @returns [connection, connectionStatus]
 */
const useSocket = (connectionUrl) => {
  const [connection, setConnection] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(
    CONNECTION_STATUS.CONNECTING
  );
  const { data } = useContext(UserContext);

  useEffect(() => {
    if (!data || !data.accessToken) {
      if (connection) {
        connection.close();
        setConnection(null);
        setConnectionStatus(CONNECTION_STATUS.DISCONNECTED);
      }
      return;
    }

    if (connection || connectionStatus === CONNECTION_STATUS.CONNECTED) return;

    const token = data.accessToken;

    const socket = io(connectionUrl, {
      auth: { token },
    });

    socket.on("connect", () => {
      if (socket.connected) {
        setConnection(socket);
        setConnectionStatus(CONNECTION_STATUS.CONNECTED);
      } else {
        setConnection(null);
        setConnectionStatus(CONNECTION_STATUS.DISCONNECTED);
      }
    });

    return () => {
      // console.log("disconnect --- cleanup");
      if (connection) {
        connection.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, connectionStatus, data && data.accessToken]);

  return [connection, connectionStatus];
};

export default useSocket;
