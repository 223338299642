import { useContext} from "react";
import { capitalize, getAgeFromYear, profileIsParent, getFullName } from "../../utils";
import Image from "../shared/Image";
import { UserContext } from "../../context/user.context";
import {useHistory } from 'react-router-dom';
import { RecommendationItemLoader } from '../loaders/RecommendationsLoader';



const RecommendationItem =({ data, showRecommendee, viewProfile, removeRecommendation })=>{

    return (
      <div className="row mb-3 justify-content-between align-items-center recommended-profile">
        <div className="col-1">
          <Image
              alt="recommended-profile"
              className="round-image-48"
              src={data.recommendation.profileImage || "../../assets/images/dafault.png"}
              fallback="assets/images/default.png"
          />
        </div>
        <div className="col-6">
          <p onClick={(event)=>viewProfile(event, data)} className="profile-details">
            {getFullName(
              data.recommendation.firstName,
              data.recommendation.lastName
            )}
            {(data.recommendation.gender || data.recommendation.birthYear) && (
              <span>
                {capitalize(data.recommendation.gender)}{' '}
                {data.recommendation.gender && (
                  <i className='fas fa-dot-circle' />
                )}
                {data.recommendation.birthDate &&
                  `${getAgeFromYear(data.recommendation.birthDate)}y`}
              </span>
            )}
          </p>
        </div>
        <div className="col-4">
        {showRecommendee && data.sender && (
          <p title={getFullName(data.sender.firstName, data.sender.lastName)} className="recommendee text-center">
            {getFullName(data.sender.firstName, data.sender.lastName)}
          </p>
        )}
        </div>
        <div className="col-1 pt-1">
          <i onClick={()=>removeRecommendation(data.recordId)} class="far fa-trash-alt"></i>
        </div>
      </div>
    );
  }

const RecommendationsModal = ({ isChild, recommendations, removeRecommendation, loading}) => {
    const { data } = useContext(UserContext);
    const user = data && data.user;
    const history = useHistory();

    console.log(recommendations)

    const viewProfile = async (event, recommendation) => {
        const $ = window.$ || window.jQuery;
        $(function () {
          $('.modal').modal('hide');
        });
        event.stopPropagation();
        setTimeout(()=>{
          if(isChild){
            history.push(`/profile/${recommendation.recommendation.userId}`);
          } else{
            history.push(`/profile/${recommendation.recommendation.userId}/${recommendation.sender.userId}`);
          }
        }, 1000)
      };

  return (
    <div
      className="modal fade"
      id="recommendations"
      tabIndex={-1}
      aria-labelledby="reccommendationsLabelby"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              All Recommendations
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="row">
              {
                loading && (
                  <>
                    <RecommendationItemLoader />
                    <RecommendationItemLoader />
                    <RecommendationItemLoader />
                    <RecommendationItemLoader />
                    <RecommendationItemLoader />
                    <RecommendationItemLoader />
                  </>
                )
              }
              {recommendations.map((recommendation)=>(
                  <RecommendationItem
                  viewProfile={viewProfile}
                  data={recommendation}
                  showRecommendee={profileIsParent(user.userType)}
                  removeRecommendation={removeRecommendation}
                  />
              ))}
            </div>
          </div>
          <div className="modal-footer action-buttons">
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendationsModal;
