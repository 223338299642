import Loader, { CircularLoader } from "./loader";

export const RecommendationItemLoader = () => {
  return (
    <div className="row justify-content-around recommended-profile">
      <div className="col-12">
        <p className="profile-details">
          <Loader height="40" width="100%" />
          <span>
            <Loader height="20" width="70%"/>
          </span>
        </p>
      </div>
    </div>
  );
};

// export const RecommendationsLoader = () => (
//   <>
//     <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
//       <div className="card card-light card-min-height-395">
//         <div className="card-body">
//           <div className="row justify-content-between card-title-container mb-4">
//             <div className="col-6">
//               <h5 className="card-title with-bg float-start">
//                 Recommendations
//               </h5>
//             </div>
//             <div className="col-2">
//               <span className="float-end">
//                 <a href="##">All</a>
//               </span>
//             </div>
//           </div>
//           <RecommendationItemLoader />
//           <RecommendationItemLoader />
//           <RecommendationItemLoader />
//           <RecommendationItemLoader />
//         </div>
//       </div>
//     </div>
//   </>
// );
