import { useContext, useEffect, useState, memo } from "react";
import Image from "../../components/shared/Image";
import { UserContext } from "../../context/user.context";
// import { GetChats } from "../../requests/user";
import { MessagingContext } from "../../context/messaging.context";
import useChat from "../../hooks/useChat";
import ChatsLoader from "../../components/loaders/ChatsLoader"
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import { GetChats } from '../../requests/user';

function NoChats() {
  return (
    <div className="empty-card text-center">
      <div className="icon-container mx-auto mt-4">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
            fill="#E80F6D"
          />
        </svg>
      </div>
      <p className="card-text">Your chats will appear here.</p>
    </div>
  );
}

function SearchIcon() {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
        stroke="#6E7191"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 17.5L13.875 13.875"
        stroke="#6E7191"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SearchBox({ onInputChange }) {
  return (
    <div className="chat-list-container position-static">
      <div className="user-search-input">
        <i className="icon-search">
          <SearchIcon></SearchIcon>
        </i>
        <input
          type="text"
          className="form-control"
          placeholder="search for someone"
          onChange={(e) => onInputChange(e.target.value)}
        />
      </div>
    </div>
  );
}


function timeAgo(given_date) {

  const givenDate = new Date(given_date);
  const today = new Date();

  const msBetweenDates = Math.abs(givenDate.getTime() - today.getTime());

  // 👇️ convert ms to hours                  min  sec   ms
  const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

  

  if(given_date === '') {
    return 'New';
  }
  else if (hoursBetweenDates < 48 && hoursBetweenDates >= 24 ) {
    return 'Yesterday';
    // return moment(given_date).from(today);

  } 
  else if(hoursBetweenDates > 48) {
    return moment(given_date).format("MMM DD, YYYY");
  }
  else {
    return moment(given_date).format('hh:mm A')
  }
    
}

const ChatItem = memo(({ messages, chat, isActive, onSelect, currentChatRequest, userType, showChatsHandler }) => {
  // if(chat.aut)
  const { data } = useContext(UserContext);

  const [author, participant] = useChat(data && data.user.id, chat, currentChatRequest);

  console.log(userType)

  const lastMessage = messages.length > 0 ? messages[messages.length - 1].body : ''
  const lastMessageDate = messages.length > 0 ? messages[messages.length - 1].dateLastUpdated : ''
  
  const selectChat =()=>{
    onSelect(chat)
    showChatsHandler()
  }


  if (!data || !data.user) return null;
  return (
    <div className={`user-list-container ${isActive && "active"}`}>
      <div
        className="row justify-content-between user-single-list"
        onClick={(e) => selectChat()}
      >
        <div className="col-9">
          <div className="float-start pt-1">
            <Image
              src={participant.profileImage}
              fallback="/assets/images/woman-1.jpeg"
              alt="user avatar"
              className={`round-image-48 ${isActive && "active"}`}
            />
          </div>
          <p className="">
            {participant.name}
            <span>
              {lastMessage ? lastMessage : "Impress your match with a good line"}
            </span>
          </p>
        </div>
        <div className="col-3 text-center">
          <div className="status-time-ref">
            {/* <span className="chat-count-inner mx-auto">9+</span> */}
            {timeAgo(lastMessageDate)}
          </div>
        </div>
        {userType==="Parent" && author.child && (
          <div className="col mt-3 d-flex justify-content-between align-items-center">
            <span className="recommended-by pt-1">
              This Chat is on behalf of <strong>{author.child.firstName}</strong>
            </span>
            <Image
              src={author.child.profileImage}
              fallback="/assets/images/default.png"
              alt="user avatar"
              className="round-image-32 float-end me-3"
            />
          </div>
        )}
      </div>
    </div>
  );
});

const ChatRequestItem = memo(({ data, chat, isActive, onSelect, showChatsHandler }) => {
  let participant;

  if(chat.sender.userId === data.user.id){
    participant = chat.receiver
  } else{
    participant = chat.sender
  }

  const sentFromMe = chat.sender.firstName === data.user.firstName && chat.sender.lastName === data.user.lastName

  const selectChat =()=>{
    onSelect(chat)
    showChatsHandler()
  }

  return (
    <div className={`user-list-container ${isActive && "active"}`}>
      <div
        className="row justify-content-between user-single-list"
        onClick={(e) => selectChat()}
      >
        <div className="col-9">
          <div className="float-start pt-1">
            <Image
              src={participant.profileImage}
              fallback="/assets/images/default.png"
              alt="user avatar"
              className={`round-image-48 ${isActive && "active"}`}
            />
          </div>
          <p className="">
            {participant.firstName} {participant.lastName}
             <span>
               { 
                  sentFromMe ? 
                    `You have sent a chat request to ${participant.firstName}`
                  :
                  `Auntie ${participant.firstName} has requested to connect with your child ${chat.receiverChild.firstName} on behalf of their child ${chat.senderChild.firstName}`
                }
              
            </span> 
          </p>
        </div>
        <div className="col-3 text-center">
          <div className="status-time-ref">
          {moment(chat.dateRequested).format("MMM DD, YYYY")}
          </div>
        </div>
        {chat.receiverChild && (
          <div className="col mt-3">
            <span className="recommended-by pt-1"> This request is on behalf of <strong>{sentFromMe ? `${chat.senderChild.firstName}` : `${chat.receiverChild.firstName}` }</strong></span>
            <img
              alt={sentFromMe ? chat.senderChild.firstName : chat.receiverChild.firstName }
              src={sentFromMe ? chat.senderChild.profileImage : chat.receiverChild.profileImage  }
              className="round-image-32 float-end me-3"
            />
          </div>
        )}
      </div>
    </div>
  );
});

const Chats = ({showChatsHandler}) => {
  const [isLoading, setIsLoading] = useState(true)
  const {
    chats,
    chatRequests,
    messagesStore,
    activeChat,
    activeChatRequest,
    userId,
    getChatMessages,
    openChatRequest,
    loading
  } = useContext(MessagingContext);
  const {data, logout } = useContext(UserContext);
  let userType;
  if(data.user){
    userType = data.user.userType
  }
  const history = useHistory();
  const user = data && data.user;

  const checkAuthorizedUser = (error) => {
    if (error?.message === 'Request failed with status code 401') {
      logout(history);
    }
  };

  //we  are already getting our chats data from the socket in messaging context but here we are
  // trying to check expired session for the entire chat componenet
  useEffect(() => {
    if(data.user){
      GetChats(user.id)
      .then((data) => {
        console.log('data loaded')
        //console.log(data.data);
      })
      .catch((err) => {
        checkAuthorizedUser(err);
      });
    }
  }, [data.user]);


  
  //console.log("From Chat.js contex from be-------" + JSON.stringify(chats), JSON.stringify(chatRequests))
  const [textSearch, setTextSearch] = useState("");
  console.log(chats, chatRequests)

  const displayedChatRequests = chatRequests.filter(cr=>cr.status !== "Accepted")
  //const chat = chats.length > 0 && chats.find((obj) => obj.chatId === activeChat);
  //const currentChatRequest = chat && chatRequests.filter(cr=>cr.recordId === chat.chatRequestId)

  const renderChatList = (list) => {
    // apply filters the return updated list
    if (!textSearch) return list;
    const filtered = list.filter((chat) => {
      return (
        (chat.author && chat.author[1].toLowerCase().includes(textSearch.trim())) ||
        (chat.participant && chat.participant[1].toLowerCase().includes(textSearch.trim())) ||
        (chat.sender && chat.sender.lastName.toLowerCase().includes(textSearch.trim())) || 
        (chat.sender && chat.sender.firstName.toLowerCase().includes(textSearch.trim()))
      );
    });
    return filtered;
  };

  const openChat = (chat) => {
    if (activeChat && activeChat === chat.chatId) return;
    getChatMessages(chat.chatId);
  };

  const onChatRequestClick = (chat) => {
    if (activeChatRequest && activeChatRequest === chat.recordId) return;
    openChatRequest(chat.recordId);
  };

  const isEmpty = () => {
    if(userType==="Parent"){
      return chats.length === 0 && displayedChatRequests.length === 0;
    }
    return chats.length === 0
  };

  // const pendingRequests = chatRequests.filter(
  //   (req) => req.status === 'Pending' && req.receiver.userId === userId
  // );

  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)
    }, 2000)
  })

  
  const sortChatList=(a,b)=>{
    if(a.dateLastUpdated && b.dateLastUpdated){
      const dateA = new Date(a.dateLastUpdated);
      const dateB = new Date(b.dateLastUpdated);
      return dateA < dateB ? 1 : -1;
    }  
      return  
  }; 

  const sortedChatList = chats.sort(sortChatList)

  const sortChatRequestLst = (a, b)=>{
      const dateA = new Date(a.dateRequested);
      const dateB = new Date(b.dateRequested);
      return dateA < dateB ? 1 : -1
  }

  const sortedChatRequestList = displayedChatRequests.sort(sortChatRequestLst)
  
  return (
    <div className="">
      <div className="card card-light card-min-height-235">
        <div className="card-body">
            <p className="title">Chat</p>
            {isEmpty() && !isLoading && loading===false && <NoChats></NoChats>}

            {/* search component */}
            {!isEmpty() && !isLoading && (
              <SearchBox
                onInputChange={(val) => setTextSearch(val)}
              ></SearchBox>
            )}
          {/* search component end */}

          {/* chat request list */}

          <div className="chat-list-scroll overflow-auto">
            {
              (isLoading || (loading && (chats.length === 0 || chatRequests.length === 0)))  && [1, 2, 3, 4, 5].map((item)=>(
                <ChatsLoader />
              ))
            }


            {!isEmpty() && !isLoading && userType === 'Parent' &&
              renderChatList(sortedChatRequestList).map((chat) => (
                <ChatRequestItem
                  chat={chat}
                  key={chat.recordId}
                  userId={userId}
                  onSelect={onChatRequestClick}
                  isActive={
                    activeChatRequest
                      ? activeChatRequest === chat.recordId
                      : false
                  }
                  data={data}
                  showChatsHandler={showChatsHandler}
                ></ChatRequestItem>
                ))}
            {/* chat request list end */}

            {/* chat list */}
            {!isEmpty() && !isLoading &&
              renderChatList(sortedChatList).map((chat) => {
                const currentChatRequest = chat && chatRequests.length>0 && chatRequests.filter(cr=>cr.recordId === chat.chatRequestId)
                return <ChatItem
                  messages={messagesStore[chat.chatId].messages} 
                  chat={chat}
                  key={chat.chatId}
                  userId={userId}
                  onSelect={openChat}
                  isActive={activeChat ? activeChat === chat.chatId : false}
                  currentChatRequest={currentChatRequest}
                  userType={userType}
                  showChatsHandler={showChatsHandler}
                ></ChatItem>
              }
              )}
          </div>
          {/* chat list end */}
        </div>
      </div>
    </div>
  );
};

export default Chats;
