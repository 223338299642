import { NavLink, useParams, useHistory } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { resetPassword } from '../../requests/user';
import cogoToast from 'cogo-toast';
import SpiralLoader from '../../components/loaders/SpiralLoader';
import { UserContext } from '../../context/user.context';

export default function ResetPassword() {
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const history = useHistory();

	const data = {};
	const { userId, token } = useParams();

	data.userId = userId;
	data.passwordResetToken = token;

	const { data: userData } = useContext(UserContext);

	useEffect(() => {
		if (userData && userData.user) {
			history.push('/dashboard');
		}
	}, [userData, history]);

	useEffect(() => {
		password && confirmPassword ? setDisabled(false) : setDisabled(true);
	}, [password, confirmPassword]);

	const submitHandler = (event) => {
		event.preventDefault();
		if (password !== confirmPassword) {
			cogoToast.error('Passwords do not match', {
				position: 'top-center',
			});
			return;
		} else if (password.length < 8 || confirmPassword.length < 8) {
			cogoToast.error('Password must be at least 8 characters', {
				position: 'top-center',
			});
			return;
		}
		data.confirmPassword = confirmPassword;
		data.password = password;
		setLoading(true);
		resetPassword(data)
			.then((res) => {
				if (res.statusCode === 201) {
					setLoading(false);
					cogoToast.success(res.message, { position: 'top-center' });
					setPassword('');
					setConfirmPassword('');
					history.push('/login');
				} else {
					setLoading(false);
					cogoToast.error(res.message, { position: 'top-center' });
				}
			})
			.catch((err) => {
				setLoading(false);
				cogoToast.error(
					'Unable to process your request at the moment',
					{ position: 'top-center' }
				);
			});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12">
					<nav className="navbar navbar-expand-xxl navbar-light">
						<a className="navbar-brand" href="##">
							<div className="logo-container">
								<NavLink to="/login">
									<img
										src="/assets/images/auntie-network-logo.png"
										alt="description_icon"
										width="45px"
										height="auto"
									/>
								</NavLink>
							</div>
						</a>
					</nav>
					<div className="formdiscription">
						<div className="marginTop"></div>
						<h2>Reset Password</h2>
						<p>Enter your new passoword</p>
					</div>
					<form onSubmit={submitHandler} className="form">
						<input
							name="password"
							type="password"
							placeholder="New password"
							onChange={({ target: { value } }) =>
								setPassword(value)
							}
							value={password}
							title="Your password must be at least 8 characters"
						/>
						<input
							name="password"
							type="password"
							placeholder="Confirm new password"
							onChange={({ target: { value } }) =>
								setConfirmPassword(value)
							}
							value={confirmPassword}
							title="Your password must be at least 8 characters"
						/>
						<button
							type="submit"
							className="btn d-flex justify-content-center align-items-center solid-btn"
							disabled={loading || disabled}
						>
							{loading ? <SpiralLoader /> : 'Submit'}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}
