export const BASE_URL = process.env.REACT_APP_API_URL; // "https://api-st.theauntienetwork.com/api";

export const VALIDATE_INVITATION = `${BASE_URL}/users/validate-invitation`;

export const VALIDATE_PARTIAL_REGISTRATION = `${BASE_URL}/onboarding/ValidatePartialRegistration`;

export const UPLOAD_PARTIAL_REGISTRATION_IMAGE = `${BASE_URL}/profile-images/onboarding`;

export const COMPLETE_REGISTRATION = `${BASE_URL}/onboarding/CompletePartialRegistration`;

export const LOGIN = `${BASE_URL}/auth/login`;

export const REGISTER = `${BASE_URL}/auth/signup`;

export const VALIDATE = `${BASE_URL}/verification/validate-otp`;

export const INVITE_CHILD = `${BASE_URL}/users/invite`;

export const RESEND_INVITE = `${BASE_URL}/users/resend-invite`;

export const GET_USER_INVITATIONS = (userId) =>
	`${BASE_URL}/users/invitations?userId=${userId}`;

export const DELETE_INVITATION = `${BASE_URL}/users/remove-invitation`;

export const SEARCH_NETWORK = `${BASE_URL}/users/network`;

export const GET_USER = (id) => `${BASE_URL}/users/read/${id}`;

export const GET_NETWORK_PROFILE = (id) => `${BASE_URL}/users/network/${id}`;

export const GET_PROFILE_IMAGES = (id) =>
	`${BASE_URL}/profile-images?userId=${id}`;

export const UPLOAD_PROFILE_IMAGE = () =>
	`${BASE_URL}/profile-images/main-image`;

export const UPLOAD_PROFILE_IMAGES = () =>
	`${BASE_URL}/profile-images/multiple`;

export const UPDATE_USER = `${BASE_URL}/users`;

export const LOCATIONS = `${BASE_URL}/locations/states`;

export const ZIPCODES = (value) =>
	`${BASE_URL}/locations/zipcodes?state=${value}`;

export const GET_USER_NOTIFICATIONS = (ownerUserId) =>
	`${BASE_URL}/notifications?ownerUserId=${ownerUserId}`;

export const UPDATE_USER_NOTIFICATIONS = `${BASE_URL}/notifications/update`;

export const DELETE_USER_NOTIFICATIONS = `${BASE_URL}/notifications/delete`;

export const ADD_FAVORITE = `${BASE_URL}/favourites`;

export const GET_USER_FAVORITES = (id) =>
	`${BASE_URL}/favourites?initiatorUserId=${id}`;

export const REMOVE_FAVORITE = `${BASE_URL}/favourites/remove`;

export const GET_RECEIVED_RECOMMENDATIONS = (userId) =>
	`${BASE_URL}/user-recommendations/received/${userId}`;

export const USER_RECOMMENDATION = `${BASE_URL}/user-recommendations`;

export const GET_MY_RECOMMENDATIONS = (userId) =>
	`${BASE_URL}/user-recommendations/sent/${userId}`;

export const DELETE_RECOMMENDATION = (recommendationId) =>
	`${BASE_URL}/user-recommendations/${recommendationId}`;

export const CHAT_REQUEST = `${BASE_URL}/chats/request`;

export const CHATS = `${BASE_URL}/chats`;

export const GET_CHATS = (userId) => `${CHATS}/?userId=${userId}`;

export const HAS_CHAT_REQUEST = `${BASE_URL}/chats/request/hasChatRequest`;

export const GET_CHILDREN = (parentId) =>
	`${BASE_URL}/users/children/${parentId}`;

export const DELETE_IMAGES = `${BASE_URL}/profile-images/delete`;

// SOCKET ENDPOINTS
export const SOCKET_URL = process.env.REACT_APP_SOCKET_API_URL;
// process.env.REACT_APP_SOCKET_API_URL; https://socket-st.theauntienetwork.com

export const MESSAGING_URL = `${SOCKET_URL}/messaging`;

export const FORGOTPASSWORD = `${BASE_URL}/auth/forgot-password`;

export const RESETPASSWORD = `${BASE_URL}/auth/reset-password`;

export const GOOGLEAUTH = `${BASE_URL}/auth/google/callback`;

export const POSTUSERPREFERENCES = `${BASE_URL}/preferences`;

export const GET_MATCHES = (userId) => `${BASE_URL}/matches?userId=${userId}`;

export const CHANGEPASSWORD = `${BASE_URL}/auth/change-password`;

export const PLANS = `${BASE_URL}/plans`

export const CREATECUSTOMER = `${BASE_URL}/plans/create-customer`

export const CREATESUBSCRIPTION = `${BASE_URL}/plans/create-subscription`
