import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../context/user.context";
import { Validate } from "../../requests/auth";
import useLocalStorage from '../../hooks/useLocalStorage'

// styles
// import "../../assets/scss/main.scss";

export const Verify = () => {
  const { state } = useLocation();
  const {removeItem} = useLocalStorage()
  const { setData, data, FetchUser } = useContext(UserContext);
  const history = useHistory();
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [loading, setLoading] = useState(false)
  const verify = (e) => {
    setLoading(true)
    e.preventDefault();
    let otp = `${input1}${input2}${input3}${input4}`;
    Validate({
      code: otp,
      userId: state?.userId,
    }).then((res) => {
      setTimeout(()=>{
        setData({ ...data, ...res.data });
        // FetchUser();
        removeItem("details")
        history.push("/dashboard");
      })
    });
  };
  useEffect(() => {
    const $ = window.$;
    function disallowedKeycodes() {
      return [
        8,
        9,
        13,
        16,
        17,
        18,
        19,
        20,
        27,
        33,
        32,
        35,
        36,
        37,
        38,
        39,
        40,
        44,
        45,
        46,
      ];
    }

    $(".form-wrapper .v-fields > div:nth-child(1) input").keyup(function(e) {
      if (disallowedKeycodes().indexOf(e.keyCode) == -1) {
        $(".form-wrapper .v-fields > div:nth-child(2) input").focus();
      }
    });
    $(".form-wrapper .v-fields > div:nth-child(2) input").keyup(function(e) {
      if (disallowedKeycodes().indexOf(e.keyCode) == -1) {
        $(".form-wrapper .v-fields > div:nth-child(3) input").focus();
      }
    });
    $(".form-wrapper .v-fields > div:nth-child(3) input").keyup(function(e) {
      if (disallowedKeycodes().indexOf(e.keyCode) == -1) {
        $(".form-wrapper .v-fields > div:nth-child(4) input").focus();
      }
    });
    $(".form-wrapper .v-fields > div:nth-child(4) input").keyup(function(e) {
      if (
        $(".form-wrapper .v-fields > div:nth-child(1) input").val().length ==
          1 &&
        $(".form-wrapper .v-fields > div:nth-child(2) input").val().length ==
          1 &&
        $(".form-wrapper .v-fields > div:nth-child(3) input").val().length ==
          1 &&
        $(".form-wrapper .v-fields > div:nth-child(4) input").val().length ==
          1 &&
        disallowedKeycodes().indexOf(e.keyCode) == -1
      ) {
        $(".v-buttons").removeAttr("disabled");
      }
    });
  }, []);
  if (!state?.userId) {
    history.push("/register");
    return null;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          {/* nav bar */}
          <nav className="navbar navbar-expand-xxl navbar-light">
            <a className="navbar-brand" href="##">
              <div className="logo-container">
                <img
                  src="assets/images/auntie-network-logo.png"
                  alt="description_icon"
                  width="45px"
                  height="auto"
                />
              </div>
            </a>
          </nav>
          <div className="onboarding">
            <form onSubmit={verify} action>
              <section className="col-md-9 col-lg-5 col-xl-4 mx-auto">
                <div className="heading-group text-center">
                  <h1>Verify your Email</h1>
                  <p className="mt-4">
                    Please enter the 4-digit code sent to your mail{" "}
                    <u>{state?.email}</u> <a href="/register">Change</a>
                  </p>
                </div>
                <div className="form-wrapper aunty-verify">
                  <div className="row mb-4 v-fields">
                    <div className="col">
                      <input
                        maxLength={1}
                        onChange={({ target: { value } }) => setInput1(value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <input
                        onChange={({ target: { value } }) => setInput2(value)}
                        maxLength={1}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <input
                        maxLength={1}
                        onChange={({ target: { value } }) => setInput3(value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <input
                        onChange={({ target: { value } }) => setInput4(value)}
                        maxLength={1}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="action-buttons">
                    <div className="row">
                      <button
                        className="v-buttons btn btn-primary solid-btn w-100 mb-4"
                        disabled
                      >
                        {loading?"Verifying...":"Verify"}
                      </button>
                      <button className="btn btn-primary transparent-btn w-100">
                        Didn’t get a code? Resend
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
