import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UserContext } from '../../context/user.context';
import { useContext } from 'react';
import { createSubscription, createCustomer } from '../../requests/user';

import CheckoutForm from './checkoutForm';
import axios from 'axios';
import { CREATECUSTOMER, CREATESUBSCRIPTION } from '../../requests/endpoints';
//import "./payment.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function App() {
	const [clientSecret, setClientSecret] = useState('');
	const { plan } = useContext(UserContext);

	const location = useLocation();
	console.log(location);

	// history
	const navigate = useHistory();

	useEffect(() => {
		console.log('payment');
		axios
			.post(CREATECUSTOMER, location.state)
			.then((response) => {
				console.log(response);
				axios
					.post(CREATESUBSCRIPTION, {
						planId: location.state.planId,
						userId: location.state.userId,
						customerId: response.data.data.customerId,
					})
					.then((response) => {
						console.log(response);
						// console.log(response.data.data.clientSecret.id);
						console.log(
							response.data.data.clientSecret.payment_intent
								.client_secret
						);
						setClientSecret(
							response.data.data.clientSecret.payment_intent
								.client_secret
						);
					})
					.catch((error) => {
						if (error.response.status === 400) {
							navigate('/subscriptions');
						}
						console.log(error.response);
					});
			})
			.catch((error) => {
				console.log(error);
			});
		// createCustomer(plan)
		//   .then(({ data }) => {
		//     console.log(data);
		//     createSubscription({ ...plan, customerId: data.customer.id })
		//       .then((response) => {
		//         console.log(response);
		//       })
		//       .catch((err) => {
		//         console.log(err);
		//       });
		//   })
		//   .catch((err) => {
		//     console.log(err);
		//   });
	}, []);

	const appearance = {
		theme: 'stripe',
	};
	const options = {
		clientSecret,
		appearance,
	};

	return (
		<div className="App">
			{clientSecret && (
				<Elements options={options} stripe={stripePromise}>
					<CheckoutForm clientSecret={clientSecret} />
				</Elements>
			)}
		</div>
	);
}
