import React, { useContext, useState, useEffect } from "react";
import { VALIDATE_STEP } from ".";
import { OnboardingContext } from "../../context";
import { getLocations } from "../../requests/onboarding";
//import {states} from "../../utils/data"



const Location = ({ nextStep, previousStep, currentStep, updateSteps }) => {
    const { setData, data, state, locationHandler, postalCodeHandler } = useContext(OnboardingContext);
    const [location, setLocation] = useState(data.location?data.location:null);
    const [states, setStates]= useState([])
    const toNextStep = () => { 
        locationHandler()
        //postalCodeHandler()
        let _data = { ...data, location:location};
        if (VALIDATE_STEP(_data, currentStep - 1)) {
            setData({..._data, step:5});
            nextStep()
        }
    }

    
    useEffect(()=>{
        if(state){
            getLocations().then(data=>{
                setStates(data.data)
            })
        }
    },[state])
    
    
    return (
        <>
            <section className="col-md-9 col-lg-5 col-xl-4 mx-auto">
                <div className="heading-group ">
                    <span className="animate__animated animate__slideInDown animate__delay-3s step-4" />
                    <h1>Which state do you live in?</h1>
                </div>
                <div className="input-container">
                    <div className="form-group">
                        <select  onChange={({ target: { value } }) => setLocation(value)} className="form-control" name="location">
                            <option value={location}>{location ? location : "Location"}</option>
                            {states.map(state=>(
                                <option value={state}>{state}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="action-buttons">
                    <div className="row">
                        <div className="col">
                            <button type="button" className="btn fa-previous" onClick={previousStep}></button>
                            <button type="button" className="btn fa-next float-end" onClick={toNextStep}></button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Location;