import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { OnboardingContext } from '../../context';
import { AcceptInvitation } from '../../requests/onboarding';
import { UserContext } from '../../context/user.context';
import useLocalStorage from '../../hooks/useLocalStorage';

const Start = () => {
  const { slug, action } = useParams();
  const [parentName, setName] = useState('');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [invalidLink, setLinkInvalid] = useState(false);
  const { data, setData } = useContext(OnboardingContext);
  const {data:userData} = useContext(UserContext)
  const {clearStorage} = useLocalStorage()


  

  
  useEffect(() => {
    if(userData && userData.user){
        clearStorage()
    }
    if(action==="accept"){
        setLoading(true);
        AcceptInvitation({ slug, action: 'Accept' })
        .then(({ data: { parentId, parentName, email } }) => {
          // change later to parent name
          setName(parentName);
          setData({ ...data, parentId, email });
          setLoading(false);
        })
        .catch((err) => {
          setLinkInvalid(true);
          setLoading(false);
        });
      }
  }, [])


  const nextStep = () => history.push('/onboarding');
  const toHome = () => (window.location = 'https://www.theauntienetwork.com/');
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            {/* nav bar */}
            <nav className='navbar navbar-expand-xxl navbar-light'>
              <a className='navbar-brand' href='##'>
                <div className='logo-container'>
                  <img
                    src='/assets/images/auntie-network-logo.png'
                    alt='description_icon'
                    width='45px'
                    height='auto'
                  />
                </div>
              </a>
            </nav>
            <div className='onboarding'>
              <form>
                <div className='col-md-9 col-lg-5 col-xl-4 mx-auto text-container text-center'>
                  {loading ? (
                    <div> Loading ...</div>
                  ) : (
                    <>
                      {
                        action === 'reject' && (
                          <>
                          <img
                            src='/assets/images/namaste.png'
                            className='checkmark animate__animated animate__shakeY'
                            alt='check'
                            width='100px'
                            height='auto'
                            fallback='/assets/images/close.png'
                          />
                          <p className='small'>
                            You have rejected an invitation to join The Auntie Network. Click the
                            button below to continue.
                          </p>
                          <div className='action-buttons'>
                            <button
                              type='button'
                              onClick={toHome}
                              className='btn solid-btn'
                            >
                              Back To Home
                            </button>
                        </div>
                        </>
                        )
                      }
                      {
                        action === 'accept' && (
                          <>
                          <img
                            src='/assets/images/namaste.png'
                            className='checkmark animate__animated animate__shakeY'
                            alt='check'
                            width='100px'
                            height='auto'
                          />
                          {invalidLink ? (
                            <p className='small'>
                              {' '}
                              <span className='text-primary'>Error</span> : Your
                              Invite Link is either{' '}
                              <span className='text-primary'>Invalid</span> or has{' '}
                              <span className='text-primary'>Expired </span> <br />{' '}
                              Request a new Invite from your Parent to continue your
                              Onboarding process
                            </p>
                          ) : (
                            <p className='small'>
                              You have accepted <strong>{parentName}</strong>{' '}
                              invitation to join The Auntie Network. Click the
                              button below to continue.
                            </p>
                          )}
                          <p className='small' />
                          {invalidLink ? (
                            <div className='action-buttons'>
                              <button
                                type='button'
                                onClick={toHome}
                                className='btn solid-btn'
                              >
                                Back To Home
                              </button>
                            </div>
                          ) : (
                            <div className='action-buttons'>
                              <button className='btn solid-btn' onClick={nextStep}>
                                Get started
                              </button>
                            </div>
                          )}
                        </>
                        )
                      }
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Start;
