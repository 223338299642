import { CloseIcon } from '../Svgs';
import { useState, useEffect, useContext } from 'react';
import cogoToast from 'cogo-toast';
import SpiralLoader from '../../components/loaders/SpiralLoader';
import { changePassword } from '../../requests/user';
import { UserContext } from '../../context/user.context';

const ResetPasswordModal = ({ isActive, handleClose }) => {
	const [data, setData] = useState({
		currentPassword: '',
		confirmPassword: '',
		password: '',
	});
	const [isLoading, setIsLoading] = useState(false);
	const [disabled, setDisabled] = useState(true);

	const { data: userData } = useContext(UserContext);

	const user = userData && userData.user;

	const onChangeHandler = ({ target }) => {
		setData((prev) => {
			return { ...prev, [target.name]: target.value };
		});
	};

	useEffect(() => {
		data.currentPassword && data.password && data.confirmPassword
			? setDisabled(false)
			: setDisabled(true);
	}, [data]);

	const submitHandler = () => {
		data.userId = user.id;
		if (data.password !== data.confirmPassword) {
			cogoToast.error('Passwords do not match', {
				position: 'top-center',
			});
			return;
		} else if (
			data.password.length < 8 ||
			data.confirmPassword.length < 8
		) {
			cogoToast.error('Password must be at least 8 characters', {
				position: 'top-center',
			});
			return;
		}
		console.log(data);
		setIsLoading(true);
		changePassword(data)
			.then((res) => {
				if (res.statusCode === 201) {
					setIsLoading(false);
					cogoToast.success(res.message, { position: 'top-center' });
					setData({
						currentPassword: '',
						confirmPassword: '',
						password: '',
					});
				} else {
					setIsLoading(false);
					cogoToast.error(res.message, { position: 'top-center' });
					setData({
						currentPassword: '',
						confirmPassword: '',
						password: '',
					});
				}
			})
			.catch((err) => {
				setIsLoading(false);
				cogoToast.error(
					'Unable to process your request at the moment',
					{ position: 'top-center' }
				);
				setData({
					currentPassword: '',
					confirmPassword: '',
					password: '',
				});
			});
	};

	return (
		<div
			className="modal edit-profile-pref"
			id="resetPassword"
			tabIndex={-1}
			aria-labelledby="resetPasswordLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title d-flex gap-4 align-items-center"
							id="editProfilePreferencesLabel"
						>
							<svg
								width={8}
								height={14}
								viewBox="0 0 8 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								data-bs-target="#editProfileForm"
								data-bs-toggle="modal"
								data-bs-dismiss="modal"
							>
								<path
									d="M7 13L1 7L7 1"
									stroke="#6E7191"
									strokeWidth={2}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<span>Reset Password</span>
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						/>
					</div>

					<div className="modal-body py-0  ">
						<form className="flex">
							<div className="container-fluid d-flex flex-column gap-5 p-0">
								<div className="">
									<div className="">
										<label
											htmlFor
											className="form-label px-2 mt-2 mb-1"
										>
											Current Password{' '}
											<span style={{ color: 'red' }}>
												*
											</span>
										</label>
										<input
											name="currentPassword"
											type="password"
											className="form-control d-block w-50 mt-0 py-3"
											placeholder="Password"
											onChange={onChangeHandler}
											value={data.currentPassword}
											title="Your password must be at least 8 characters"
										/>
									</div>
									<div className="">
										<label
											htmlFor
											className="form-label  px-2 mt-4 mb-1"
										>
											New Password{' '}
											<span style={{ color: 'red' }}>
												*
											</span>
										</label>
										<input
											name="password"
											type="password"
											className="form-control d-block w-50 mt-0 py-3"
											placeholder="Password"
											onChange={onChangeHandler}
											value={data.password}
											title="Your password must be at least 8 characters"
										/>
									</div>
									<div className="mb-4">
										<label
											htmlFor
											className="form-label px-2 mt-4 mb-1"
										>
											Confirm New Password{' '}
											<span style={{ color: 'red' }}>
												*
											</span>
										</label>
										<input
											name="confirmPassword"
											type="password"
											className="form-control d-block w-50 mt-0 py-3"
											placeholder="Password"
											onChange={onChangeHandler}
											value={data.confirmPassword}
											title="Your password must be at least 8 characters"
										/>
									</div>
								</div>
							</div>
						</form>
					</div>

					<div className="modal-footer action-buttons">
						<button
							className="btn transparent-gray w-25 d-flex justify-content-center align-items-center"
							data-bs-dismiss="modal"
						>
							Cancel
						</button>
						<button
							className="btn solid-btn w-25 d-flex justify-content-center align-items-center"
							onClick={submitHandler}
							disabled={isLoading || disabled}
						>
							{isLoading ? <SpiralLoader /> : 'Submit'}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPasswordModal;
