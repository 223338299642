import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { VALIDATE_STEP } from '.';
import { OnboardingContext } from '../../context';
import { UserContext } from '../../context/user.context';
import useAuth from '../../hooks/useAuth';
import {
	CompleteRegistration,
	SyncRegistration,
} from '../../requests/onboarding';

const Password = ({ nextStep, previousStep, currentStep }) => {
	const { data, setData, clearData, registrationCompletedHandler } =
		useContext(OnboardingContext);
	const { setData: setUser } = useContext(UserContext);
	const [password, setPassword] = useState('');
	const { login } = useAuth();

	const toNextStep = () => {
		registrationCompletedHandler();
		let _data = { ...data, password };
		if (VALIDATE_STEP(_data, currentStep - 1)) {
			let syncData = { ..._data };
			delete syncData.avatar_preview;
			SyncRegistration(syncData).then(({ data: res }) => {
				setData({ ..._data, onboardingComplete: true });
				CompleteRegistration({ id: res.id }).then((res) => {
					clearData();
					nextStep();
					login(res.data);
					setUser(res.data);
				});
			});
		}
	};

	return (
		<div>
			<section className="col-md-9 col-lg-5 col-xl-4 mx-auto">
				<div className="heading-group">
					<span className="animate__animated animate__slideInDown animate__delay-3s step-9" />
					<h1>Almost done, Set your password</h1>
				</div>
				<div className="row input-container">
					<div className="form-group mb-4">
						<input
							onChange={({ target: { value } }) =>
								setPassword(value)
							}
							type="password"
							className="form-control"
							placeholder="password"
						/>
					</div>
					<p className="terms-privacy">
						By tapping on the button below, you agree to our
						<NavLink
							to={{
								pathname:
									'https://www.theauntienetwork.com/privacy/',
							}}
							target="_blank"
							activeClassName="active"
						>
							Terms and Conditions.
						</NavLink>
						Learn about our Privacy Policy
					</p>
				</div>
				<div className="action-buttons">
					<div className="row">
						<div className="col">
							<button
								type="button"
								className="btn fa-previous"
								onClick={previousStep}
							>
								{' '}
							</button>
							<button
								type="button"
								className="btn fa-next float-end"
								onClick={toNextStep}
							></button>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Password;
