import React from 'react';
// import Custom loader library
import ContentLoader from 'react-content-loader';

//  define custom loader svg
const Loader = (props) => (
  <ContentLoader
    height={props.height}
    primarycolor={props.pcolor ? props.pcolor : ' #f0f0f0'}
    secondarycolor={props.sec_color ? props.sec_color : '#d6dcd6'}
    width={props.width}
  >
    {/* svg only */}
    <rect
      x='0'
      y='0'
      rx={props.radius || '5'}
      ry={props.radius || '5'}
      width={props.width || ''}
      height={props.height}
    />
  </ContentLoader>
);

//  define custom loader svg
export default Loader;
export const CircularLoader = (props) => (
  <ContentLoader
    height={props.height || 30}
    primarycolor={props.pcolor ? props.pcolor : ' #f0f0f0'}
    secondarycolor={props.sec_color ? props.sec_color : '#d6dcd6'}
  >
    <circle cx='200' cy='50' r={props.radius || 30} />
  </ContentLoader>
);
