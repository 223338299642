/* eslint-disable react-hooks/exhaustive-deps */
import cogoToast from "cogo-toast";
import { useContext, useEffect, useState } from "react";
import RecommendUsersModal from "../../components/Modals/RecommendUsersModal";
import { FavoriteIconOutline } from "../../components/Svgs";
import { UserContext } from "../../context/user.context";
import Image from "../../components/shared/Image";
import { AddNewFavorite, RemoveFavorite } from '../../requests/user';


import {
  GetChatRequest,
  GetChildren,
  GetMyRecommendations,
  RecommendProfile,
  SendChatRequest,
  GetChatRequestSent,
  GetNetworkSuggestions,
} from "../../requests/user";
import {
  capitalize,
  getAgeFromYear,
  getFullName,
  profileIsChild,
  profileIsParent,
} from "../../utils";
import { useParams, useHistory } from "react-router-dom";
import SpiralLoader from "../../components/loaders/SpiralLoader";
import axios from "axios";

function EditProfileTrigger() {
  return (
    <button
      type="button"
      className="btn solid-btn"
      data-bs-toggle="modal"
      data-bs-target="#editProfileForm"
    >
      Edit Profile
    </button>
  );
}

const buttonText = (props) => {
  if (props?.isLoading || !props.users) {
    return <SpiralLoader size="sm" />
  } else if (props?.isChild) {
    return props?.text;
  } else if (!props.isChild) return "Talk to Parent";
};

function NetworkActionTrigger(props) {
    const hasChildren = props.children &&  props.children.length > 0
    console.log(hasChildren)

    const clickHandler = ()=>{
      if( props.isChild === false && props.sender){
        props.sendChatRequest(props.sender, props.selectedChild)
      }
      if(props.isChild === false && hasChildren === false){
        cogoToast.error('You do not have a child account, please invite your children to start matching')
      }
      if(props.isChild){
        props.onActionClick()
      }
    }

  if (props.users?.recommended) {
    return (
      <button
        type="button"
        className="btn solid-btn w-100 d-flex align-items-center justify-content-center"
        disabled={props.isRecommended || props.chatRequested}
        onClick={props.sender ? ()=>props.sendChatRequest(props.sender, props.selectedChild):props.onActionClick}
        data-bs-toggle={!props.isChild && !props.sender ? "modal" : ""}
        data-bs-target={
          !props.isChild && !props.sender ? "#recommendPopUp" : ""
        }
      >
        {buttonText(props)}
      </button>
    );
  } else {
    return (
      <button
        type="button"
        className="btn solid-btn w-100 align-items-center d-flex justify-content-center"
        disabled={props.isRecommended || props.chatRequested}
        onClick={clickHandler}
        data-bs-toggle={
          !props.isChild && 
          !props.sender &&
           (props.children&&props.children.length>0) ?
            "modal" : ""}
        data-bs-target={
          !props.isChild && !props.sender ? "#recommendPopUp" : ""
        }
      >
        {buttonText(props)}
      </button>
    );
  }
}

const ProfileCard = ({senderId, isProfilePage, profile}) => {
  const { data, profile: userProfile, logout } = useContext(UserContext);
  const [isRecommended, setIsRecommended] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [children, setChildren] = useState(null);
  const [loadingChildren, setLoadingChildren] = useState(true);
  const [chatRequested, setChatRequested] = useState(false);
  const [chatRequest, setChatRequest] = useState(null);
  const [recommendText, setRecommendText] = useState("Recommended");
  const [networks, setNetworks] = useState([]);
  const [profiles, setProfiles] = useState(null);
  const [selectedChild, setSelectedChild] = useState({})
  const [favorited, setFavorited] = useState(false)
  const [isLiking, setIsLiking] = useState(false)

  const user = data && data.user;

  const history = useHistory();

  //Check if users access token has expired with a 401 error code
  const checkAuthorizedUser = (error) => {
    if (error?.message === "Request failed with status code 401") {
      logout(history);
    }
  };

  useEffect(() => {
    if (networks && networks.length < 0) return; 

    // setIsLoading(true);
    GetNetworkSuggestions()
      .then((data) => {
        setNetworks(data.data);
      })
      .catch((err) => {
        checkAuthorizedUser(err);
        // console.log("network error: ", err);
      });
  }, []);

  const { profileId } = useParams();

  useEffect(() => {
    if (!profileId) return;

    setProfiles(networks.find((prof) => prof.id === profileId));
  }, [networks, profileId]);

  //check if profile has been favorited
  useEffect(()=>{
    console.log(profiles)
})

  //Check  if Profile is recommended on load
  useEffect(() => {
    if (profiles) {
      if (profiles?.recommended) {
        setIsRecommended(true);
        setRecommendText("Recommended");
      } else if (!profiles?.recommended) {
        setIsRecommended(false);
        setRecommendText("Recommend To Parent");
      }
    }
  }, [profiles]);

  useEffect(() => {
    if (!user) return;

    if (profileIsParent(user.userType)) {
      setLoadingChildren(true);
      GetChildren(user.id)
        .then((res) => {
          setChildren(res.data)
          setLoadingChildren(false);
        })
        .catch((err) => {
          setLoadingChildren(false);
          // console.log("err ", err);
        });
    }
  }, [user && user.userType]);

  useEffect(() => {
    if (user && profileIsParent(user.userType)) checkChatRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user && user.id, profile && profile.id]);

  
  useEffect(()=>{
    if(senderId && children){
      const child = (children.find(child=>child.id === senderId))
      setSelectedChild(child)
    }
  },[senderId, children])
  
  const closeModal = () => {
    const $ = window.$ || window.jQuery;

    $(function () {
      $(".modal").modal("hide");
    });
  };

  const sendChatRequest = (childId, selectedChild) => {
   
    if (!childId) return;
    const data = {
      senderUserId: user.id, // user id (userType === 'Parent')
      receiverUserId: profile.parentId, // network profile parent's id
      senderChildUserId: childId, // id of the child to send request for
      receiverChildUserId: profile.id, // id of the current network profile
    };

  setIsLoading(true);
    SendChatRequest(data)
      .then((res) => {
        if (res.statusCode === 201) {
          setChatRequested(true);

          setTimeout(() => {
            setIsLoading(false);
            cogoToast.success(
              `You have sent a chat request to ${profile.firstName}'s auntie 😊`
            );
          }, 500);

          setTimeout(closeModal, 2000);
        } else {
          cogoToast.success(
            `There was an error sending the request, Try again later 🙁`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        cogoToast.error(
          `Chat request already sent on behalf of ${selectedChild?.firstName} 🙁`
        );
        setLoading(false);

        setIsLoading(false);
        // console.log("err ", err.code, err.statusCode, err);
      });
  };

  const onActionClick = () => {
    if (loading || isRecommended) return;

    if (profileIsChild(user.userType)) {
      if (!canSendRecommendation(user)) return;
      // send recommendation
      const data = {
        senderUserId: user.id,
        receiverUserId: user.parentId,
        recommendedUserIds: [profile.id],
      };

      sendRecommendationRequest(data);
    } else {
    }
  };

  const sendRecommendationRequest = (data) => {
    setLoading(true);
    RecommendProfile(data)
      .then((res) => {
        if (res.statusCode === 201) {
          setIsRecommended(true);

          setTimeout(() => {
            setLoading(false);
            cogoToast.success(
              `${profile.firstName} has been recommended to your auntie 😊`
            );
            setRecommendText("Recommended");
          }, 500);
        } else {
          cogoToast.success(
            `There was an error recommending ${profile.firstName}to your auntie, Try again later 🙁`
          );
        }
      })
      .catch((err) => {
        setLoading(false);

        checkAuthorizedUser(err);
        // console.log("err ", err);
      });
  };

  const checkChatRequest = () => {
    let data;

    if (profileIsParent(user.userType)) {
      //console.log("sender id", user.id);
      //console.log("reciever child", profile.id);
      data = {
        senderUserId: user.id, // user id (userType === 'Parent')
        receiverChildUserId: profile.id, // id of the current network profile
      };
    } else {
      data = {
        senderChildUserId: user.id, // user id (userType === 'Child')
        receiverChildUserId: profile.id, // id of the current network profile
      };
    }

    setLoading(true);
    GetChatRequest(data)
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200 && res.data) {
          setChatRequested(true);
          //console.log(res.data);
          setChatRequest(res.data);
        } else {
          setChatRequested(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        checkAuthorizedUser(err);
        // console.log("err ", err.code, err.statusCode, err);
      });
  };

  const canSendRecommendation = (user) => {
    return user.parentId && user.parentId !== null;
  };

  const canDisableNetworkActionTrigger = () => {
    if (loading) return true;

    if (profileIsParent(user.userType)) {
      return chatRequested;
    }
    return profile?.recommended && isRecommended;
  };

  if (!user) return <span>No user found, Login in to continue</span>;

  const favoriteProfile = async (e) => {
    e.stopPropagation();

    if (isLiking || profiles.favourited) return;
    setIsLiking(true);

    try {
      setIsLiking(false);
      const res = await AddNewFavorite({
        initiatorUserId: user.id,
        favouritedUserId: profiles.id,
      });

      if (res.statusCode === 201) {
        setProfiles({
          ...profiles,
          favouriteId: res.data.favoriteRecord.id,
          favourited: true,
        });
      }
    } catch (e) {
      setIsLiking(false);
      // console.log("ADD FAVORITE ERROR :", e);
    }
  };

  const removeFavourite = async (e) => {
    e.stopPropagation();

    if (isLiking || !profiles.favourited) return;
    setIsLiking(true);

    try {
      setIsLiking(false);
      const res = await RemoveFavorite({
        id: profiles.favouriteId,
        initiatorUserId: user.id,
        favouritedUserId: profiles.id,
      });

      if (res.statusCode === 200) {
        setProfiles({
          ...profiles,
          favouriteId: null,
          favourited: false,
        });
      }
    } catch (e) {
      setIsLiking(false);
      // console.log("REMOVE FAVORITE ERROR :", e);
    }
  };

  return (
    <>
      <div className="card card-light text-center">
        <div className="card-body ">
          <div className="row">
            <div className="w-100">
              {!isProfilePage && (
                <div
                style={{cursor:"pointer"}}
                  className={`profile-like-it float-end ${
                    profiles && profiles.favourited ? "liked animate__heartBeat" : 'like-it animate__bounceIn'
                  }`}
                  onClick={(e)=>profiles.favourited ? removeFavourite(e) : favoriteProfile(e)}
                >
                  <FavoriteIconOutline />
                </div>
              )}
            </div>
          </div>
          <div className="profile-img-with-text text-center">
            <Image
              alt="profile"
              className="round-image-120 mx-auto"
              src={profileId ? profile.profileImage : userProfile.profileImage || 'assets/images/profile-avatar.png'}
              fallback="assets/images/profile-avatar.png"
            />

            <p>
              {profileId
                ? getFullName(profile.firstName, profile.lastName)
                : getFullName(userProfile.firstName, userProfile.lastName)}

              <span>
                {profileId
                  ? capitalize(profile?.gender || "")
                  : capitalize(userProfile?.gender || "")}
                <i className="fas fa-dot-circle" />{" "}
                {profileId
                  ? getAgeFromYear(profile?.birthDate || "01/01/2000")
                  : getAgeFromYear(userProfile?.birthDate || "01/01/2000")}
                y
              </span>
            </p>
            <div className="action-buttons mt-3">
              <div className="row justify-content-center">
                <div className="row justify-content-center">
                  {isProfilePage && <EditProfileTrigger></EditProfileTrigger>}
                  {!isProfilePage && (
                    <NetworkActionTrigger
                      isChild={user && profileIsChild(user.userType)}
                      onActionClick={onActionClick}
                      disabled={canDisableNetworkActionTrigger()}
                      text={recommendText}
                      isRecommended={isRecommended}
                      isLoading={loading}
                      users={profiles}
                      sender={senderId}
                      sendChatRequest={sendChatRequest}
                      selectedChild = {selectedChild}
                      chatRequested={chatRequested}
                      children={children}
                    ></NetworkActionTrigger>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modals */}
      <RecommendUsersModal
        senderId={senderId}
        users={children}
        loading={loadingChildren}
        isloading={isLoading}
        onSelect={sendChatRequest}
        recieverChild={chatRequest}
        profile={profile}
      />
    </>
  );
};

export default ProfileCard;
