import axios from "axios";
import cogoToast from "cogo-toast";
import { LOGIN, REGISTER, VALIDATE, LOCATIONS } from "./endpoints";

export const Login = (_data) => {
  console.log(LOGIN);
  return axios
    .post(LOGIN, _data)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      cogoToast.error(
        err.response ? err.response.data.message[0] : "An Error Occurred",
        {
          position: "top-center",
        }
      );
    });
};

export const Locations = () => {
  return axios.get(LOCATIONS).then(({ data }) => {
    return data;
  });
};

export const Register = (_data) => {
  return axios
    .post(REGISTER, _data)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      cogoToast.error(err.response.data.message[0], { position: "top-center" });
    });
};

export const Validate = (_data) => {
  return axios.post(VALIDATE, _data).then(({ data }) => {
    return data;
  });
};
