import * as yup from "yup";
 const date = new Date()
 const year = date.getFullYear()
 const maxYear = year - 36

 const maxBirthDate = `${maxYear}-12-30`


export const RegisterSchema = yup.object().shape({
    firstName: yup
        .string()
        .required("Firstname is required"),
    lastName: yup
        .string()
        .required("Lastname is required"),
    birthDate: yup
        .date()
        .max(maxBirthDate, "You must be at least 35 years")
        .required("Date of birth is required"),
    gender: yup
        .string()
        .required("gender is required"),
    profession: yup
        .string()
        .required("profession is required"),
    location: yup
        .string()
        .required("location is required"),     
    email: yup
        .string()
        .email("Enter a valid email")
        .required("Email is required"),
    password: yup
        .string()
        .min(8, "Password should be of minimum 8 characters length")
        .required("Password is required"),
    confirmPassword: yup
        .string()
        .min(8, "Password should be of minimum 8 characters length")
        .required("Confirm Password"),
    phoneNumber:yup
        .number().required()
    
});
