import {
	memo,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import cogoToast from 'cogo-toast';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { capitalize } from '../../utils';
import { MessagingContext } from '../../context/messaging.context';
import { useHotkeys } from 'react-hotkeys-hook';
import useChat from '../../hooks/useChat';
import Image from '../../components/shared/Image';
import { ChatRequestAction } from './ChatRequestAction';
import Loader from '../../components/loaders/loader';
import SpiralLoader from '../../components/loaders/SpiralLoader';
import { UserContext } from '../../context/user.context';

function EmptyMessages({ loading }) {
	return (
		<div className="card-body" style={{ overflowY: 'auto' }}>
			<div className="empty-card text-center">
				<div className="icon-container-no-bg">
					{loading && (
						<Loader width="120" height="120" radius="50%" />
					)}
					{!loading && (
						<svg
							width={120}
							height={120}
							viewBox="0 0 120 120"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clipPath="url(#clip0)">
								<rect
									width={120}
									height={120}
									rx={60}
									fill="#FADCE9"
								/>
								<g opacity="0.5" filter="url(#filter0_bd)">
									<circle
										cx="-32.5"
										cy="19.5"
										r="78.5"
										fill="white"
									/>
								</g>
								<path
									d="M80.3566 66.4284C80.3566 67.565 79.9051 68.6551 79.1013 69.4589C78.2976 70.2626 77.2075 70.7141 76.0709 70.7141H50.3566L41.7852 79.2855V44.9998C41.7852 43.8632 42.2367 42.7731 43.0404 41.9694C43.8441 41.1656 44.9342 40.7141 46.0709 40.7141H76.0709C77.2075 40.7141 78.2976 41.1656 79.1013 41.9694C79.9051 42.7731 80.3566 43.8632 80.3566 44.9998V66.4284Z"
									fill="#E80F6D"
								/>
								<g opacity="0.4" filter="url(#filter1_bd)">
									<circle
										cx={122}
										cy={68}
										r={30}
										fill="white"
									/>
								</g>
								<circle cx={54} cy={56} r={2} fill="white" />
								<circle cx={61} cy={56} r={2} fill="white" />
								<circle cx={68} cy={56} r={2} fill="white" />
							</g>
							<defs>
								<filter
									id="filter0_bd"
									x={-215}
									y={-163}
									width={365}
									height={365}
									filterUnits="userSpaceOnUse"
									colorInterpolationFilters="sRGB"
								>
									<feFlood
										floodOpacity={0}
										result="BackgroundImageFix"
									/>
									<feGaussianBlur
										in="BackgroundImage"
										stdDeviation={52}
									/>
									<feComposite
										in2="SourceAlpha"
										operator="in"
										result="effect1_backgroundBlur"
									/>
									<feColorMatrix
										in="SourceAlpha"
										type="matrix"
										values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
										result="hardAlpha"
									/>
									<feOffset dy={4} />
									<feGaussianBlur stdDeviation={17} />
									<feColorMatrix
										type="matrix"
										values="0 0 0 0 0.78 0 0 0 0 0 0 0 0 0 0.338 0 0 0 0.2 0"
									/>
									<feBlend
										mode="normal"
										in2="effect1_backgroundBlur"
										result="effect2_dropShadow"
									/>
									<feBlend
										mode="normal"
										in="SourceGraphic"
										in2="effect2_dropShadow"
										result="shape"
									/>
								</filter>
								<filter
									id="filter1_bd"
									x={-12}
									y={-66}
									width={268}
									height={268}
									filterUnits="userSpaceOnUse"
									colorInterpolationFilters="sRGB"
								>
									<feFlood
										floodOpacity={0}
										result="BackgroundImageFix"
									/>
									<feGaussianBlur
										in="BackgroundImage"
										stdDeviation={52}
									/>
									<feComposite
										in2="SourceAlpha"
										operator="in"
										result="effect1_backgroundBlur"
									/>
									<feColorMatrix
										in="SourceAlpha"
										type="matrix"
										values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
										result="hardAlpha"
									/>
									<feOffset dy={4} />
									<feGaussianBlur stdDeviation={17} />
									<feColorMatrix
										type="matrix"
										values="0 0 0 0 0.78 0 0 0 0 0 0 0 0 0 0.338 0 0 0 0.1 0"
									/>
									<feBlend
										mode="normal"
										in2="effect1_backgroundBlur"
										result="effect2_dropShadow"
									/>
									<feBlend
										mode="normal"
										in="SourceGraphic"
										in2="effect2_dropShadow"
										result="shape"
									/>
								</filter>
								<clipPath id="clip0">
									<rect
										width={120}
										height={120}
										rx={60}
										fill="white"
									/>
								</clipPath>
							</defs>
						</svg>
					)}
				</div>
			</div>
			{loading ? (
				<p className="text-center">
					<Loader width="150" height="20" />
				</p>
			) : (
				<p className="text-center" style={{ fontSize: 'large' }}>
					Start a new conversation
				</p>
			)}
		</div>
	);
}

function RequestChildMatch({ userId, chatRequest }) {
	const { sendMatchRequest, isMatchRequestLoading } =
		useContext(MessagingContext);

	return (
		<div className="match-request">
			{!isMatchRequestLoading && 'Request child match'}
			{isMatchRequestLoading && <SpiralLoader />}
			{!isMatchRequestLoading && (
				<button
					type="button"
					className="request-btn"
					onClick={(e) =>
						sendMatchRequest({
							senderUserId: chatRequest.sender.userId,
							receiverUserId: userId,
							id: chatRequest.recordId,
						})
					}
				>
					<svg
						width={25}
						height={25}
						viewBox="0 0 30 29"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0)">
							<path
								d="M26.785 14.142H13.8214"
								stroke="#FCFCFC"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M26.7851 14.142L10.8752 21.8024L13.8215 14.142L10.8752 6.48172L26.7851 14.142Z"
								stroke="#FCFCFC"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</g>
						<defs>
							<clipPath id="clip0">
								<rect
									width={20}
									height={20}
									fill="white"
									transform="translate(15) rotate(45)"
								/>
							</clipPath>
						</defs>
					</svg>
				</button>
			)}
		</div>
	);
}

function MatchRequestResponder({ chatRequest, userId }) {
	const { respondToMatchRequest, isMatchRequestLoading } =
		useContext(MessagingContext);
	console.log('respond child match rendered -------> ', chatRequest, userId);

	return (
		<div className="match-request-responder ">
			{isMatchRequestLoading && <p>Accepting Request...</p>}
			{!isMatchRequestLoading && (
				<>
					<div className="match-profile-container">
						<figure className="children">
							<Image
								src={chatRequest.receiverChild.profileImage}
								fallback="/assets/images/default.png"
								alt=""
								className="round-image-40"
							/>
							<figcaption className="caption">
								{chatRequest.receiverChild.firstName}
							</figcaption>
						</figure>
						<i className="divider">
							<svg
								width={8}
								height={8}
								viewBox="0 0 8 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx={4} cy={4} r={4} fill="#E80F6D" />
							</svg>
							<svg
								width={8}
								height={8}
								viewBox="0 0 8 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle
									cx={4}
									cy={4}
									r="3.5"
									stroke="#E80F6D"
								/>
							</svg>
						</i>
						<figure className="children">
							<Image
								src={chatRequest.senderChild.profileImage}
								fallback="/assets/images/default.png"
								alt=""
								className="round-image-40"
							/>
							<figcaption className="caption">
								{chatRequest.senderChild.firstName}
							</figcaption>
						</figure>
					</div>
					<div className="match-action-btn">
						<button
							type="button"
							className="request-btn mx-1"
							onClick={(e) =>
								respondToMatchRequest({
									senderUserId: userId,
									receiverUserId: chatRequest.receiver.userId,
									id: chatRequest.recordId,
									action: 'Accept',
								})
							}
						>
							<svg
								width={22}
								height={22}
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M20 6L9 17L4 12"
									stroke="#00BA88"
									strokeWidth={2}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
						<button
							type="button"
							className="request-btn"
							onClick={(e) =>
								respondToMatchRequest({
									senderUserId: userId,
									receiverUserId: chatRequest.receiver.userId,
									id: chatRequest.recordId,
									action: 'Reject',
								})
							}
						>
							<svg
								width={22}
								height={22}
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M18 6L6 18"
									stroke="#E80F6D"
									strokeWidth={2}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6 6L18 18"
									stroke="#E80F6D"
									strokeWidth={2}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</div>
				</>
			)}
		</div>
	);
}

const TextMessage = memo(({ message, author, participant, loading, style }) => {
	//console.log(JSON.stringify(message))
	return (
		<>
			{loading ? (
				<div className={`${style}`}>
					<p>
						<Loader height="50" width="120" />
					</p>
				</div>
			) : (
				<div
					className={`${
						message.authorId === author.id
							? 'outbound-message-content'
							: 'inbound-message-content'
					}`}
				>
					<p>{message.body}</p>
					<div className="chat-time">
						{moment(message.dateLastUpdated).format(
							'MMM DD, YYYY hh:mm A'
						)}
					</div>
				</div>
			)}
		</>
	);
});

const RenderMessages = memo(
	({ messageContainerRef, messages, author, participant, loading }) => {
		useLayoutEffect(() => {
			// scroll effect
			// console.log("scroll ", messageContainerRef.current);
			window.setTimeout(function () {
				if (!messageContainerRef.current) return;
				messageContainerRef.current.scrollTop =
					messageContainerRef.current.scrollHeight;
			}, 1000);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [messages.length]);

		return (
			<>
				{loading &&
					[0, 1, 2, 3, 4, 5].map((item, i) => (
						<TextMessage
							loading={loading}
							style={
								i % 2 === 0
									? 'outbound-message-content'
									: 'inbound-message-content'
							}
						/>
					))}
				{!loading &&
					messages.map((message) => (
						<TextMessage
							key={message.messageId}
							author={author}
							message={message}
							participant={participant}
						></TextMessage>
					))}
			</>
		);
	}
);

function SearchIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
				stroke="#6E7191"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.5 17.5L13.875 13.875"
				stroke="#6E7191"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

const MessagesHeader = ({
	child,
	name,
	profileImage,
	loading,
	matchStatus,
	reciever,
	userId,
	userType,
}) => {
	return (
		<>
			<div className="card-header">
				<div className="row justify-content-between">
					<div className="col-9">
						<div className="float-start">
							{loading ? (
								<Loader width="48" height="48" radius="50%" />
							) : (
								<Image
									src={
										profileImage ||
										'/assets/images/profile-avatar.png'
									}
									fallback="assets/images/default.png"
									alt="chat participant avatar"
									className="round-image-48 active"
								/>
							)}
						</div>
						<p>
							{loading ? (
								<Loader width="120" height="30" />
							) : (
								name
							)}
							{loading ? (
								<Loader width="200" height="20" />
							) : (
								<>
									{child && (
										<span
											className={
												userType === 'Parent'
													? ''
													: 'd-none'
											}
										>
											{capitalize(reciever.profession)}
											{reciever.profession && <i className="fas fa-dot-circle" />}
											<Link
												style={{ color: '#6e7191' }}
												to={`/profile/${
													child && child.userId ? child.userId : ''
												}`}
											>{`${
												child && child.userId ? capitalize(child.firstName) : ''
											}'s`}</Link>{' '}
											Auntie
										</span>
									)}
									{!child && (
										<span
											className={
												userType === 'Parent'
													? ''
													: 'd-none'
											}
										>
											{capitalize(reciever.gender)}
											<i className="fas fa-dot-circle" />
											<Link
												style={{ color: '#6e7191' }}
												to={`/profile/${
													reciever && reciever.userId ? reciever.userId : ''
												}`}
											>View Profile</Link>{' '}
										</span>
									)}
								</>
							)}

							{userType === 'Parent' &&
							reciever.userId === userId &&
							matchStatus === 'Pending' &&
							!loading ? (
								<span className="messageHeader">
									Request sent
								</span>
							) : (
								''
							)}

							{userType === 'Parent' &&
								matchStatus === 'Accepted' &&
								!loading && (
									<span className="messageHeader">
										Request accepted
									</span>
								)}
						</p>
					</div>
					<div className="col-3 chat-top-actions">
						<li className="d-none float-end search">
							<SearchIcon></SearchIcon>
						</li>
					</div>
				</div>
			</div>
		</>
	);
};

function EmojiSelectorIcon() {
	return (
		<svg
			width={18}
			height={18}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				stroke="#A0A3BD"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14"
				stroke="#A0A3BD"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 9H15.01"
				stroke="#A0A3BD"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 9H9.01"
				stroke="#A0A3BD"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

function ImageSelectorIcon() {
	return (
		<svg
			width={18}
			height={18}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
				stroke="#A0A3BD"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 15L16 10L5 21"
				stroke="#A0A3BD"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
				stroke="#A0A3BD"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

const Messages = ({screenIsMobile, showChatsHandler}) => {
	const {
		userId,
		activeChat,
		activeChatRequest,
		messagesStore,
		chats,
		chatRequests,
		sendMessage,
		loading,
	} = useContext(MessagingContext);

	const [isLoading, setIsLoading] = useState(true);
	const { data } = useContext(UserContext);
	let userType;
	if (data.user) {
		userType = data.user.userType;
	}

	const messages = activeChat && messagesStore[activeChat].messages;

	const hasMessages = messages && messages.length > 0;
	const hasChatRequest =
		!activeChat && activeChatRequest && chatRequests.length > 0;
	const isEmpty = !hasMessages && !hasChatRequest;
	const isActive = activeChat;

	const chat =
		chats.length > 0 && chats.find((obj) => obj.chatId === activeChat);
	const chatRequest =
		chatRequests.length > 0 &&
		chatRequests.find((obj) => obj.recordId === activeChatRequest);

	const currentChatRequest =
		chat && chatRequests.filter((cr) => cr.recordId === chat.chatRequestId);

	console.log(currentChatRequest);
	const [author, participant] = useChat(userId, chat, currentChatRequest);
	console.log(chat);
	const [text, setText] = useState('');
	const messageContainerRef = useRef(null);

	const sendText = () => {
		if (!text) return;
		sendMessage(text);
		setText('');
	};

	// Press Enter key to send message
	useHotkeys('Enter', () => sendText(), { enableOnTags: ['INPUT'] });

	console.log(participant);

	const getCurrentChatRequest = (status) => {
		if (!chat) return false;
		const list = chatRequests.filter(
			(cr) =>
				cr.matchStatus === status && cr.recordId === chat.chatRequestId
		);
		if (!list.length) return;
		return list[0];
	};

	const canRequestMatch = () => {
		const chat = getCurrentChatRequest('');
		if (!chat) return false;

		return (
			chat.receiver.userId === userId &&
			chat.matchStatus === '' &&
			chat.status === 'Accepted'
		);
	};

	const canRespondToMatch = () => {
		const chat = getCurrentChatRequest('Pending');
		if (!chat) return false;

		return (
			chat.sender.userId === userId &&
			chat.matchStatus === 'Pending' &&
			chat.status === 'Accepted'
		);
	};

	const disableInputs = hasChatRequest || !activeChat;

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);

	return (
		<div className="">
			{
				screenIsMobile &&
				<div onClick={()=>showChatsHandler()} className="w-100 back-to-chats mt-2">
					<span>&nbsp;</span>
				</div>
			}
			<div className="card card-light card-min-height-730 h-100">
				{(hasMessages || isActive) && participant.child && (
					<MessagesHeader
						name={participant.name}
						profileImage={participant.profileImage}
						loading={isLoading || loading}
						child={participant.child}
						matchStatus={participant.matchStatus}
						reciever={participant.receiver}
						userId={userId}
						userType={userType}
					/>
				)}
				{isEmpty && (
					<EmptyMessages
						loading={isLoading || loading}
					></EmptyMessages>
				)}

				{/*hasChatRequest && (
          <MessagesHeader
            name={chatRequest.sender.firstName}
            profileImage={chatRequest.sender.profileImage}
          />
        )*/}

				{hasChatRequest && (
					<ChatRequestAction
						loading={isLoading || loading}
						chatRequest={chatRequest}
					/>
				)}
				{!hasChatRequest && (
					<div
						className="card-body"
						style={{ overflowY: 'auto' }}
						ref={messageContainerRef}
					>
						<div className="message-body-container">
							<div className="message-thread">
								{!isLoading &&
									!loading &&
									canRequestMatch() && (
										<RequestChildMatch
											userId={userId}
											chatRequest={getCurrentChatRequest(
												''
											)}
										></RequestChildMatch>
									)}
								{!isLoading &&
									!loading &&
									canRespondToMatch() && (
										<MatchRequestResponder
											userId={userId}
											chatRequest={getCurrentChatRequest(
												'Pending'
											)}
										></MatchRequestResponder>
									)}
								{hasMessages && (
									<RenderMessages
										messageContainerRef={
											messageContainerRef
										}
										messages={messages}
										author={author}
										participant={participant}
										loading={isLoading || loading}
									/>
								)}
							</div>
						</div>
					</div>
				)}
				<div className="card-footer bg-transparent">
					{isActive && !hasChatRequest && (
						<div className="chat-component-container">
							<div className="d-flex align-items-center w-100">
								<div className="col">
									<div className="chat-input col-12">
										<input
											className="content-editable bg-transparent w-100 border-0"
											contentEditable="false"
											aria-multiline="true"
											aria-label="Write a message…"
											placeholder="Write a message…"
											spellCheck="false"
											type="text"
											value={text}
											disabled={disableInputs}
											onChange={(e) =>
												setText(e.target.value)
											}
										/>
									</div>
								</div>
								{/* <div className="col-2">
                  <div className="action-icon-container d-flex">
                    <li className="align-middle">
                      <EmojiSelectorIcon></EmojiSelectorIcon>
                    </li>
                    <li className="align-middle">
                      <ImageSelectorIcon></ImageSelectorIcon>
                    </li>
                  </div>
                </div> */}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Messages;
