import { MessageIcon } from "../../components/Svgs";


function RecentChats() {
  return (
    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
      <div className="card card-light with-bg card-min-height-380">
        <div className="card-body">
          <div className="row justify-content-between card-title-container mb-4">
            <div className="col-6">
                <h5 className="card-title with-bg float-start">Recent chats</h5>
            </div>
            <div className="col-2">
            </div>
          </div>

          <div className="empty-card text-center">
            <div className="icon-container mx-auto">
              <MessageIcon></MessageIcon>
            </div>
            <p className="card-text">Your recent chats will appear here.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentChats;