import cogoToast from 'cogo-toast';
//import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Register } from '../../../requests/auth';
import { RegisterSchema } from '../../../validation/Register.validation';
import SpiralLoader from '../../../components/loaders/SpiralLoader';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { useEffect } from 'react';
import { states, occupations } from '../../../utils/data';
import Select from 'react-select';
import { capitalize } from '../../../utils';

export const RegisterEmailForm = ({ setContext }) => {
	const formValues = {
		birthDate: '',
		email: '',
		firstName: '',
		gender: '',
		lastName: '',
		profession: '',
		location: '',
	};

	const reactSelectValues = {
		gender: { option: '', value: '' },
		location: { option: '', value: '' },
		profession: { option: '', value: '' },
	};

	const [loading, setLoading] = useState(false);
	const { setItem, getItem } = useLocalStorage();
	const details = getItem('details');
	const reactSelectDetails = getItem('reactSelectDetails');
	const [items, setItems] = useState(details ? details : formValues);
	const [reactSelectData, setReactSelectData] = useState(
		reactSelectDetails ? reactSelectDetails : reactSelectValues
	);
	const [disabled, setDisabled] = useState(true);

	const history = useHistory();

	const handleSubmit = (e) => {
		e.preventDefault();

		RegisterSchema.validate(items)
			.then((valid) => {
				if (valid) {
					const specialRegex =
						/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
					if (items.password !== items.confirmPassword) {
						return cogoToast.error('Passwords does not match', {
							position: 'top-center',
						});
					}
					if (
						items.firstName.match(specialRegex) !== null ||
						items.lastName.match(specialRegex) !== null
					) {
						return cogoToast.error(
							'No special characters is allowed in Your Name',
							{
								position: 'top-center',
							}
						);
					}
					if (
						items.firstName.length < 2 ||
						items.lastName.length < 2
					) {
						return cogoToast.error(
							'first name or last name should be at least two characters',
							{
								position: 'top-center',
							}
						);
					}
					setLoading(true);
					console.log(items.birthDate);

					Register({
						...items,
						userType: 'Parent',
						registrationChannel: 'Email',
						birthDate: new Date(
							items.birthDate
						).toLocaleDateString(),
						firstName: capitalize(items.firstName),
						lastName: capitalize(items.lastName),
						email: items.email.toLowerCase(),
					})
						.then((res) => {
							setTimeout(() => {
								history.push('/verify', {
									email: items.email,
									userId: res ? res.data.userId : '',
								});
							}, 2000);
						})
						.catch((err) => {
							// console.log("registration error --->", err);
						})
						.finally(() => {
							setLoading(false);
							delete items.password;
							delete items.confirmPassword;
							setItem('details', items);
							setItem('reactSelectDetails', reactSelectData);
						});
				}
			})

			.catch((err) => {
				console.log(err.errors);
				cogoToast.error(err.errors[0], { position: 'top-center' });
			});
	};

	const handleChange = (name, value) => {
		if (name === 'gender' || name === 'location' || name === 'profession') {
			setItems({ ...items, [name]: value.value });
			setReactSelectData({ ...reactSelectData, [name]: value });
		} else {
			setItems({ ...items, [name]: value });
		}
	};

	useEffect(() => {
		const $ = window.$;
		if (
			$('.input1').val().length > 1 &&
			$('.input2').val().length > 1 &&
			$('.input3').val().length > 1 &&
			items.gender &&
			items.profession &&
			items.location &&
			$('.input7').val().length > 1 &&
			$('.input8').val().length > 1 &&
			$('.input9').val().length > 1 &&
			$('.input10').val().length > 1
		) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [items]);

	const genderOptions = [
		{ value: 'Male', label: 'Male' },
		{ value: 'Female', label: 'Female' },
	];

	const occupationOptions = occupations.map((occupation) => ({
		value: occupation,
		label: occupation,
	}));

	const locationOptions = states.map((location) => ({
		value: location,
		label: location,
	}));
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			'border-radius': '16px',
			padding: '14px 14px',
			'margin-bottom': '20px',
			background: '#EFF0F7',
			color: '#6E7191',
			border: 'none',
		}),
	};

	return (
		<div className="col-md-12 col-lg-7 col-xl-7">
			<div className="col-md-8 col-lg-8 col-xl-7 mx-auto form-content-container">
				<h1 className>Parent Sign Up</h1>
				<div className="form-wrapper">
					<form onSubmit={(event) => handleSubmit(event)} action="#">
						<div className="row g-2 mb-2">
							<div className="col-md">
								<div className="form-group">
									<label htmlFor className="form-label">
										First Name{' '}
										<span style={{ color: 'red' }}>*</span>
									</label>
									<input
										onChange={({
											target: { value, name },
										}) => handleChange(name, value)}
										type="text"
										name="firstName"
										className="form-control input1"
										placeholder="Enter your First Name"
										value={items.firstName}
									/>
								</div>
							</div>
							<div className="col-md">
								<div className="form-group">
									<label htmlFor className="form-label">
										Last Name{' '}
										<span style={{ color: 'red' }}>*</span>
									</label>
									<input
										onChange={({
											target: { value, name },
										}) => handleChange(name, value)}
										name="lastName"
										type="text"
										className="form-control input2"
										placeholder="Enter your Last Name"
										value={items.lastName}
									/>
								</div>
							</div>
						</div>
						<div className="row g-2 mb-2">
							<div className="col-md">
								<div className="form-group">
									<label htmlFor className="form-label">
										Date of Birth{' '}
										<span style={{ color: 'red' }}>*</span>
									</label>
									<input
										onChange={({
											target: { value, name },
										}) => handleChange(name, value)}
										name="birthDate"
										type="date"
										className="form-control input3"
										placeholder="Date of Birth"
										value={items.birthDate}
									/>
								</div>
							</div>
							<div className="col-md">
								<div className="form-group">
									<label htmlFor className="form-label">
										Gender{' '}
										<span style={{ color: 'red' }}>*</span>
									</label>
									<Select
										onChange={(selectedOption) =>
											handleChange(
												'gender',
												selectedOption
											)
										}
										options={genderOptions}
										styles={customStyles}
										value={reactSelectData.gender}
										className="input4"
									/>
								</div>
							</div>
						</div>
						<div className="form-group mb-4">
							<label htmlFor className="form-label">
								Occupation{' '}
								<span style={{ color: 'red' }}>*</span>
							</label>
							<Select
								onChange={(selectedOption) =>
									handleChange('profession', selectedOption)
								}
								options={occupationOptions}
								styles={customStyles}
								value={reactSelectData.profession}
								className="input5"
							/>
						</div>
						<div className="form-group">
							<label htmlFor className="form-label">
								Location <span style={{ color: 'red' }}>*</span>
							</label>
							<Select
								onChange={(selectedOption) =>
									handleChange('location', selectedOption)
								}
								options={locationOptions}
								styles={customStyles}
								value={reactSelectData.location}
								className="input6"
							/>
						</div>
						<div className="form-group">
							<label htmlFor className="form-label">
								Email Address{' '}
								<span style={{ color: 'red' }}>*</span>
							</label>
							<input
								onChange={({ target: { value, name } }) =>
									handleChange(name, value)
								}
								name="email"
								type="email"
								className="form-control input7"
								placeholder="Email"
								value={items.email}
							/>
						</div>
						<div className="form-group">
							<label htmlFor className="form-label">
								Phone Number{' '}
								<span style={{ color: 'red' }}>*</span>
							</label>
							<input
								onChange={({ target: { value, name } }) =>
									handleChange(name, value)
								}
								name="phoneNumber"
								type="tel"
								className="form-control input8"
								placeholder="Phone Number"
								value={items.phoneNumber}
								pattern="/^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$/"
							/>
						</div>
						<div className="form-group">
							<label htmlFor className="form-label">
								Password <span style={{ color: 'red' }}>*</span>
							</label>
							<input
								onChange={({ target: { value, name } }) =>
									handleChange(name, value)
								}
								name="password"
								type="password"
								className="form-control input9"
								placeholder="Password"
								title="Your password must be at least 8 characters"
							/>
						</div>
						<div className="form-group">
							<label htmlFor className="form-label">
								Confirm Password{' '}
								<span style={{ color: 'red' }}>*</span>
							</label>
							<input
								onChange={({ target: { value, name } }) =>
									handleChange(name, value)
								}
								type="password"
								name="confirmPassword"
								className="form-control input10"
								placeholder="Confirm Password"
								title="Your password must be at least 8 characters"
							/>
						</div>

						<div className="col">
							<button
								type="submit"
								className=" button1 btn btn-primary solid-btn w-100 d-flex justify-content-center align-items-center"
								disabled={disabled}
							>
								{loading ? <SpiralLoader /> : 'Create Account'}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
