/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useCallback, useEffect, useState } from 'react';
// import { SyncRegistration } from "../requests/onboarding";
import axios from 'axios';
import { GetUser, GetProfileImages } from '../requests/user';
import useAuth from '../hooks/useAuth';
import useLocalStorage from '../hooks/useLocalStorage';
import { USER_PROFILE_STORAGE_KEY } from '../constants';
import { useHistory } from 'react-router-dom';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [data, setData] = useState({
    accessToken: '',
    userId: '',
    user: null,
  });

  const [profile, setProfile] = useState(null);

  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState([])

  const history = useHistory();

  // hooks
  const { login } = useAuth();
  const { getUser, clearStorage, setItem, getItem } = useLocalStorage();

  const _setData = useCallback((_data) => {
    login(_data);
    setData(_data);
    //This updates the profile state in context and localStorage immediately the user logs in
    setProfile(_data.user);
  }, []);

  const handlePlan = (data)=>{
    setPlan(data)
  }

  useEffect(() => {
    const data = getUser();

    if (data === null) return;

    if (!data.user && data.userId) {
      GetUser(data.userId).then((res) => {
        _setData({ ...data, user: res.data });
        console.log('first');
        // update profile
        setItem(USER_PROFILE_STORAGE_KEY, res.data);
        setProfile(res.data);
      });
    } else {
      setData(data);
      const p = getItem(USER_PROFILE_STORAGE_KEY);
      setProfile(p);
    }
    
  }, []);

  const FetchUser = () => {
    return GetUser(data.userId).then((res) => {
      const val = { ...data, user: res.data };
      setProfile(res.data);
      setData(val);
      return val;
    });
  };

  const UpdateUserProfile = () => {
    return GetUser(data.userId).then((res) => {
      console.log(res.data)
      setItem(USER_PROFILE_STORAGE_KEY, res.data);
      setProfile(res.data);
      return res.data;
    });
  };

  const getProfileImages = async (id) => {
    setLoading(true);
    try {
      const res = await GetProfileImages(id);

      // console.log(res);
      setLoading(false);
      if (res.statusCode === 200) {
        setImages(res.data);
      }
    } catch (err) {
      setLoading(false);
      // console.log("ERROR ", e);
    }
  };

  useEffect(() => {
    if (!profile?.id) return false;
    getProfileImages(profile.id);
  }, [profile?.id]);

  axios.interceptors.response.use((res) => {
    if (res?.config?.url.includes('api/auth/login') && res.status === 200) {
      _setData({ ...res.data.data });
    }
    return res;
  });

  axios.interceptors.response.use((res) => {
    if (
      res?.config?.url.includes('CompletePartialRegistration') &&
      res.status === 200
    ) {
      _setData({ ...res.data.data });
      login(res.data.data);
    }
    return res;
  });

  const logoutFn = async () => {
    await clearStorage();
    setData(null);
    setProfile(null);
    history.push('/login');
  };

  return (
    <UserContext.Provider
      value={{
        data: data,
        profile,
        getProfileImages,
        setData: _setData,
        logout: logoutFn,
        FetchUser,
        UpdateUserProfile,
        images,
        loading,
        plan,
        setPlan:handlePlan
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
