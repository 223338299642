import { useContext, useEffect, useState } from 'react';
// import { FavoriteIcon } from "../../components/Svgs";
import { UserContext } from '../../context/user.context';
import { GetUserFavoriteProfiles, RemoveFavorite } from '../../requests/user';
import { NavLink } from 'react-router-dom';
import FavoritesLoader from '../../components/loaders/FavoritesLoader';
import Image from '../../components/shared/Image';
import { fallBackImage, getAgeFromYear, sortFavourites } from '../../utils';
import Styles from './Favorite.module.css';

const loadScript = () => {
	const $ = window.$ || window.jQuery;

	$(function () {
		// owl carousel
		$('.owl-carousel').owlCarousel({
			loop: true,
			nav: true,
			dots: true,
			margin: 20,
			items: 4,
			responsiveClass: true,
			responsive: {
				0: {
					items: 1,
					nav: true,
					dots: true,
				},
				320: {
					items: 2,
					nav: true,
					dots: true,
				},
				600: {
					items: 3,
					nav: true,
					dots: true,
				},
				1200: {
					items: 4,
					nav: true,
					dots: true,
					loop: false,
				},
			},
		});
	});
};

function Favorites() {
	const { data } = useContext(UserContext);
	const [favorites, setFavorites] = useState({
		loading: false,
		data: [],
	});

	const user = data && data.user;

	const getUserFavorites = (id) => {
		loadScript();
		setFavorites({
			loading: true,
			data: [],
		});
		GetUserFavoriteProfiles(id)
			.then((res) => {
				if (res.statusCode === 200) {
					loadScript();
					setFavorites({
						loading: false,
						data: res.data.sort(sortFavourites),
					});
				}
			})
			.catch((err) => {
				// console.log("err ", err);
				setFavorites({
					loading: false,
					data: [],
				});
			});
	};

	useEffect(() => {
		// if (loading || favorites.length > 0) return;
		getUserFavorites(user.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.id]);

	const removeFavorited = async (favUserId, recordId) => {
		try {
			await RemoveFavorite({
				id: recordId,
				initiatorUserId: user.id,
				favouritedUserId: favUserId,
			});

			// const UpdatedFavorites = favorites.filter(
			//   (fav) => fav.recordId !== recordId
			// );

			getUserFavorites(user.id);
		} catch (err) {
			console.log(err.message);
		}
	};

	return (
		<div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
			<div className="card card-light card-min-height-395">
				<div className="card-body">
					<div className="row justify-content-between card-title-container mb-4">
						<div className="col-6">
							<h5 className="card-title with-bg float-start">
								Your favorites
							</h5>
						</div>
						<div className="col-2"></div>
					</div>
					{favorites.loading && <FavoritesLoader />}
					{favorites.data?.length > 0 && (
						<div className="owl-carousel owl-theme justify-content-center w-100">
							{favorites.data?.map(
								({ favourited, recordId }, index) => (
									<div
										key={recordId}
										className={` ${Styles.imageCard} favorite-item relative `}
									>
										<div className="favouritesDetails pl-3">
											<p> {`${favourited.firstName}`} </p>
											<p>
												{' '}
												{`${
													favourited.gender
														? favourited.gender
														: ''
												}  ${getAgeFromYear(
													favourited.birthDate
												)}y`}{' '}
											</p>
										</div>
										<NavLink
											to={`/profile/${favourited.userId}`}
										>
											<Image
												fallback={fallBackImage}
												src={favourited.profileImage}
												alt="profile avatar"
												styles={'w-100 '}
											/>
										</NavLink>
										{/* Feature yet to be fully implemented on backend 
                   <img
                    fallback={fallBackImage}
                    src={favourited.profileImage}
                    alt='profile avatar'
                    className={Styles.imgRounded}
                  /> */}

										<p
											onClick={() =>
												removeFavorited(
													favourited.userId,
													recordId,
													index
												)
											}
											className={Styles.favoriteIcon}
										>
											{' '}
											<i
												className={`${Styles.favoriteSvg} fas fa-heart`}
											></i>
										</p>
									</div>
								)
							)}
						</div>
					)}
					{!favorites.loading && favorites.data?.length === 0 && (
						<EmptyFavorites />
					)}
				</div>
			</div>
		</div>
	);
}

const EmptyFavorites = () => (
	<div className="empty-card text-center">
		<div className="icon-container mx-auto">
			<svg
				width={22}
				height={20}
				viewBox="0 0 22 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M19.8382 2.61012C19.3274 2.09912 18.721 1.69376 18.0535 1.4172C17.3861 1.14064 16.6707 0.998291 15.9482 0.998291C15.2257 0.998291 14.5103 1.14064 13.8428 1.4172C13.1754 1.69376 12.5689 2.09912 12.0582 2.61012L10.9982 3.67012L9.93817 2.61012C8.90647 1.57842 7.5072 0.998826 6.04817 0.998826C4.58913 0.998826 3.18986 1.57842 2.15817 2.61012C1.12647 3.64181 0.546875 5.04108 0.546875 6.50012C0.546875 7.95915 1.12647 9.35842 2.15817 10.3901L3.21817 11.4501L10.9982 19.2301L18.7782 11.4501L19.8382 10.3901C20.3492 9.87936 20.7545 9.27293 21.0311 8.60547C21.3076 7.93801 21.45 7.2226 21.45 6.50012C21.45 5.77763 21.3076 5.06222 21.0311 4.39476C20.7545 3.7273 20.3492 3.12087 19.8382 2.61012Z"
					fill="#E80F6D"
				/>
			</svg>
		</div>
		<p className="card-text">Your liked profiles appear here.</p>
		<NavLink className="btn btn-primary" to="/networks">
			Explore
		</NavLink>
	</div>
);

export default Favorites;
