import React, {useContext, useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';


import { getUserNotifications, updateUserNotifications, deleteUserNotifications } from '../../requests/user'
import { UserContext } from '../../context/user.context'
import { splitText, capitalizeAll } from '../../utils'
import { NotificationItemLoader } from '../../components/loaders/NotificationsLoader'

const Notification=({notification, updateNotifications, userId})=>{

    const [firstPart, secondPart] = splitText(notification.body) 
    
    const updateNotification=()=>{
        
        const _data=
            {
                id: notification.notificationId,
                ownerUserId: userId,
                read: "Yes"
            }
        updateUserNotifications(_data).then((data)=>{
            getUserNotifications(userId).then(({data:{data}})=>{
                console.log(data)
                updateNotifications(data)
            }).catch((error)=>{
                console.log(error)
            })
        })
    }

/*
    const deleteNotification=()=>{
        const _data = {
        "notificationIds": [notification.notificationId],
        "ownerUserId": userId
        }
        deleteUserNotifications(_data).then(data=>{
            console.log(data)
            getNotifications()
        })
    }
*/

    return <>
        <div className="notification align-items-center mb-3">
            <div onClick={()=>updateNotification()} className="notificationDescription col-8">
                <p className="notification-title">{capitalizeAll(notification.notificationType, "_")}</p>
                <p><span className="bodyFirstPart">{firstPart}</span> {secondPart}</p>
            </div>
            <div className="time col-3">
            {notification.read==='No' && <i class="fas fa-circle"></i>}
            <p className={notification.read==='Yes' && 'pt-4'}>{new Date().toDateString()}</p>
            </div>
            {/*<div className="col-1 pt-3">
                <i onClick={()=>deleteNotification()} class="far fa-trash-alt"></i>
            </div>*/}
        </div>
    </>
}

const Notifications =()=>{
    const [notifications, setNotifications] = useState([])
    const {data, logout} = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const history = useHistory();

    const userId = data && data.userId 

    const checkAuthorizedUser = (error) => {
        if (error?.message === 'Request failed with status code 401') {
          logout(history);
        }
    };

    const getNotifications = ()=>{
            setLoading(true)
        getUserNotifications(userId).then(({data:{data}})=>{
            setNotifications(data)
            setLoading(false)
        }).catch((error)=>{
            checkAuthorizedUser(error)
            console.log(error)
        })
    }

    const updateNotifications = (data)=>{
        setNotifications(data)
    }

    useEffect(()=>{
        getNotifications()
    },[userId])

    

    return <>
        <div className='row m-0'>
            <div className='col'>
            <h1 className='mb-0'>Notifications</h1>
            </div>
        </div>
        <div className='row m-0'>
            <div className='col-lg-7 col-md-12 col-sm-12 col-xs-12'>
                <div className='card card-light card-min-height-395'>
                    <div className={`${!loading && notifications.length === 0 ? 'd-flex justify-content-center': ''} card-body notifications`}>
                    {!loading && notifications.length === 0 ? <h3 className="text-align-center">No Notification</h3>: ''}
                    {
                            loading
                            ?
                            <>
                            <NotificationItemLoader/>
                            <NotificationItemLoader/>
                            <NotificationItemLoader/>
                            <NotificationItemLoader/>
                            </>
                            :
                            notifications.map(notification=>(  
                                <Notification 
                                    userId={userId}  
                                    notification={notification}
                                    updateNotifications ={updateNotifications} 
                                />                   
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Notifications