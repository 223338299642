import Loader from './loader';

const FavoritesLoader = () => {
  return (
    <>
      <div className='owl-carousel owl-theme justify-content-center w-100'>
      <div className='favorite-item'>
        <Loader height='220' width='105' radius='22' />
      </div>
      <div className='favorite-item'>
        <Loader height='220' width='105' radius='22' />
      </div>
      <div className='favorite-item'>
        <Loader height='220' width='105' radius='22' />
      </div>
      <div className='favorite-item'>
        <Loader height='220' width='105' radius='22' />
      </div>
    </div>
  </>
  );
};

export default FavoritesLoader;
