import React, { useContext, useEffect, useState } from 'react';
import StepWizard from 'react-step-wizard';
import cogoToast from 'cogo-toast';
import { OnboardingContext } from '../../context';
import DateOfBirth from './DateOfBirth';
import Height from './Height';
import Interest from './Interest';
import Meet from './Meet';
import Name from './Name';
import Password from './Password';
import Profile from './Profile';
import Gender from './Gender';
import ZipCode from './ZipCode';
import Location from './Location'
// import Start from './Start';
import Finish from './Finish';
import useLocalStorage from "../../hooks/useLocalStorage"
import {UserContext} from '../../context/user.context'
import {useHistory} from 'react-router-dom'

// styles
// import "../../assets/scss/main.scss";
// import "../../assets/scss/slider.scss";


let custom = {
    enterRight: 'animated fadeInRight',
    enterLeft: 'animated fadeInLeft',
    exitRight: 'animated fadeOutRight',
    exitLeft: 'animated fadeOutLeft'
}

const requriedFieldsPerStep = [
    { fields: [], message: "" },
    { fields: [], message: "" },
    { fields: ["birthDate"], message: "Choose a Date of Birth 🙂" },
    { fields: ["location"], message: "Choose a Location 🙂" },
    { fields: ["firstName", "lastName"] },
    { fields: ["height"], message: "Enter your Height 📏" },
    { fields: ["hobbies"], message: "Select at least one Hobby 🏊‍♀️" },
    { fields: [] },
    { fields: ["password"], message: "Choose a strong Password to continue 📌" },
]

const specialRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
//const numRegex = /[a-zA-Z@*.\s]/g;

export const VALIDATE_STEP = (data, step) => {
    let { fields, message } = requriedFieldsPerStep[step];
    let valid = true;
    console.log(data)
    
    
    fields.forEach((key) => {
        valid = valid && Boolean(data[key]);
        console.log(data[key])
        console.log(Boolean(data[key]))
        console.log(valid)
    });

    if (!valid) {
        cogoToast.error(message, { position: "top-center" })
    }

    if (!valid && step === 4) {
        if(fields && data){
            if (fields.includes("firstName") && !data.firstName) {
                cogoToast.error("Enter your firstname 😇", { position: "top-center" });
    
            } else if (fields.includes("lastName") && !data.lastName) {
                cogoToast.error("Enter your lastName 😇", { position: "top-center" });
            }
            
        }
    }

    /*
    if (step === 4){
        if(fields && data.zipcode){
            if (fields.includes("zipcode") && (data.zipcode.match(numRegex) !== null)) {
                cogoToast.error("No special character or words is allowed 😇", { position: "top-center" });
                return true
            }   
        }
    }
    */

    if(step===4){
        if(fields && data.firstName && data.firstName){
            if(fields.includes("firstName") && (data.firstName && data.firstName.match(specialRegex) !== null)){
                cogoToast.error("Please no special character is allowed 😇", { position: "top-center" });
                return false
            } else if(fields.includes("lastName") && (data.lastName && data.firstName.match(specialRegex) !== null)){
                cogoToast.error("Please no special character is allowed 😇", { position: "top-center" });
                return false
            } else if(fields.includes("firstName") && (data.firstName.length < 2)){
                cogoToast.error("Please name must be at least two characters")
                return false
            }
             else if(fields.includes("lastName") && (data.lastName.length < 2)){
                cogoToast.error("Please name must be at least two characters")
                return false
            }
        }
    }

    return valid
}


const Welcome = () => {
    const { data, setData } = useContext(OnboardingContext);
    const { getItem } = useLocalStorage();
    const onboardingData = getItem("TAN_ONBOARDING_DATA")

    

    const {data:userData} = useContext(UserContext)

    const [step, setStep] = useState(data.step);
    const updateStep = ({ activeStep }) => {
        setStep(activeStep)
    };

    const history = useHistory()

    useEffect(() => {
      if(userData && userData.user){
          history.push('/dashboard')
      }
      
      if(!onboardingData){
        window.location.replace('https://www.theauntienetwork.com/')
      }
     
    },[])
    
   
    
    /*useEffect(() => {
        
        if (!data.parentId) {
            window.location = "https://www.theauntienetwork.com/"
        }
    }, [data.parentId]);
    if (!data.parentId) return (<div></div>)*/
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    {/* nav bar */}
                    <nav className="navbar navbar-expand-xxl navbar-light">
                        <a className="navbar-brand" href="##">
                            <div className="logo-container">
                                <img src="/assets/images/auntie-network-logo.png" alt="description_icon" width="45px" height="auto" />
                            </div>
                        </a>
                    </nav>
                    <div className="onboarding">
                        {(step > 0
                            && step < 10) && <div className="steps">
                                <ul className={`step-${step - 1}`}></ul>
                            </div>}
                        <form >
                            <StepWizard initialStep = {step} onStepChange={updateStep} transitions={custom}>
                                <Gender  />
                                <Meet />
                                <DateOfBirth  />
                                <Location />
                                {/*<ZipCode />*/}
                                <Name />
                                <Height />
                                <Interest />
                                <Profile />
                                <Password />
                                <Finish />
                            </StepWizard>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome;
