import Loader from "./loader";

export const NotificationItemLoader = () => {
  return (
      <div className="notification align-items-center mb-3">
            <div className="notificationDescription col-8">
                <p className="notification-title mb-2"> <Loader height="30" width="80%" /></p>
                <p> <Loader height="30" width="100%" /></p>
            </div>
            <div className="time col-3">
            <p className='pt-4'> <Loader height="30" width="100%" /></p>
            </div>
        </div>
  );
};