import { useContext } from "react";
import Image from "../../components/shared/Image";
import { MessagingContext } from "../../context/messaging.context";
import SpiralLoader from "../../components/loaders/SpiralLoader";
import Loader from "../../components/loaders/loader";
import { UserContext } from "../../context/user.context";
import { Link } from "react-router-dom";


export const CHAT_REQUEST_ACTIONS = {
  Accept: "Accept",
  Reject: "Reject",
  Remove: "Remove",
};

export const ChatRequestAction = ({ loading:isLoading, chatRequest }) => {
  console.log("request ", chatRequest);
  const { acceptChatRequest, loading } = useContext(MessagingContext);
  const {data} = useContext(UserContext);

  const sentFromMe = chatRequest && chatRequest.sender.firstName === data.user.firstName && chatRequest.sender.lastName === data.user.lastName

  return (
    <div
      className="card-body"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <div className="empty-card">
        {isLoading && <Loader width="230" height="40"/>}
        {!isLoading&&<p className="card-text">
          {
            sentFromMe
            ?
            <span>
              {`You have sent a chat request to Auntie ${chatRequest && chatRequest.receiver.firstName} to connect with their child ${chatRequest && chatRequest.receiverChild.firstName} on behalf of your child ${chatRequest && chatRequest.senderChild.firstName}`}
              <h1
                className="card-text"
                style={{
                  padding: "5px",
                }}
              >
                {"Start chatting when they accept your request"}
              </h1>
            </span>
            :
            <>
            {`Auntie ${chatRequest && chatRequest.sender.firstName} thinks ${chatRequest && chatRequest.receiverChild.firstName} and `} <Link className="remove-default-style" to={`/profile/${chatRequest && chatRequest.senderChild.userId}`}>{chatRequest && chatRequest.senderChild.firstName}</Link> {` will hit it off!`}
            
          </>
          }
        </p>}
        <h1
          className="card-text"
          style={{
            padding: "5px",
          }}
        >
          {isLoading ? <Loader width="200" height="20" />:!sentFromMe && "Accept to start chatting"}
        </h1>
        <div className="action-buttons text-center">
          {isLoading&&<Loader width="130" height="50" radius="20px" />}
          {!isLoading && !sentFromMe &&
          <button
            className="btn solid-btn"
            onClick={(e) =>
              acceptChatRequest({
                id: chatRequest.recordId,
                action: CHAT_REQUEST_ACTIONS.Accept,
              })
            }
          >
           {loading ? <SpiralLoader size="sm" /> : "Accept"}
          </button>
        }
        </div>
      </div>
    </div>
  );
};
