// import { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { AUTH_STORAGE_KEY } from '../constants';
import { USER_PROFILE_STORAGE_KEY } from '../constants';
import useLocalStorage from './useLocalStorage';


const useAuth = () => {
  // const history = useHistory();
  const { setUser, clearStorage, setItem } = useLocalStorage();

  const login = async (data) => {
    try {
      setUser(JSON.stringify(data))
      setItem(USER_PROFILE_STORAGE_KEY, data.user);
    } catch (e) {
      // console.log('ERROR: useAuth: ', e);
    }
  }

  const logout = () => {
    return new Promise(res => {
      clearStorage();

    })
    // history.push("/login");
  }

  return { login, logout }
}

export default useAuth;