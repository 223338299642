import React, { useContext, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
	LoginPageBackgroundIcon,
	GoogleIcon,
	TwitterIcon,
	FacebookIcon,
} from '../../components/Svgs';
import { LoginForm } from './Forms/Login.form';
import './Login.css';
import { UserContext } from '../../context/user.context';
import { GOOGLEAUTH } from '../../requests/endpoints';

import '../../assets/scss/main.scss';
import { isSafariBrowser } from '../../utils/browserDetect';

export const Login = () => {
	const isSafari = isSafariBrowser();
	const history = useHistory();
	const { data } = useContext(UserContext);

	useEffect(() => {
		if (data && data.user) {
			history.push('/dashboard');
		}
	}, [data, history]);

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12">
					<div className="row aunty-signup-login">
						<div className="col-md-4 col-lg-5 col-xl-4">
							<div className="promotional-left-panel">
								<div className="logo">
									<NavLink to="/login">
										<img
											src="assets/images/promo-logo.png"
											alt="promo-logo"
											className={`${
												isSafari
													? 'safari-img-rotate'
													: ''
											}`}
										/>
									</NavLink>
								</div>
								<div className="image-container text-center">
									<LoginPageBackgroundIcon></LoginPageBackgroundIcon>
								</div>
								<div className="text text-center">
									<h3>The Auntie Network</h3>
									<p>Find the perfect match</p>
								</div>
							</div>
						</div>
						<div className="col-md-12 col-lg-7 col-xl-7">
							<div className="col-md-8 col-lg-8 col-xl-7 mx-auto form-content-container text-center">
								<section className="mx-auto">
									<div className="heading-group">
										<h1>Login to your account</h1>
									</div>
									<LoginForm />
									{/* <div className="after-form-separator">
                    <span>Or</span>
                  </div> */}
									<div className="login-button-options">
										{/* <GoogleLogin /> */}

										{/* <FacebookLogin /> */}
										{/* <TwitterLogin /> */}
									</div>
									<div className="after-login-button-options-separator">
										<span />
									</div>
									<div className="links-wrapper">
										<p className="existing-user-login">
											Don’t have an account?{' '}
											<a
												onClick={() =>
													history.push('/register')
												}
												href="register"
											>
												Create Account
											</a>
										</p>
									</div>
								</section>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const GoogleLogin = () => {
	return (
		<li>
			<a href="##" className="btn google-btn w-100">
				<GoogleIcon></GoogleIcon>
				<span>Continue with Google</span>
			</a>
		</li>
	);
};

const FacebookLogin = () => {
	return (
		<li>
			<a
				href="##"
				className="btn btn-primary solid-btn facebook-btn py-3 w-100"
			>
				<FacebookIcon></FacebookIcon>
				<span>Continue with Facebook</span>
			</a>
		</li>
	);
};

const TwitterLogin = () => {
	return (
		<li>
			<a
				href="##"
				className="btn btn-primary solid-btn twitter-btn py-3 w-100"
			>
				<TwitterIcon></TwitterIcon>
				<span>Continue with Twitter</span>
			</a>
		</li>
	);
};
