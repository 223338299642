import React from 'react';
import Loader from './loader';
import { profileIsChild } from '../../utils';
import { TagList } from '../Profile';
import ProfileCardLoader from './ProfileCardLoader';
import ParentChatCardLoader from './ParentChatCardLoader';
import ImageGridLoader from './ImageGridLoader';
import { useHistory } from 'react-router-dom';

const SingleProfileLoader = ({ profile, currentUser }) => {
  const history = useHistory();
  const goBack = () => history.goBack();
  function BackButton(props) {
    return (
      <div className='w-100 back-to-network mt-2' onClick={props.goBack}>
        <span>&nbsp;</span>
      </div>
    );
  }
  return (
    <div className='row single-explore-profile m-0'>
      <BackButton goBack={goBack}></BackButton>
      <div className='col-md-3'>
        <ProfileCardLoader currentUser={currentUser} profile={profile} />
        {!profileIsChild(currentUser?.userType) && !profile && (
          <ParentChatCardLoader />
        )}
      </div>
      <div className='col-md-8 content-wrapper'>
        {/* {<TagList title='Hobbies'></TagList>} */}

        <p>Hobbies</p>

        <div className='d-flex gap-2'>
          <Loader height='40' width='90%' radius='20' />
          <Loader height='40' width='90%' radius='20' />
          <Loader height='40' width='90%' radius='20' />
          <br />
          <br />
          <br />
        </div>
        <div className=''>
          <p>Location</p>

          <Loader height='40' width='50%' radius='20' />
        </div>

        <p>Bio</p>
        <p>
          {' '}
          <Loader height='20' width='80%' />
          <Loader height='20' width='80%' />
          <Loader height='20' width='80%' />
          <Loader height='20' width='80%' />
          <Loader height='20' width='80%' />
        </p>

        <p>Pictures</p>
        {profileIsChild(currentUser?.userType) && profile && (
          <ImageGridLoader />
        )}
      </div>
    </div>
  );
};

export default SingleProfileLoader;
