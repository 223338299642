import { useContext, useEffect, useState } from 'react';
import { RecommendationItemLoader } from '../../components/loaders/RecommendationsLoader';
import { UserContext } from '../../context/user.context';
import Image from '../../components/shared/Image';
import RecommendationsModal from '../../components/Modals/RecommendationsModal'
import {sortRecommendations} from '../../utils'
import {
  GetMyRecommendations,
  GetParentRecommendations,
} from '../../requests/user';
import {
  capitalize,
  getAgeFromYear,
  getFullName,
  profileIsChild,
  profileIsParent,
} from '../../utils';
import { useHistory } from 'react-router-dom';
import {deleteRecommendation} from '../../requests/user'
import cogoToast from "cogo-toast";

const RecommendationItem =({ data, showRecommendee, viewProfile })=>{
  return (
    <div className="row justify-content-between recommended-profile">
      <div className="col-1">
        <Image
          alt='recommended-profile'
          className='round-image-48'
          src={
            data.recommendation.profileImage ||
            '../../assets/images/dafault.png'
          }
          fallback='assets/images/default.png'
        />
      </div>
      <div className="col-6">
        <p title={`${ data.recommendation.firstName} ${data.recommendation.lastName}`} onClick={(event)=>viewProfile(event, data)} className="profile-details">
          {getFullName(
            data.recommendation.firstName,
            data.recommendation.lastName
          )}
          {(data.recommendation.gender || data.recommendation.birthDate) && (
            <span>
              {capitalize(data.recommendation.gender)}{' '}
              {data.recommendation.gender && (
                <i className='fas fa-dot-circle' />
              )}
              {data.recommendation.birthDate &&
                `${getAgeFromYear(data.recommendation.birthDate)}y`}
            </span>
          )}
        </p>
      </div>
      <div className="col-5">
        {showRecommendee && data.sender && (
          <p className='recommendee text-center'>
            {getFullName(data.sender.firstName, data.sender.lastName)}
          </p>
        )}
      </div>
    </div>
  );
}

function Recommendations() {
  const { data, logout } = useContext(UserContext);
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const user = data && data.user;


  //Check if users access token has expired with a 401 error code
  const checkAuthorizedUser = (error) => {
    if (error?.message === 'Request failed with status code 401') {
      logout(history);
    }
  };

  const getChildRecommendations = (id) => {
    GetMyRecommendations(id)
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          console.log(res.data)
          // throw new Error('Request failed with status code 401');
          setRecommendations(res.data.sort(sortRecommendations));
        }
      })
      .catch((err) => {
        console.log(err.message);

        checkAuthorizedUser(err);

        setLoading(false);
      });
  };

  const getParentRecommendations = (id) => {
    GetParentRecommendations(id)
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          console.log(res.data)
          setRecommendations(res.data.sort(sortRecommendations));
        }
      })
      .catch((err) => {
        checkAuthorizedUser(err);
        // console.log("err ", err);
        setLoading(false);
      });
  };

  const viewProfile = async (event, recommendation) => {
    event.stopPropagation();
    if(profileIsChild(user.userType)){
      history.push(`/profile/${recommendation.recommendation.userId}`);
    } else{
      history.push(`/profile/${recommendation.recommendation.userId}/${recommendation.sender.userId}`);
    }
  };

  const removeRecommendation = (recordId) =>{
    deleteRecommendation(recordId)
    .then(res=>{
      if(res.statusCode === 200){
        if (profileIsChild(user.userType)) {
          getChildRecommendations(user.id);
        } else {
          getParentRecommendations(user.id);
        }
        cogoToast.success('Recommendation deleted')
      }
    })
    .catch(err=>{
      console.log(err)
    })
}

  useEffect(() => {
    if (loading || recommendations.length) return;
    
    setLoading(true);
    if (profileIsChild(user.userType)) {
      getChildRecommendations(user.id);
    } else {
      getParentRecommendations(user.id);
    }
  }, []);

  return (
    <>
      <div className='col-lg-5 col-md-12 col-sm-12 col-xs-12'>
        <div className='card card-light card-min-height-395'>
          <div className='card-body'>
            <div className='row justify-content-between card-title-container mb-4'>
              <div className='col-6'>
                <h5 className='card-title with-bg float-start'>
                  Recommendations
                </h5>
              </div>
              <div className="col-4">
                <span className={`${recommendations.length === 0 ? 'd-none' : ''} float-end`}>
                    <a 
                    data-bs-toggle="modal"
                    data-bs-target="#recommendations"
                    href="##">All <i class="fas fa-chevron-right"></i></a>
                </span>
              </div>
            </div>
            {loading ? (
              <>
                <RecommendationItemLoader />
                <RecommendationItemLoader />
                <RecommendationItemLoader />
                {/* <RecommendationItemLoader /> */}
              </>
            ) : (
              ''
            )}
            {!loading &&
              recommendations
                .slice(0, 4)
                .map((recommendation) => (
                  <div>
                  <RecommendationItem
                    viewProfile = {viewProfile}
                    data={recommendation}
                    showRecommendee={profileIsParent(user.userType)}
                  ></RecommendationItem>
                  </div>
                ))}
            {!loading && recommendations.length === 0 && (
              <div className='empty-card text-center'>
                <div className='icon-container mx-auto'>
                  <svg
                    width={31}
                    height={12}
                    viewBox='0 0 31 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx={25} cy={6} r={6} fill='#E80F6D' />
                    <circle
                      cx={6}
                      cy={6}
                      r={5}
                      stroke='#E80F6D'
                      strokeWidth={2}
                    />
                  </svg>
                </div>
                <p className='card-text'>
                  You haven’t had any recommendations yet. Recommendations from
                  family shows up here
                </p>
              </div>
            )}
          </div>
          <RecommendationsModal
            recommendations = {recommendations}
            isChild={profileIsChild(user.userType)}
            removeRecommendation={removeRecommendation}
            loading={loading}
          />
        </div>
      </div>
      
    </>
  );
}

export default Recommendations;
