import { useState, memo, useEffect } from 'react';
import { capitalize, getAgeFromYear } from '../../utils';
import Image from '../shared/Image';
import SpiralLoader from '../loaders/SpiralLoader';
import { useParams } from 'react-router-dom';

const UserSelector = memo(({ onSelectUser, user, check, senderId }) => {
  return (
    <div className='col-md-6'>
      <div className='row justify-content-between recommend-popup'>
        <div className='col-3'>
          <Image
            src={user.profileImage}
            alt='child display avatar'
            className='round-image-56'
            fallback='/assets/images/woman-1.jpeg'
          />
        </div>
        <div className='col-6 pt-1'>
          <p>
            {user.firstName}
            <span>
              {capitalize(user.gender)} <i className='fas fa-dot-circle' />{' '}
              {`${getAgeFromYear('01/01/2004')}y`}
            </span>
          </p>
        </div>
        <div className='col-3 pt-3 text-center'>
          <label className='custom-radio-btn'>
            <input
              type='radio'
              name='recommend'
              onClick={senderId ? onSelectUser(user.id) : (_) => onSelectUser(user.id)}
            />
            <span className='check' />
          </label>
        </div>
      </div>
    </div>
  );
});

const RecommendUsersModal = ({
  users,
  loading,
  isloading,
  onSelect,
  profile,
  senderId
}) => {
  const [childId, setChildId] = useState('');
  const [selectedChild, setSelectedChild] = useState({});

  useEffect(() => {
    if (!childId) return false;
    setSelectedChild(users.find((user) => user.id === childId));
  }, [childId]);

  return (
    <div
      className='modal fade'
      id='recommendPopUp'
      tabIndex={-1}
      aria-labelledby='editProfileFormLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>
              {childId
                ? `You are sending a request to ${profile?.firstName}'s auntie on behalf of ${selectedChild?.firstName} 😊`
                : `Select a child to continue`}
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            />
          </div>
          <div className='modal-body'>
            <div className='row'>
              {users &&
                users.map((user, i) => {
                  if(user.id === senderId){
                    return <UserSelector senderId={senderId} check={true} key={i} onSelectUser={setChildId} user={user} />
                  }
                  return <UserSelector senderId={null} check={false} key={i} onSelectUser={setChildId} user={user} />
                }
                )}
            </div>
          </div>
          <div className='modal-footer action-buttons'>
            <button
              className='btn solid-btn d-flex align-items-center justify-content-center w-25'
              onClick={(e) => !loading && onSelect(childId, selectedChild)}
              // data-bs-dismiss={chatRequested ? 'modal' : ''}
              disabled={childId ? false : true}
            >
              {isloading ? <SpiralLoader size='sm' /> : 'Continue'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendUsersModal;
