import { useContext, useState, useEffect } from 'react';
import cogoToast from 'cogo-toast';
import {
	UpdateUser,
	UploadProfileImage,
	UploadProfileImages,
	GetLocation,
} from '../../requests/user';
import { UserContext } from '../../context/user.context';
import Image from '../shared/Image';
import { profileIsChild } from '../../utils/index';
import SpiralLoader from '../loaders/SpiralLoader';
import '../../assets/scss/main.scss';
import { occupations, states } from '../../utils/index';
import Classes from './EditProfile.module.css';
import ImagePreview from './ImagePreview';
import ImageModalGrid from './ImageModalGrid';
import DatePicker from 'react-datepicker';

function UploadFileIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={Classes.svg}
		>
			<path
				d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
				stroke="#E80F6D"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.1673 6.66667L10.0007 2.5L5.83398 6.66667"
				stroke="#E80F6D"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 2.5V12.5"
				stroke="#E80F6D"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

function ArrowRightOutlined() {
	return (
		<svg
			className="float-end"
			width={8}
			height={14}
			viewBox="0 0 8 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.77734 1.8147L6.93525 6.9726L1.77734 12.1305"
				stroke="#E80F6D"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

const EditProfileModal = () => {
	const { profile, getProfileImages } = useContext(UserContext);

	const { UpdateUserProfile, images: myImages } = useContext(UserContext);

	const [selects, setSelects] = useState({});

	const [check, setCheck] = useState(true);

	const [locations, setLocations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [avatar, setAvatar] = useState({ preview: null, raw: null });

	const formatDate = (date) => {
		const dateValue = new Date(date).toISOString().split('T')[0];
		return dateValue;
	};

	useEffect(() => {
		(async () => {
			const data = await GetLocation();
			// setLocations([]);
			setLocations(data.data);
		})();
	}, []);

	const [avatars, setAvatars] = useState({
		first: {
			preview: null,
			raw: false,
			loading: false,
		},
		second: {
			preview: null,
			raw: false,
			loading: false,
		},
		third: {
			preview: null,
			raw: false,
			loading: false,
		},
		fourth: {
			preview: null,
			raw: false,
			loading: false,
		},
		fifth: {
			preview: null,
			raw: false,
			loading: false,
		},
	});

	console.log(profile);
	const [profileDetails, setProfileDetails] = useState({
		...profile,
	});

	/*
  const extractLocationState = (name) => {
    return name?.split(',')[1]?.trim();
  };

  */

	/*
  //This logic helps simulate the location number type upon selection and the location text type upon load
  useEffect(() => {
    setSelects(
      locations?.find(
        (location) =>
          profileDetails?.location?.includes(location.state_name) ||
          location.id === Number(profileDetails.location)
      )
    );
  }, [locations, profileDetails]);
  */

	//Onchange for all form elements
	const handleChange = ({ target }) => {
		setProfileDetails({ ...profileDetails, [target.name]: target.value });
	};

	//Handle change for image uploads
	const handleAvatarChange = (e) => {
		const file = e.target.files[0];
		if (!file) return;
		if (file.size > 12000000) {
			cogoToast.error('Please upload an image less than 10MB');
			return;
		}

		avatar.raw && URL.revokeObjectURL(avatar.raw);
		let preview = URL.createObjectURL(file);
		setAvatar({
			preview,
			raw: file,
		});
	};

	//handle change for all image fields
	const handleAvatarChanges = ({ target }) => {
		const file = target.files[0];
		if (file.size > 12000000) {
			cogoToast.error('Please upload an image less than 10MB');
			return;
		}
		setAvatars({
			...avatars,
			[target.name]: {
				preview: URL.createObjectURL(file),
				raw: file,
				loading: true,
			},
		});
	};

	const promiseArr = [];

	useEffect(() => {
		console.log(profileDetails);
		const Objectvalues = Object.values(avatars);

		const canUpload = Objectvalues.every((item) => item.raw === false);

		if (!canUpload) {
			multipleImageUpload();
		}
	}, [avatars]);

	const clearFields = () => {
		setAvatars({
			first: {
				...avatars.first,
				loading: false,
				raw: false,
			},
			second: {
				...avatars.second,
				loading: false,
				raw: false,
			},
			third: {
				...avatars.third,
				loading: false,
				raw: false,
			},
			fourth: {
				...avatars.fourth,
				loading: false,
				raw: false,
			},
			fifth: {
				...avatars.fifth,
				loading: false,
				raw: false,
			},
		});
	};

	const multipleImageUpload = async () => {
		// e.preventDefault();
		try {
			const form = new FormData();

			avatars.first.raw &&
				form.append('profileImages', avatars.first.raw);

			avatars.second.raw &&
				form.append('profileImages', avatars.second.raw);

			avatars.third.raw &&
				form.append('profileImages', avatars.third.raw);

			avatars.fourth.raw &&
				form.append('profileImages', avatars.fourth.raw);

			avatars.fifth.raw &&
				form.append('profileImages', avatars.fifth.raw);

			form.append('userId', profileDetails.id);

			setAvatars({
				first: {
					...avatars.first,
					raw: false,
				},
				second: {
					...avatars.second,
					raw: false,
				},
				third: {
					...avatars.third,
					raw: false,
				},
				fourth: {
					...avatars.fourth,
					raw: false,
				},
				fifth: {
					...avatars.fifth,
					raw: false,
				},
			});

			const res = await UploadProfileImages(form);

			// console.log(form.getAll());

			getProfileImages(profile.id);

			clearFields();

			const { hide } = cogoToast.success(
				'Image uploaded successfully 👌',
				{
					position: 'top-center',
					hideAfter: 3000,
				}
			);

			setTimeout(hide, 2000);
		} catch (err) {
			cogoToast.error(err.message);

			clearFields();
		}
	};

	const images = [
		{
			position: 'first',
		},

		{
			position: 'second',
		},

		{
			position: 'third',
		},

		{ position: 'fourth' },
	];

	//image upload function
	const imageUpload = async () => {
		try {
			if (avatar.raw) {
				const form = new FormData();
				form.append('profileImage', avatar.raw);
				form.append('userId', profileDetails.id);
				promiseArr.push(UploadProfileImage(form));
				await UploadProfileImage(form);
			}
		} catch (err) {
			throw err;
		}
	};

	//form data submit
	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			console.log(profileDetails.location);

			const data = {
				id: profileDetails.id,
				bio: profileDetails.bio,
				profession: profileDetails.profession,
				hobbies: profileDetails.hobbies && [...profileDetails.hobbies],
				//location: profileDetails.location
			};

			console.log(data);

			//only add location field to payload if there's a location data
			/*
      const locationData =
        profileDetails.location && selects
          ? (data.location = selects.id)
          : data;
      */

			await imageUpload();

			promiseArr.push(UpdateUser({ ...data }));

			if (promiseArr.length === 0) return;
			const res = await Promise.all(promiseArr);
			setLoading(false);

			const { hide } = cogoToast.success(
				'Profile Updated Successfully 👌',
				{
					position: 'top-center',
					hideAfter: 3000,
				}
			);
			UpdateUserProfile();

			setTimeout(hide, 2000);
		} catch (err) {
			cogoToast.error(err.message);

			setLoading(false);
		}
	};

	return (
		<div
			className="modal fade user-profile-modal edit-profile-fm"
			id="editProfileForm"
			tabIndex={-1}
			aria-labelledby="editProfileFormLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Edit Profile</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						/>
					</div>
					<div className="modal-body">
						<div className="profileImg">
							<Image
								alt="profile"
								className="round-image-120 mx-auto"
								src={
									avatar.preview ||
									profileDetails?.profileImage ||
									'assets/images/profile-avatar.png'
								}
								fallback="assets/images/default.png"
							/>
							<input
								type="file"
								id="upload-button"
								style={{ display: 'none' }}
								onChange={handleAvatarChange}
							/>
							<label className="d-block" htmlFor="upload-button">
								<UploadFileIcon></UploadFileIcon>
							</label>
						</div>
						<div className="profile-update-form">
							<form>
								<div className="row g-2 mb-3">
									<div className="col-md">
										<div className="form-floating">
											<input
												type="text"
												className="form-control"
												id="fullname"
												placeholder="Fullname"
												disabled="disabled"
												onChange={handleChange}
												value={
													profileDetails.firstName +
													' ' +
													profileDetails.lastName
												}
												readOnly
											/>
											<label htmlFor="fullname">
												Fullname
											</label>
										</div>
									</div>
									<div className="col-md">
										<div className="form-floating">
											<input
												type="email"
												className="form-control"
												id="email"
												placeholder="name@example.com"
												onChange={handleChange}
												value={profileDetails.email}
												readOnly
											/>
											<label htmlFor="email">
												Email address
											</label>
										</div>
									</div>
								</div>
								<div className="row g-2 mb-3">
									<div className="col-md">
										<div className="form-floating">
											<input
												type="date"
												className="form-control"
												id="birthDate"
												name="birthDate"
												// onChange={handleChange}
												value={
													formatDate(
														profileDetails.birthDate
													) || ''
												}
												placeholder="Date of Birth"
												readOnly
											/>
											<label htmlFor="birthDate">
												Date of Birth
											</label>
										</div>
									</div>
									<div className="col-md">
										<div className="form-floating">
											<select
												className="form-select"
												id="gender"
												name="gender"
												onChange={handleChange}
												value={profileDetails.gender}
												disabled
											>
												<option>
													Select Gender...
												</option>
												<option value="Female">
													Female
												</option>
												<option value="Male">
													Male
												</option>
											</select>
											<label htmlFor="gender">
												Gender
											</label>
										</div>
									</div>
									<div className="col-md">
										<div className="form-floating">
											<select
												className="form-select"
												id="profession"
												name="profession"
												onChange={handleChange}
												value={
													profileDetails.profession
												}
											>
												<option>
													Select Profession...
												</option>

												{occupations.map((occ, ind) => (
													<option
														key={ind}
														value={occ}
													>
														{occ}
													</option>
												))}
											</select>
											<label htmlFor="Profession">
												Profession
											</label>
										</div>
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-12">
										<div className="form-floating">
											<select
												className="form-select"
												id="location"
												name="location"
												onChange={handleChange}
												value={profileDetails.location}
											>
												<option>
													Select Location...
												</option>

												{locations.map((loc, index) => (
													<option
														key={index}
														value={loc}
													>
														{loc}
													</option>
												))}
											</select>
											<label htmlFor="location">
												Location
											</label>
										</div>
									</div>
								</div>

								<div className="row mb-3">
									<div className="col-12">
										<div className="form-floating">
											<textarea
												className="form-control"
												placeholder="Leave a comment here"
												id="Bio"
												style={{ height: 150 }}
												value={profileDetails.bio}
												name="bio"
												onChange={handleChange}
											></textarea>
											<label htmlFor="Bio">Bio</label>
										</div>
									</div>
								</div>

								<div className="row edit-preferences-trigger">
									<div className="col-12">
										<a
											href="##"
											className="d-block"
											data-bs-target="#resetPassword"
											data-bs-toggle="modal"
											data-bs-dismiss="modal"
										>
											<span>Reset Password</span>
											<ArrowRightOutlined></ArrowRightOutlined>
										</a>
									</div>
								</div>

								{profileIsChild(profile.userType) && (
									<div className="row edit-preferences-trigger">
										<div className="col-12">
											<a
												href="##"
												className="d-block"
												data-bs-target="#editProfilePreferences"
												data-bs-toggle="modal"
												data-bs-dismiss="modal"
											>
												<span>Edit Preferences</span>
												<ArrowRightOutlined></ArrowRightOutlined>
											</a>
										</div>
									</div>
								)}
							</form>
							{profileIsChild(profile.userType) && (
								<div className="col-12 profilePictures mt-4">
									<p className="mb-3"> Upload Pictures</p>
									<div className="row">
										{images.map((item, ind) => {
											const { position } = item;

											return (
												<ImagePreview
													avatars={avatars}
													handleAvatarChanges={
														handleAvatarChanges
													}
													setAvatars={setAvatars}
													UploadFileIcon={
														UploadFileIcon
													}
													key={ind}
													position={position}
												/>
											);
										})}
									</div>
									<div className="col-12">
										<p>
											Impress people with your pictures.
											Upload up to ten (10) cool pictures.
											Max file is 10MB per photo
										</p>
									</div>

									{/* <div className='col-12 action-buttons mt-3'>
                    <button
                      className={`btn transparent-gray d-flex justify-content-center align-items-center`}
                      onClick={() => setShowUploadZone(!showUploadZones)}
                    >
                      {showUploadZones ? 'Hide Pictures' : 'Add pictures'}
                    </button>
                  </div> */}

									<div className="col-12 profilePictures mt-4">
										<p className="mb-3">Pictures</p>
										<div className="row">
											{myImages?.map((img) => (
												<ImageModalGrid
													{...img}
													key={img.fileName}
													profile={profile}
												/>
											))}
										</div>
									</div>
								</div>
							)}{' '}
						</div>
					</div>
					<div className="modal-footer action-buttons">
						<button
							className="btn transparent-gray w-25 d-flex justify-content-center align-items-center"
							data-bs-dismiss="modal"
						>
							Cancel
						</button>
						<button
							className="btn solid-btn w-25 d-flex justify-content-center align-items-center"
							disabled={loading}
							onClick={handleSubmit}
						>
							{loading ? <SpiralLoader size="sm" /> : 'Save'}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditProfileModal;
