const useChat = (userId, chat, currentChatRequest) => {
  if(!userId) return [];

  //console.log("userID----- "+ userId)
  
  let author = {},
    participant = {};
    //console.log("chat log----- "+ JSON.stringify(chat))
  if (chat) {
    //console.log("from usechat hook chat log----- "+ JSON.stringify(chat))
    if (chat.author[0] === userId) {
      author.id = chat.author[0];
      author.name = chat.author[1];
      author.profileImage = chat.authorProfileImage;

      participant.id = chat.participant[0];
      participant.name = chat.participant[1];
      participant.profileImage = chat.participantProfileImage;
    } else {
      author.id = chat.participant[0];
      author.name = chat.participant[1];
      author.profileImage = chat.participantProfileImage;

      participant.id = chat.author[0];
      participant.name = chat.author[1];
      participant.profileImage = chat.authorProfileImage;
    }
    if(currentChatRequest){
      if(currentChatRequest[0].sender.userId === userId){
        participant.child = currentChatRequest[0].receiverChild
        participant.matchStatus= currentChatRequest[0].matchStatus
        participant.receiver = currentChatRequest[0].receiver
        author.child = currentChatRequest[0].senderChild
      }else{
        participant.child =currentChatRequest[0].senderChild
        participant.matchStatus= currentChatRequest[0].matchStatus
        participant.receiver = currentChatRequest[0].sender
        author.child = currentChatRequest[0].receiverChild
      }
    }
  }

  return [author, participant];
};

export default useChat;
