import React, { useContext, useState } from 'react';
import { VALIDATE_STEP } from '.';
import { OnboardingContext } from '../../context';
import cogoToast from 'cogo-toast';

const Profile = ({ nextStep, previousStep, currentStep }) => {
	const { data, setData } = useContext(OnboardingContext);
	const [bio, setBio] = useState('');
	const [avatar, setAvatar] = useState({ preview: null, raw: null });

	const toNextStep = () => {
		let _data = { ...data, bio };
		if (VALIDATE_STEP(_data, currentStep - 1)) {
			setData({ ..._data, step: 9 });
			nextStep();
		}
	};

	const handleChange = (e) => {
		const file = e.target.files[0];
		if (!file) return;
		if (file.size > 12000000) {
			cogoToast.error('Please upload an image less than 10MB');
			return;
		}
		avatar.raw && URL.revokeObjectURL(avatar.raw);
		let preview = URL.createObjectURL(file);
		setAvatar({
			preview,
			raw: e.target.files[0],
		});
		setData({ ...data, avatar_preview: file });
	};
	
	return (
		<>
			<section className="col-md-9 col-lg-5 col-xl-4 mx-auto">
				<div className="heading-group">
					<span className="animate__animated animate__slideInDown animate__delay-3s step-8" />
					<h1>Your profile</h1>
				</div>
				<div className="row input-container">
					<div className="form-group text-center mt-4">
						<div className="upload-image-preview mx-auto">
							{!avatar.preview ? (
								<img
									src={'assets/images/avatar.png'}
									alt="Avatar preview"
								/>
							) : (
								<img
									className="preview"
									src={avatar.preview}
									alt="Avatar Preview"
								/>
							)}
						</div>
						<div className="action-buttons mb-4">
							<div className="row justify-content-center">
								<div className="col-auto">
									<button
										type="button"
										className="btn transparent-btn"
									>
										<label
											className=""
											htmlFor="upload-button"
										>
											{!avatar.preview
												? 'Upload Avatar'
												: 'Change Avatar'}
										</label>
									</button>
								</div>
							</div>
						</div>

						<input
							type="file"
							id="upload-button"
							style={{ display: 'none' }}
							onChange={handleChange}
						/>

						<label htmlFor="bio" className="form-label">
							Bio
						</label>
						<textarea
							onChange={({ target: { value } }) => setBio(value)}
							className="form-control"
							id="bio"
							rows={4}
							defaultValue={''}
						/>
					</div>
				</div>
				<div className="action-buttons">
					<div className="row">
						<div className="col">
							<button
								type="button"
								className="btn fa-previous"
								onClick={previousStep}
							>
								{' '}
							</button>
							{Boolean(bio) || Boolean(avatar.preview) ? (
								<button
									type="button"
									className="btn fa-next float-end"
									onClick={toNextStep}
								></button>
							) : (
								<button
									type="button"
									className="btn fa-next float-end solid-btn"
									onClick={toNextStep}
								>
									Skip
								</button>
							)}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Profile;
