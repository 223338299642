import React, { useContext, useEffect, useState } from 'react';
import { VALIDATE_STEP } from '.';
import { OnboardingContext } from '../../context';
import { interests } from '../../utils/data';

//export const INTERESTS = ["Astronomy", "Baseball", "Blogging", "Bowling", "Cross Puzzle", "Coffee", "Camping", "Dinner Out", "Dancing", "Exploring", "Football", "Fencing", "Fishing", "Karate"]

const Interest = ({ nextStep, previousStep, currentStep }) => {
	const [hobbies, setHobbies] = useState([]);
	const { data, setData } = useContext(OnboardingContext);

	const select = (el) => {
		if (hobbies.includes(el)) {
			setHobbies([...hobbies].filter((hobby) => hobby !== el));
		} else {
			setHobbies([...hobbies, el]);
		}
	};

	const toNextStep = () => {
		let _data = { ...data, hobbies: hobbies.length ? hobbies : '' };
		if (VALIDATE_STEP(_data, currentStep - 1)) {
			setData({ ..._data, step: 8 });
			nextStep();
		}
	};

	return (
		<>
			<section className="col-md-8 col-lg-9 col-xl-9 mx-auto">
				<div className="heading-group text-center">
					<span className="animate__animated animate__slideInDown animate__delay-3s step-7" />
					<h1>What are your interests?</h1>
				</div>
				<div className="row input-container text-center">
					<div className="form-group">
						{interests.map((item, i) => (
							<button
								type="button"
								onClick={() => select(item)}
								className={`tc-tag ${
									hobbies.includes(item) ? 'tc-selected' : ''
								}`}
								data-tag="0"
							>
								{item}
							</button>
						))}
					</div>
				</div>

				<div className="action-buttons">
					<div className="row">
						<div className="col-md-4 col-lg-2 col-xl-2 mx-auto">
							<button
								type="button"
								className="btn fa-previous"
								onClick={previousStep}
							>
								{' '}
							</button>
							<button
								type="button"
								className="btn fa-next float-end"
								onClick={toNextStep}
							></button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Interest;
