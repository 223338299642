import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/user.context';
import { OnboardingProvider } from './context';
import { MessagingProvider } from './context/messaging.context';

// replace console.* for disable log on production
if (
	process.env.REACT_APP_ENV === 'production' ||
	process.env.REACT_APP_ENV === 'qa'
) {
	console.log = () => {};
	console.error = () => {};
	console.debug = () => {};
}

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<UserProvider>
				<OnboardingProvider>
					<MessagingProvider>
						<App />
					</MessagingProvider>
				</OnboardingProvider>
			</UserProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals();
