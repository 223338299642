export const MESSAGING_ACTIONS = {
  NEW_MESSAGE: "NEW_MESSAGE",
  SET_MESSAGES: "SET_MESSAGES",
  UPDATE_MESSAGE_ID: "UPDATE_MESSAGE_ID",
  UPDATE_MESSAGE_BODY: "UPDATE_MESSAGE_BODY",
  SET_CHATS: "SET_CHATS",
  SET_USER_ID: "SET_USER_ID",
  SET_ACTIVE_CHAT: "SET_ACTIVE_CHAT",
  DELETE_MESSAGE: "DELETE_MESSAGE",
  SET_CHAT_REQUESTS: "SET_CHAT_REQUESTS",
  UPDATE_CHAT_REQUEST: "UPDATE_CHAT_REQUEST",
  SET_ACTIVE_CHAT_REQUEST: "SET_ACTIVE_CHAT_REQUEST",
};
