import { Route } from 'react-router-dom';

// pages import

import { routes } from './routes/routes';

// styles
import 'react-datepicker/dist/react-datepicker.css';

import Authenticated from './routes/components/Authenticated';
import UnAuthenticated from './routes/components/UnAuthenticated';

function App() {
	return (
		<div>
			{routes.map((route) => {
				if (route.secured)
					return (
						<Route
							key={route.path}
							exact={true}
							component={(props) => (
								<Authenticated
									Component={route.component}
									page={route.basePath}
									{...props}
								/>
							)}
							path={route.path}
						/>
					);
				return (
					<Route
						key={route.path}
						component={(props) => (
							<UnAuthenticated
								page={route.basePath}
								Component={route.component}
								{...props}
							/>
						)}
						path={route.path}
					/>
				);
			})}
		</div>
	);
}

export default App;
