import React from "react";

function Done() {
  return (
    <div className="App">
      <h1>Payment Done!!!</h1>
    </div>
  );
}

export default Done;
