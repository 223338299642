import React from 'react';
import './SpiralLoader.css';
function SpiralLoader({ size }) {
  return (
    <div>
      <div className={`loader${size} text-center`}></div>
    </div>
  );
}

SpiralLoader.defaultProps = {
  size: '',
};

export default SpiralLoader;
