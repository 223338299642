import React, { useContext, useState } from 'react';
import { VALIDATE_STEP } from '.';
import { OnboardingContext } from '../../context';
import { capitalize } from '../../utils';

const Name = ({ nextStep, previousStep, currentStep }) => {
	const { data, setData } = useContext(OnboardingContext);
	const [name, setName] = useState({
		firstName: data.firstName,
		lastName: data.lastName,
	});

	const toNextStep = () => {
		let _data = { ...data, ...name };
		if (VALIDATE_STEP(_data, currentStep - 1)) {
			// console.log('set name ', _data);
			setData({ ..._data, step: 6 });
			nextStep();
		}
	};
	return (
		<>
			<section className="col-md-9 col-lg-5 col-xl-4 mx-auto">
				<div className="heading-group">
					<span className="animate__animated animate__slideInDown animate__delay-3s step-5" />
					<h1>What’s your name?</h1>
				</div>
				<div className="row input-container">
					<div className="form-group mb-4">
						<input
							value={name.firstName}
							onChange={({ target: { value } }) =>
								setName({
									...name,
									firstName: capitalize(value),
								})
							}
							type="text"
							className="form-control"
							placeholder="First name"
						/>
					</div>
					<div className="form-group">
						<input
							value={name.lastName}
							onChange={({ target: { value } }) =>
								setName({
									...name,
									lastName: capitalize(value),
								})
							}
							type="text"
							className="form-control"
							placeholder="Last name"
						/>
					</div>
				</div>
				<div className="action-buttons">
					<div className="row">
						<div className="col">
							<button
								type="button"
								className="btn fa-previous"
								onClick={previousStep}
							>
								{' '}
							</button>
							<button
								type="button"
								className="btn fa-next float-end"
								onClick={toNextStep}
							></button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Name;
