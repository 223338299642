import React, { useState, useContext, useEffect } from 'react';
import { VALIDATE_STEP } from '.';
import { OnboardingContext } from '../../context';

const Height = ({ nextStep, previousStep, currentStep }) => {
	const { data, setData } = useContext(OnboardingContext);
	const [height, setHeight] = useState(data.height);

	const inchesToFeet = (value)=>{
		const feet = ((value/12).toFixed(1)).split('.')
		
		return `${feet[0]}' ${feet[1]}"`
	  }

	useEffect(() => {
		// height slider
		const noUiSlider = window.noUiSlider;
		const heightRange = document.querySelector('#height-range');
		var labels = {
			40: ` 3'3" `,
			55: `4'6"`,
			67: `5'6"`,
			77: `6'4"`,
			85: `7'1"`,
			90: `7'5"`,
			100: `8'3"`,
		};
		noUiSlider.create(heightRange, {
			start: height ? height : 55,
			connect: [true, false],
			tooltips: {
				to: function (value) {
					return value > 100 ? '100+' : inchesToFeet(parseInt(value))
				},
			},
			range: {
				min: 40,
				'16%': 55,
				'32%': 67,
				'48%': 77,
				'64%': 85,
				'80%': 90,
				max: 100,
			},
			pips: {
				mode: 'steps',
				filter: function (value, type) {
					return type === 0 ? -1 : 1;
				},
				format: {
					to: function (value, key) {
						return labels[value];
					},
				},
			},
		});
	}, []);
	const getSliderValue = () => {
		let slider = document.querySelector('#height-range');
		let sliderValue = slider?.noUiSlider?.get();
		return sliderValue;
	};
	const toNextStep = () => {
		let _data = { ...data, height: getSliderValue() };
		if (VALIDATE_STEP(_data, currentStep - 1)) {
			setData({ ..._data, step: 7 });
			nextStep();
		}
	};
	return (
		<>
			<section className="col-md-9 col-lg-5 col-xl-4 mx-auto">
				<div className="heading-group">
					<span className="animate__animated animate__slideInDown animate__delay-3s step-6" />
					<h1>What’s your height?</h1>
				</div>
				<div className="row input-container">
					<div className="form-group">
						<div id="height-range" />
					</div>
				</div>
				<div className="action-buttons">
					<div className="row">
						<div className="col">
							<button
								type="button"
								className="btn fa-previous"
								onClick={previousStep}
							>
								{' '}
							</button>
							<button
								type="button"
								className="btn fa-next float-end"
								onClick={toNextStep}
							></button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Height;
