import { useContext, useEffect, useState } from 'react';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { UserContext } from '../context/user.context';
import { Dashboard } from '../pages/Dashboard/Index';
import { Login } from '../pages/Login';
import Welcome from '../pages/Onboarding';
import Start from '../pages/Onboarding/Start';
import Profile from '../pages/Profile';
import Notifications from '../pages/Notifications/index.js';
import { Register } from '../pages/Register';
import { Verify } from '../pages/Register/Verify';
import Networks from '../pages/Networks/Index';
import useLocalStorage from '../hooks/useLocalStorage';
import SingleProfile from '../pages/Profile/SingleProfile';
import Chat from '../pages/Chat';
import ForgetPassword from '../pages/Authentication/ForgetPassword';
import ResetPassword from '../pages/Authentication/ResetPassword';
import Subscriptions from '../pages/Subscriptions';
import Payment from '../pages/Subscriptions/payment';
import Done from '../pages/Subscriptions/done';

const Redirect = () => {
	const { data } = useContext(UserContext);
	const { getUser } = useLocalStorage();
	// const localUser = getUser();

	const location = useLocation();
	const routeHistory = useHistory();

	const [localUser, setLocalUser] = useState(getUser());

	//check local storage each time path changes
	useEffect(() => {
		const getFromStorage = getUser();

		if (getFromStorage) {
			setLocalUser(getFromStorage);
		} else {
			setLocalUser('');
		}
	}, [location.pathname]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		const currentRoute = routes.find(
			(route) =>
				route.path === routeHistory.location.pathname && route.secured
		);
		const isSecuredRoute = currentRoute !== undefined;

		if (isSecuredRoute && data && localUser !== '') {
			console.log(
				'secured route ',
				routeHistory.location.pathname,
				currentRoute,
				isSecuredRoute
			);
			routeHistory.push(routeHistory.location.pathname);
		} else if (location.pathname === '/' && localUser === '') {
			routeHistory.push('/login');
		} else if (!isSecuredRoute) {
			routeHistory.push(routeHistory.location.pathname);
		} else if (location.pathname === '/login') {
			routeHistory.push(routeHistory.location.pathname);
		} else if (localUser === '') {
			routeHistory.push('/login');
		}
		// } else {
		//   routeHistory.push(history.location.pathname);
		// }
	}, [data, routeHistory, localUser]);

	return <div />;
};

export const routes = [
	{
		path: '/',
		basePath: '',
		component: Redirect,
		secured: false,
	},
	{ path: '/login', basePath: 'login', component: Login, secured: false },
	{ path: '/verify', basePath: 'verify', component: Verify, secured: false },
	{
		path: '/profile',
		basePath: 'profile',
		component: Profile,
		secured: true,
		class: 'dashboard-page',
	},
	{
		path: '/chat',
		basePath: 'chat',
		component: Chat,
		secured: true,
		class: 'dashboard-page',
	},
	{
		path: '/networks',
		basePath: 'networks',
		component: Networks,
		secured: true,
		class: 'dashboard-page',
	},
	{
		path: '/profile/:profileId',
		basePath: 'networks',
		component: SingleProfile,
		secured: true,
		class: 'dashboard-page',
	},
	{
		path: '/profile/:profileId/:senderId',
		basePath: 'networks',
		component: SingleProfile,
		secured: true,
		class: 'dashboard-page',
	},
	{
		path: '/register',
		basePath: 'register',
		component: Register,
		secured: false,
	},
	{
		path: '/forgot-password',
		basePath: 'forgot-password',
		component: ForgetPassword,
		secured: false,
	},
	{
		path: '/auth/reset-password/:userId/:token',
		basePath: 'reset-password',
		component: ResetPassword,
		secured: false,
	},
	{
		path: '/onboarding',
		basePath: 'onboarding',
		component: Welcome,
		secured: false,
	},
	{
		path: '/dashboard',
		basePath: 'dashboard',
		component: Dashboard,
		secured: true,
		class: 'dashboard-page',
	},

	{
		path: '/notifications',
		basePath: 'notifications',
		component: Notifications,
		secured: true,
		class: 'dashboard-page',
	},
	{
		path: '/subscriptions',
		basePath: 'subscriptions',
		component: Subscriptions,
		secured: true,
		class: 'dashboard-page',
	},

	{
		path: '/subscriptions/payment',
		basePath: 'subscriptions',
		component: Payment,
		secured: true,
		class: 'dashboard-page',
	},

	{
		path: '/subscriptions/payment/done',
		basePath: 'subscriptions',
		component: Done,
		secured: true,
		class: 'dashboard-page',
	},
	{
		path: '/subscriptions',
		basePath: 'subscriptions',
		component: Subscriptions,
		secured: true,
		class: 'dashboard-page',
	},

	{
		path: '/subscriptions/payment',
		basePath: 'subscriptions',
		component: Payment,
		secured: true,
		class: 'dashboard-page',
	},

	{
		path: '/invitations/:action/:slug',
		basePath: 'invitations',
		component: Start,
		secured: false,
	},
];
