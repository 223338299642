import React, { useEffect, useState } from 'react';
import {
	CardElement,
	PaymentElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import cogoToast from 'cogo-toast';
import styles from './payment.module.scss';

export default function CheckoutForm() {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			'payment_intent_client_secret'
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case 'succeeded':
					setMessage('Payment succeeded!');
					cogoToast.success('Payment succeeded!');
					break;
				case 'processing':
					setMessage('Your payment is processing.');
					cogoToast.success('Payment succeeded!');
					break;
				case 'requires_payment_method':
					setMessage(
						'Your payment was not successful, please try again.'
					);
					cogoToast.error(
						'Your payment was not successful, please try again.'
					);
					break;
				default:
					setMessage('Something went wrong.');
					cogoToast.error('Something went wrong.');
					break;
			}
		});
	}, [stripe]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: 'http://localhost:3001/subscriptions/payment/done',
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === 'card_error' || error.type === 'validation_error') {
			setMessage(error.message);
		} else {
			setMessage('An unexpected error occurred.');
		}

		setIsLoading(false);
	};

	return (
		<form className={styles.form} id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" />
			<button
				className={styles.button}
				disabled={isLoading || !stripe || !elements}
				id="submit"
			>
				<span id="button-text">
					{isLoading ? (
						<div className={styles.spinner} id="spinner"></div>
					) : (
						'Pay now'
					)}
				</span>
			</button>
			{/* Show any error or success messages */}
			{message && (
				<div className={styles['payment-message']} id="payment-message">
					{message}
				</div>
			)}
		</form>
	);
}
