import { useState, useEffect, useContext } from 'react';
import { forgotPassword } from '../../requests/user';
import cogoToast from 'cogo-toast';
import SpiralLoader from '../../components/loaders/SpiralLoader';
import { NavLink, useHistory } from 'react-router-dom';
import { UserContext } from '../../context/user.context';

export default function ForgetPassword() {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const history = useHistory();

	const emailRegex = /[" "@*.]/g;

	const { data } = useContext(UserContext);

	useEffect(() => {
		if (data && data.user) {
			history.push('/dashboard');
		}
	}, [data, history]);

	useEffect(() => {
		email ? setDisabled(false) : setDisabled(true);
	}, [email]);

	const submitHandler = (event) => {
		event.preventDefault();
		if (!email.match(emailRegex)) {
			cogoToast.error('Please enter a valid email', {
				position: 'top-center',
			});
			return;
		}
		setLoading(true);
		forgotPassword({
			email,
		})
			.then((res) => {
				if (res.statusCode === 201) {
					setLoading(false);
					// cogoToast.success(res.message, {position:"top-center"})
					cogoToast.info(
						"We have sent you a link to reset your password. If it doesn't appear within a few minutes, check your spam folder",
						{ position: 'top-center', hideAfter: 5 }
					);
					setEmail('');
				}
			})
			.catch((err) => {
				setLoading(false);
				// cogoToast.error("We could not find your email in our records", {position:"top-center"})
				cogoToast.info(
					"We have sent you a link to reset your password.\n If it doesn't appear within a few minutes, check your spam folder",
					{ position: 'top-center', hideAfter: 5 }
				);
			});
	};

	const navigate = () => {
		history.push('/login');
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12">
					<nav className="navbar navbar-expand-xxl navbar-light">
						<a className="navbar-brand" href="##">
							<div className="logo-container">
								<NavLink to="/login">
									<img
										src="assets/images/auntie-network-logo.png"
										alt="description_icon"
										width="45px"
										height="auto"
									/>
								</NavLink>
							</div>
						</a>
					</nav>
					<div onClick={navigate} className="backbutton">
						<div className="back-btn">
							<div className="w-100 back-to-network">
								<span>&nbsp;</span>
							</div>
						</div>
					</div>
					<div className="formdiscription">
						<h2>Forgot Password</h2>
						<p>Enter your email to reset your passoword.</p>
					</div>
					<form onSubmit={submitHandler} className="form">
						<input
							name="email"
							type="email"
							className=""
							placeholder="Email"
							onChange={({ target: { value } }) =>
								setEmail(value)
							}
							value={email}
						/>
						<button
							type="submit"
							className="btn d-flex justify-content-center align-items-center solid-btn"
							disabled={loading || disabled}
						>
							{loading ? <SpiralLoader /> : 'Submit'}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}
