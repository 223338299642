import { useContext, useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import Image from '../../components/shared/Image';
// import { FavoriteIcon } from "../../components/Svgs";
import { UserContext } from '../../context/user.context';
import { AddNewFavorite, RemoveFavorite } from '../../requests/user';
import { capitalize, getAgeFromYear } from '../../utils';

function FavouriteIcon() {
  return (
    <svg
      width={21}
      height={20}
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.3382 2.61012C18.8274 2.09912 18.221 1.69376 17.5535 1.4172C16.8861 1.14064 16.1707 0.998291 15.4482 0.998291C14.7257 0.998291 14.0103 1.14064 13.3428 1.4172C12.6754 1.69376 12.0689 2.09912 11.5582 2.61012L10.4982 3.67012L9.43817 2.61012C8.40647 1.57842 7.0072 0.998826 5.54817 0.998826C4.08913 0.998826 2.68986 1.57842 1.65817 2.61012C0.626474 3.64181 0.046875 5.04108 0.046875 6.50012C0.046875 7.95915 0.626474 9.35842 1.65817 10.3901L2.71817 11.4501L10.4982 19.2301L18.2782 11.4501L19.3382 10.3901C19.8492 9.87936 20.2545 9.27293 20.5311 8.60547C20.8076 7.93801 20.95 7.2226 20.95 6.50012C20.95 5.77763 20.8076 5.06222 20.5311 4.39476C20.2545 3.7273 19.8492 3.12087 19.3382 2.61012Z'
        fill='#A0A3BD'
      />
    </svg>
  );
}

const NetworkCard = (props) => {
  const history = useHistory();
  const [network, setNetwork] = useState(props.network || null);
  const [isLiking, setIsLiking] = useState(false);
  const { data } = useContext(UserContext);
  const user = data && data.user;

  const favoriteProfile = async (e) => {
    e.stopPropagation();

    if (isLiking || network.favourited) return;
    setIsLiking(true);

    try {
      setIsLiking(false);
      const res = await AddNewFavorite({
        initiatorUserId: user.id,
        favouritedUserId: network.id,
      });

      if (res.statusCode === 201) {
        setNetwork({
          ...network,
          favouriteId: res.data.favoriteRecord.id,
          favourited: true,
        });
      }
    } catch (e) {
      setIsLiking(false);
      // console.log("ADD FAVORITE ERROR :", e);
    }
  };

  const removeFavourite = async (e) => {
    e.stopPropagation();

    if (isLiking || !network.favourited) return;
    setIsLiking(true);

    try {
      setIsLiking(false);
      const res = await RemoveFavorite({
        id: network.favouriteId,
        initiatorUserId: user.id,
        favouritedUserId: network.id,
      });

      if (res.statusCode === 200) {
        setNetwork({
          ...network,
          favouriteId: null,
          favourited: false,
        });
        
      }
    } catch (e) {
      setIsLiking(false);
      // console.log("REMOVE FAVORITE ERROR :", e);
    }
  };

  const {
    firstName,
    lastName,
    gender,
    profileImage,
    birthDate,
    favourited,
    id,
  } = network;
  const age = getAgeFromYear(birthDate);

  const viewProfile = (e) => {
    e.stopPropagation();
    history.push(`/profile/${id}`);
  };

  return (
    <div className='network-item col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3' onClick={viewProfile}>
      <div className='card'>
        <div className='card-body'>
          <div className='list-img-container'>
            <Image
              src={profileImage}
              fallback='assets/images/woman-1.jpeg'
              alt='profile avatar'
            />
          </div>
          <div className='row justify-content-between list-profile-details'>
            <div className='col-10'>
              <p>
                {capitalize(firstName)} {capitalize(lastName)}
                <span>
                  {capitalize(gender) || ''} <i className='fas fa-dot-circle' />{' '}
                  {`${age}y`}
                </span>
              </p>
            </div>
            <div
              className={`col-2 my-auto ${
                favourited
                  ? 'liked animate__heartBeat '
                  : 'like-it animate__bounceIn'
              }`}
              onClick={(e) =>
                favourited ? removeFavourite(e) : favoriteProfile(e)
              }
            >
              <FavouriteIcon></FavouriteIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NetworkCard);
