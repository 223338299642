/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { UserContext } from '../../context/user.context';
import ParentProfile from './ParentProfile';
import SingleProfile from './SingleProfile';
import UserProfile from './UserProfile';
import SingleProfileLoader from '../../components/loaders/SingleProfileLoader';

// import "../../assets/scss/dashboard.scss";

const Profile = (props) => {
  const { data, profile } = useContext(UserContext);
  const [user, setUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (data && data.user) {
      setUser(data.user);
    }
  }, [data && data.user]);

  const userId = data && data.userId;

  const profileId = props.location.state && props.location.state.userId;

  if (!profileId && !data) {
    history.push('/login');
    return <span></span>;
  }

  /*why this block of code*/
  if (!user)
    return (
      <div>
        <SingleProfileLoader currentUser={user} profile={profile} />
      </div>
    );
  

  if (!profileId) {
    if (user.userType.toLowerCase() === 'parent') return <ParentProfile />;
    return <UserProfile />;
  } else if (profileId) {
    if (profileId === userId) return <UserProfile />;
    return <SingleProfile profile={props.profile} />;
  } else {
    return history.push('/login');
  }

  // return <div>main profile</div>;
};

export default withRouter(Profile);
