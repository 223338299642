import SpiralLoader from '../../components/loaders/SpiralLoader';
import { useRef, useEffect } from 'react';

function InviteChildrenModal({ setEmail, inviteChild, loading }) {
  return (
    <div
      // ref={modalRef}
      className='modal fade'
      id='inviteChildrenModal'
      tabIndex={-1}
      aria-labelledby='inviteChildrenModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='inviteChildrenModalLabel'>
              Invite child
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            />
          </div>
          <div className='modal-body'>
            <div className='form-group'>
              <label htmlFor='childInvite' className='form-label'>
                Enter your child's email address below
              </label>
              <input
                onChange={({ target: { value } }) => setEmail(value)}
                type='email'
                className='form-control pt-3 pb-3'
                placeholder='email@example.com'
              />
              <div id='emailHelp' className='form-text px-2'>
                An invite link will be sent to the above email address.
              </div>
            </div>
          </div>
          <div className='modal-footer action-buttons'>
            <button
              type='button'
              className={`btn solid-btn ${
                loading ? 'disabled' : ''
              } d-flex justify-content-center w-25 align-items-center `}
              onClick={inviteChild}
              disabled={loading}
            >
              {loading ? <SpiralLoader size='sm' /> : 'Send Invite'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default InviteChildrenModal;
