import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Image from '../../../components/shared/Image';

// import "../../../assets/scss/dashboard.scss";

import {
	NotificationIcon,
	ChatIcon,
	VolumeUpIcon,
	SettingsIcon,
	LogoutIcon,
	HomeIcon,
	UserIcon,
	BlogIcon,
	ProfileIcon,
} from '../../../components/Svgs';
import { UserContext } from '../../../context/user.context';
// import useAuth from "../../../hooks/useAuth";

export const Sidebar = ({ page }) => {
	const { data, logout, profile } = useContext(UserContext);
	const history = useHistory();
	const user = data && data.user;
	return (
		<nav className="navbar navbar-expand-lg navbar-light fixed-left">
			<div className="w-100">
				<NavLink className="navbar-brand" to="/dashboard">
					<img
						className="brand-logo-round"
						src="/assets/images/auntie-network-logo.png"
						alt="description_icon"
					/>
				</NavLink>
				<div className="profile-dropdown float-end">
					<div>
						<Image
							alt="sb-profile-image"
							className="round-image-50"
							draggable="false"
							src={
								user && profile.profileImage
									? profile.profileImage
									: 'assets/images/profile-avatar.png'
							}
							fallback="assets/images/profile-avatar.png"
						/>
					</div>
					<div className="dropdown-content">
						<li
							className={`border-radius-top ${
								page === 'network' ? 'active' : ''
							}`}
						>
							<NavLink to="/profile" activeClassName="active">
								<ProfileIcon></ProfileIcon>
								Profile
							</NavLink>
						</li>
						{/* <li>
							<NavLink to="##" activeClassName="active">
								<SettingsIcon></SettingsIcon>
								Settings
							</NavLink>
						</li> */}
						<li className="border-radius-bottom">
							<NavLink to="/logout" onClick={logout}>
								<LogoutIcon></LogoutIcon>
								Logout
							</NavLink>
						</li>
					</div>
				</div>

				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarNavDropdown"
					aria-controls="navbarNavDropdown"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon" />
				</button>
			</div>
			<div className="collapse navbar-collapse" id="navbarNavDropdown">
				<ul className="navbar-nav">
					<li
						className={`nav-item ${
							page === 'dashboard' ? 'active' : ''
						}`}
					>
						<NavLink
							className={`nav-link ${
								page === 'dashboard' ? 'active' : ''
							}`}
							aria-current="page"
							to="/dashboard"
						>
							<HomeIcon></HomeIcon>
							Home
						</NavLink>
					</li>
					<li
						className={`nav-item ${
							page === 'networks' ? 'active' : ''
						}`}
					>
						<NavLink
							className={`nav-link ${
								page === 'networks' ? 'active' : ''
							}`}
							to="/networks"
							activeClassName="active"
						>
							<UserIcon></UserIcon>
							The Network
						</NavLink>
					</li>
					<li
						className={`nav-item ${
							page === 'chat' ? 'active' : ''
						}`}
					>
						<NavLink
							className={`nav-link ${
								page === 'chat' ? 'active' : ''
							}`}
							to="/chat"
							activeClassName="active"
						>
							<ChatIcon></ChatIcon>
							Chat
						</NavLink>
					</li>
					<li
						className={`nav-item ${
							page === 'notifications' ? 'active' : ''
						}`}
					>
						<NavLink
							className={`nav-link ${
								page === 'notifications' ? 'active' : ''
							}`}
							to="/notifications"
							activeClassName="active"
						>
							<NotificationIcon></NotificationIcon>
							Notifications
						</NavLink>
					</li>
					{/* <li
						className={`nav-item ${
							page === 'subscriptions' ? 'active' : ''
						}`}
					>
						<NavLink
							className={`nav-link ${
								page === 'subscriptions' ? 'active' : ''
							}`}
							to="/subscriptions"
							activeClassName="active"
						>
							<i class="fas fa-money-check-alt me-3"></i>
							Subscribe
						</NavLink>
					</li> */}
					<hr />
					<li
						className={`nav-item ${
							page === 'stories' ? 'active' : ''
						}`}
					>
						<NavLink
							className={`nav-link ${
								page === 'stories' ? 'active' : ''
							}`}
							to={{
								pathname: 'https://www.theauntienetwork.com',
							}}
							target="_blank"
							activeClassName="active"
						>
							<VolumeUpIcon></VolumeUpIcon>
							Success Stories
						</NavLink>
					</li>
					<li
						className={`nav-item ${
							page === 'blog' ? 'active' : ''
						}`}
					>
						<NavLink
							className={`nav-link ${
								page === 'stories' ? 'active' : ''
							}`}
							to={{
								pathname:
									'https://www.theauntienetwork.com/blog',
							}}
							target="_blank"
							activeClassName="active"
						>
							<BlogIcon></BlogIcon>
							Blog
						</NavLink>
					</li>
				</ul>
			</div>
		</nav>
	);
};
