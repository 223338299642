import axios from "axios";
import {
  LOCATIONS,
  ZIPCODES,
  COMPLETE_REGISTRATION,
  UPLOAD_PARTIAL_REGISTRATION_IMAGE,
  VALIDATE_INVITATION,
  VALIDATE_PARTIAL_REGISTRATION,
} from "./endpoints";

export const AcceptInvitation = (_data) => {
  return axios.post(VALIDATE_INVITATION, _data).then(({ data }) => {
    return data;
  });
};

export const SyncRegistration = (_data) => {
  return axios.post(VALIDATE_PARTIAL_REGISTRATION, _data).then(({ data }) => {
    return data;
  });
};

export const UploadOnboardingImage = async (data) => {
  return axios
    .post(UPLOAD_PARTIAL_REGISTRATION_IMAGE, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      return data;
    });
};

export const CompleteRegistration = (_data) => {
  return axios.post(COMPLETE_REGISTRATION, _data).then(({ data }) => {
    return data;
  });
};

export const getLocations = () => {
  return axios.get(LOCATIONS).then(({ data }) => {
    return data;
  });
};

export const getZipcodes = (state)=>{
  return axios.get(ZIPCODES(state)).then(({data})=>{
    return data
  })
}