import axios from 'axios';
import { USER_STORAGE_KEY } from '../constants';
import {
  ADD_FAVORITE,
  CHAT_REQEUST,
  CHAT_REQUEST,
  GET_CHATS,
  GET_CHILDREN,
  GET_MY_RECOMMENDATIONS,
  GET_NETWORK_PROFILE,
  GET_PROFILE_IMAGES,
  GET_RECEIVED_RECOMMENDATIONS,
  GET_USER,
  GET_USER_FAVORITES,
  GET_USER_INVITATIONS,
  HAS_CHAT_REQUEST,
  INVITE_CHILD,
  REMOVE_FAVORITE,
  SEARCH_NETWORK,
  SEND_RECOMMENDATION,
  UPDATE_USER,
  UPLOAD_PROFILE_IMAGE,
  UPLOAD_PROFILE_IMAGES,
  USER_RECOMMENDATION,
  RESEND_INVITE,
  DELETE_IMAGES,
  LOCATIONS,
  GET_USER_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS,
  DELETE_USER_NOTIFICATIONS,
  FORGOTPASSWORD,
  RESETPASSWORD,
  DELETE_RECOMMENDATION,
  DELETE_INVITATION,
  GOOGLEAUTH,
  POSTUSERPREFERENCES,
  CHANGEPASSWORD,
  GET_MATCHES,
  PLANS,
  CREATESUBSCRIPTION,
  CREATECUSTOMER
} from './endpoints';

axios.interceptors.request.use((config) => {
  // console.log(config)
  let user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
  if (user) {
    config.headers['Authorization'] = `Bearer ${user.accessToken}`;
  }
  return config;
});



export const GetUser = (id) => {
  return axios.get(GET_USER(id)).then(({ data }) => {
    return data;
  });
};

export const GetNetworkProfile = (data) => {
  return axios
    .get(GET_NETWORK_PROFILE(data.profileId), data)
    .then(({ data }) => {
      return data;
    });
};

export const GetProfileImages = (id) => {
  return axios.get(GET_PROFILE_IMAGES(id)).then(({ data }) => {
    return data;
  });
};

export const UpdateUser = (_data) => {
  return axios.post(UPDATE_USER, _data).then(({ data }) => {
    return data;
  });
};

export const InviteChild = (_data) => {
  return axios.post(INVITE_CHILD, _data).then(({ data }) => {
    return data;
  });
};

export const resendInvite = (_data) => {
  return axios.post(RESEND_INVITE, _data).then(({ data }) => {
    return data;
  });
};

export const GetUserInvitations = (id) => {
  return axios.get(GET_USER_INVITATIONS(id)).then(({ data }) => {
    return data;
  });
};

export const deleteInvitation = (data)=>{
    return axios.post(DELETE_INVITATION, data).then(({data})=>{
      return data
    })
}


export const getUserNotifications =(ownerUserId)=>{
  return axios.get(GET_USER_NOTIFICATIONS(ownerUserId)).then((data)=>{
    return data
  })
}

export const updateUserNotifications = (_data)=>{
  return axios.post(UPDATE_USER_NOTIFICATIONS, _data).then((data)=>{
    return data
  })
}

export const deleteUserNotifications =(_data)=>{
  return axios.post(DELETE_USER_NOTIFICATIONS, _data).then((data)=>{
    return data
  })
}



// export const GetNetworkSuggestions = async () => {
//   try {
//     const resp = await axios.get(SEARCH_NETWORK);

//     if (!resp.ok) throw new Error(`New errors`);
//     if (resp.ok) return resp.data;
//   } catch (error) {
//     throw error;
//   }
// };

export const GetNetworkSuggestions = () => {
  return axios.get(SEARCH_NETWORK).then(({ data }) => {
    return data;
  });
};

export const GetUserFavoriteProfiles = (id) => {
  return axios.get(GET_USER_FAVORITES(id)).then(({ data }) => {
    return data;
  });
};

export const AddNewFavorite = (data) => {
  return axios.post(ADD_FAVORITE, data).then(({ data }) => {
    return data;
  });
};

export const RemoveFavorite = (data) => {
  return axios.post(REMOVE_FAVORITE, data).then(({ data }) => {
    return data;
  });
};

export const RecommendProfile = (data) => {
  return axios.post(USER_RECOMMENDATION, data).then(({ data }) => {
    return data;
  });
};

export const SendChatRequest = (data) => {
  return axios.post(CHAT_REQUEST, data).then(({ data }) => {
    return data;
  });
};

export const GetChatRequestSent = (data) => {
  return axios.get(CHAT_REQUEST, data).then((data) => {
    return data;
  });
};

export const GetChatRequest = (data) => {
  return axios.post(HAS_CHAT_REQUEST, data).then(({ data }) => {
    return data;
  });
};

export const GetChats = (userId) => {
  return axios.get(GET_CHATS(userId)).then(({ data }) => {
    return data;
  });
};

export const GetChildren = (parentId) => {
  return axios.get(GET_CHILDREN(parentId)).then(({ data }) => {
    return data;
  });
};

export const GetMyRecommendations = (id) => {
  return axios.get(GET_MY_RECOMMENDATIONS(id)).then(({ data }) => {
    return data;
  });
};

export const GetParentRecommendations = (id) => {
  return axios.get(GET_RECEIVED_RECOMMENDATIONS(id)).then(({ data }) => {
    return data;
  });
};

export const  deleteRecommendation = (id) =>{
  return axios.delete(DELETE_RECOMMENDATION(id)).then(({data})=>{
    return data
  })
}

export const UploadProfileImage = async (data) => {
  return axios
    .post(UPLOAD_PROFILE_IMAGE(), data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => {
      return data;
    });
};

export const UploadProfileImages = async (data) => {
  return axios
    .post(UPLOAD_PROFILE_IMAGES(), data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => {
      return data;
    });
};

export const DeleteImages = (data) => {
  return axios.post(DELETE_IMAGES, data).then(({ data }) => {
    return data;
  });
};

export const GetLocation = async () => {
  const res = await axios.get(LOCATIONS);
  const data = res.data;
  return data;
};

export const forgotPassword = async (dat) => {
  const res = await axios.post(FORGOTPASSWORD, dat)
  const data = res.data;
  return data
}

export const resetPassword = async (dat) => {
  const res = await axios.post(RESETPASSWORD, dat)
  const data = res.data;
  return data
}

export const googleAuth = () => {
  return axios.get(GOOGLEAUTH).then(({data})=>{
    return data
  })
}

export const postUserPreferences = (data) =>{
    return axios.post(POSTUSERPREFERENCES, data).then(({data})=>{
      return data
    })
}

export const changePassword = async (dat) => {
  const res = await axios.post(CHANGEPASSWORD, dat)
  const data = res.data;
  return data
}

export const GetMatches = async (userId) =>{
  const res = await axios.get(GET_MATCHES(userId))
  const data = res.data;
  return data
}

export const GetPlans = () => {
  return axios.get(PLANS).then(({ data }) => {
    return data;
  });
};

export const createCustomer = (_data)=>{
  return axios.post(CREATECUSTOMER, _data).then(({data})=>{
    return data
  })
}

export const createSubscription = (_data)=>{
  return axios.post(CREATESUBSCRIPTION, _data).then(({data})=>{
    return data
  })
}