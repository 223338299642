import { MESSAGING_ACTIONS } from "./constants";

export const messagingReducer = (state, action) => {
  console.log("update state ", state, action);
  switch (action.type) {
    case MESSAGING_ACTIONS.SET_USER_ID:
      return { ...state, userId: action.payload };

    case MESSAGING_ACTIONS.SET_ACTIVE_CHAT:
      return { ...state, activeChat: action.payload, activeChatRequest: null };

    case MESSAGING_ACTIONS.SET_ACTIVE_CHAT_REQUEST:
      return { ...state, activeChat: null, activeChatRequest: action.payload };

    case MESSAGING_ACTIONS.SET_CHATS:
      const messagesStore = {};
      action.payload.forEach((chat) => {
        messagesStore[chat.chatId] = {
          messagesLoaded: false,
          messages: [],
        };
      });

      return {
        ...state,
        chats: action.payload,
        messagesStore: {
          ...state.messagesStore,
          ...messagesStore,
        },
      };

    case MESSAGING_ACTIONS.SET_CHAT_REQUESTS:
      return {
        ...state,
        chatRequests: action.payload,
      };

    case MESSAGING_ACTIONS.UPDATE_CHAT_REQUEST:
      return {
        ...state,
        chatRequests: state.chatRequests.map(request => {
          if(request.recordId === action.payload.id) return {...request, ...action.payload }
          return request;
        })
      };

    case MESSAGING_ACTIONS.SET_MESSAGES:
      const chatId = action.payload.id;
      const store = {
        ...state.messagesStore,
        [chatId]: action.payload.data,
      };
      return {
        ...state,
        messagesStore: store,
      };

    case MESSAGING_ACTIONS.NEW_MESSAGE:
      const id = action.payload.id;
      const messages = {
        ...state.messagesStore[id],
        messages: [...state.messagesStore[id].messages, action.payload.data],
      };
      const stores = {
        ...state.messagesStore,
        [id]: messages,
      };
      console.log("new message ", id, messages);
      return {
        ...state,
        messagesStore: stores,
      };

    case MESSAGING_ACTIONS.UPDATE_MESSAGE_ID:
      // const id = action.payload.id;
      const updated = state.messagesStore[action.payload.chatId].messages.map(
        (msg) => {
          if (msg.messageId === action.payload.data.updateMeta.tmpMessageId) {
            return action.payload.data.message;
          }
          return msg;
        }
      );

      const updatedMessagesStore = {
        ...state.messagesStore,
        [action.payload.chatId]: {
          ...state.messagesStore[action.payload.chatId],
          messages: updated,
        },
      };

      console.log(
        "update message ID: ",
        action.payload.chatId,
        updatedMessagesStore
      );
      return {
        ...state,
        messagesStore: updatedMessagesStore,
      };

    case MESSAGING_ACTIONS.DELETE_MESSAGE:
      const update = {
        ...store.messagesStore,
        [action.payload.chatId]: {
          ...store.messagesStore[action.payload.chatId],
          messages: store.messagesStore[action.payload.chatId].filter(
            (msg) => msg.messageId !== action.payload.data.message.messageId
          ),
        },
      };
      console.log(
        "delete message ID: ",
        action.payload.chatId,
        updatedMessagesStore
      );
      return {
        ...state,
        messagesStore: update,
      };

    case MESSAGING_ACTIONS.UPDATE_MESSAGE_BODY:
      const bodyUpdate = {
        ...store.messagesStore,
        [action.payload.chatId]: {
          ...store.messagesStore[action.payload.chatId],
          messages: store.messagesStore[action.payload.chatId].map((msg) => {
            if (msg.messageId === action.payload.data.message.messageId)
              return action.payload.data.message;
            return msg;
          }),
        },
      };
      console.log("update message BODY: ", action.payload.chatId, bodyUpdate);

      return {
        ...state,
        messagesStore: bodyUpdate,
      };
    default:
      console.log("default");
      break;
  }
};
