import React from 'react';
import { useEffect, useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../context/user.context';
import { GetUser, UpdateUser } from '../../requests/user';
import { interests } from '../../utils/data';
// import { Sidebar } from "../../components/shared/SideBar";
import { CloseIcon, LocationIcon, UploadIcon } from '../../components/Svgs';
import ProfileCard from './ProfileCard';
import {
	BioData,
	ParentChatCard,
	ProfileImageGrid,
	Tag,
	TagList,
} from '../../components/Profile';
import { profileIsChild, profileIsParent } from '../../utils';
import EditProfileModal from '../../components/Modals/EditProfileModal';
import EditPreferencesModal from '../../components/Modals/EditPreferencesModal';
import ResetPasswordModal from '../../components/Modals/ResetPasswordModal';
// import "../../assets/scss/dashboard.scss";

const ParentProfile = (props) => {
	const history = useHistory();
	const { data, profile } = useContext(UserContext);

	// const [profile, setProfile] = useState(profiles);
	const [isLoading, setIsLoading] = useState(false);

	const [edits, setEdits] = useState({
		firstName: '',
		lastName: '',
		gender: '',
		userType: '',
		parentId: '',
		lookingFor: '',
		birthDate: '',
		hobbies: '',
		zipcode: '',
		height: '',
		bio: '',
	});

	// const saveEdits = () => {
	//   let new_edits = { ...edits };
	//   Object.keys(new_edits).forEach((key) =>
	//     !Boolean(new_edits[key]) ? delete new_edits[key] : {}
	//   );
	//   if (!Object.keys(new_edits).length) return;
	//   UpdateUser({ id: userId, ...new_edits }).then((res) => {
	//     FetchUser();
	//   });
	// };

	return (
		<>
			<div className="row single-explore-profile m-0">
				<div className="col-md-3">
					<ProfileCard
						profile={profile}
						isProfilePage={true}
					></ProfileCard>
				</div>
				<div className="col-md-8 content-wrapper">
					{/* <TagList title='Hobbies' tags={profile.hobbies}></TagList> */}
					<div className="user-location">
						<p>Location</p>
						<div>
							<Tag
								text={profile.location || 'Unknown'}
								Icon={LocationIcon}
							/>
						</div>
					</div>
					<BioData text={profile.bio}></BioData>
					{profileIsChild(profile.userType) && (
						<ProfileImageGrid profile={profile}></ProfileImageGrid>
					)}
				</div>
				{/* Modal */}
				<EditProfileModal />
				<EditPreferencesModal />
				<ResetPasswordModal />
			</div>
		</>
	);
};

const Height = ({ setEdits, edits, height }) => {
	const getSliderValue = () => {
		let slider = document.querySelector('#preferenceHeight');
		let sliderValue = slider?.noUiSlider?.get();
		return sliderValue;
	};

	useEffect(() => {
		let slider = document.querySelector('#preferenceHeight');
		slider.addEventListener('mouseout', () => {
			setEdits({ ...edits, height: getSliderValue() });
		});
	}, []);
	useEffect(() => {
		// height slider
		const noUiSlider = window.noUiSlider;
		const heightRange = document.querySelector('#preferenceHeight');
		var labels = {
			40: '40in',
			55: '55in',
			67: '67in',
			77: '77in',
			85: '85in',
			90: '90in',
			100: '100in',
		};
		noUiSlider.create(heightRange, {
			start: 55,
			connect: [true, false],
			tooltips: {
				to: function (value) {
					return value > 100 ? '100+' : parseInt(value);
				},
			},
			range: {
				min: 40,
				'16%': 55,
				'32%': 67,
				'48%': 77,
				'64%': 85,
				'80%': 90,
				max: 100,
			},
			pips: {
				mode: 'steps',
				filter: function (value, type) {
					return type === 0 ? -1 : 1;
				},
				format: {
					to: function (value, key) {
						return labels[value];
					},
				},
			},
		});

		heightRange.noUiSlider.set(height);
	}, []);

	return (
		<div className="row preferenceHeight">
			<div className="col-9">
				<p>What's your height</p>
				<div id="preferenceHeight" />
			</div>
		</div>
	);
};

const DateOfBitrh = ({ birthDate, setEdits, edits }) => {
	const [dob, setDob] = useState(birthDate);
	useEffect(() => {
		if (!dob) return;
		setEdits({ ...edits, dob });
	}, [dob]);

	return (
		<div className="row preferenceDOB">
			<div className="col-6">
				<p>What is your date of birth?</p>
				<div className="form-floating">
					<div className="input-wrapper">
						<DatePicker
							scrollableYearDropdown
							showYearDropdown
							maxDate={Date.now() - 5.676e11}
							// maxDate={Date.now() - 5.676e11}
							yearDropdownItemNumber={50}
							className="form-control datepicker"
							placeholderText={
								dob
									? new Date(dob).toLocaleDateString()
									: 'Birthday'
							}
							selected={new Date(dob).valueOf()}
							onChange={(e) => {
								setDob(new Date(e).toLocaleDateString());
								setEdits({
									...edits,
									birthDate: new Date(e).toLocaleDateString(),
								});
							}} //only when value has changed
						/>
					</div>

					{/* <label htmlFor="preferenceDOB">Date of Birth</label> */}
				</div>
			</div>
		</div>
	);
};

const Hobbies = ({ hobbies: _hobbies, setEdits, edits }) => {
	const [hobbies, setHobbies] = useState(_hobbies || []);

	const select = (el) => {
		if (hobbies.includes(el)) {
			setHobbies([...hobbies].filter((hobby) => hobby !== el));
		} else {
			setHobbies([...hobbies, el]);
		}
	};

	useEffect(() => {
		if (!hobbies.length) {
			return setEdits({ ...edits, hobbies: null });
		}
		setEdits({ ...edits, hobbies });
	}, [hobbies]);

	return (
		<div className="row preferenceHobbies">
			<div className="col-12">
				<p>Hobbies</p>
				<div className="input-wrapper">
					<div className="form-group">
						{interests.map((item, i) => (
							<button
								key={i}
								type="button"
								onClick={() => select(item)}
								className={`tc-tag ${
									hobbies.includes(item) ? 'tc-selected' : ''
								}`}
								data-tag="0"
							>
								{item}
							</button>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ParentProfile;
