
import { capitalize, getFullName, getAgeFromYear } from "../../utils";
import Image from "../shared/Image";
import { UserContext } from '../../context/user.context';
import {useContext} from 'react'
import {resendInvite} from '../../requests/user'
import cogoToast from 'cogo-toast';



function InvitationRow({ email, status, removeInvitation }) {
    const {data} = useContext(UserContext)

    const resendInvitation = async()=>{
      const $ =window.$
  
      $(`.${email.split('@')[0]}`).addClass("spin-redo")
      setTimeout(()=>{
        resendInvite({
          userId:data.userId,
          email:email
        }).then((res)=>{
          if(res.statusCode === 201){
           const {hide} = cogoToast.success('Invitation Re-sent 📧', {
              position: 'top-center',
              hideAfter: 3000,
            });
            setTimeout(hide, 1000)
            $(`.${email.split('@')[0]}`).removeClass("spin-redo")
          }
        })
        .catch((err) => {
          // hideLoader();
  
          cogoToast.error(err.response && err.response.data.message);
          
          $(`.${email.split('@')[0]}`).removeClass("spin-redo")
        });
      }, 3000)
    }

    return (
      <div className="row justify-content-between childrenInvitation">
        <div className="col-7 mt-2">
          <p title={email} className="reset-margin-left-5">{email}</p>
        </div>
        <div className="col-3">
          <p className={`recommendee ${status.toLowerCase()} text-center`}>
            {capitalize(status)}
          </p>
        </div>
        {status==="Pending"?(
        <>
          <div class="col-1 pt-2">
            <i onClick={()=>resendInvitation()} title="Resend Invitation"  class={`${email.split('@')[0]} fas fa-redo`}></i>
          </div>
          <div class="col-1 pt-2">
            <i onClick={()=>removeInvitation({userId:data.userId, email:email})} title="Delete Invitation" class="far fa-trash-alt"></i>
          </div>
        </>
        )
        :
        <>
          <div class="col-1 grayed pt-2">
            <i class="fas grayed  fa-redo"></i>
          </div>
          <div class="col-1 grayed pt-2">
            <i  class="far grayed  fa-trash-alt"></i>
          </div>
      </>
        }
      </div>
    );
}

function RegisteredInvitation({imageUrl, firstName, lastName, gender, birthDate}) {
  console.log(imageUrl)
  return (
    <div className="row justify-content-between recommended-profile">
      <div className="col-1">
        <Image
          alt=""
          className="round-image-48"
          src={imageUrl}
          fallback="assets/images/profile-avatar.png"
        />
      </div>
      <div className="col-6">
        <p className="pl-1">
        {getFullName(
            firstName,
            lastName
          )}
          {(gender || birthDate) && (
            <span>
              {capitalize(gender)}{' '}
              {gender && (
                <i className='fas fa-dot-circle' />
              )}
              {birthDate && Boolean(getAgeFromYear(birthDate)) === true &&
                `${getAgeFromYear(birthDate)}y`}
            </span>
          )}
        </p>
      </div>
      <div className="col-3">
        <p className="recommendee registered text-center">Registered</p>
      </div>
      <div class="col-1 grayed pt-2">
        <i class="fas grayed  fa-redo"></i>
      </div>
      <div class="col-1 grayed pt-2">
        <i  class="far grayed  fa-trash-alt"></i>
      </div>
    </div>
  );
}

const ChildrenModal = ({ invitations, removeInvitation}) => {
           

  return (
    <div
      className="modal fade"
      id="children"
      tabIndex={-1}
      aria-labelledby="reccommendationsLabelby"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
             All Invitations
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="row">
            {invitations &&
            invitations.map((invitation, i) => {
              if (
                invitation.status === "Pending" ||
                invitation.status === "Accepted" ||
                invitation.status === "Rejected"
              ) {
                return (
                  <InvitationRow
                    key={i}
                    email={invitation.email}
                    status={invitation.status}
                    removeInvitation={removeInvitation}
                  ></InvitationRow>
                );
              } else {
                return <RegisteredInvitation 
                    firstName={invitation.invitee.firstName}
                    lastName={invitation.invitee.lastName}
                    gender={invitation.invitee.meta.gender}
                    imageUrl={invitation.invitee.profileImage}
                    birthDate = {invitation.invitee.meta.birthDate}
                />;
              }
            })}
            </div>
          </div>
          <div className="modal-footer action-buttons">
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChildrenModal;
